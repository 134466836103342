<style lang="scss" scoped>
    .time-table {
        p {
            margin-bottom: 20px;
            .title_time {
                font-size: 12px;
                font-weight: 700;
                float: left;
                /*margin-bottom: 5px;*/
            }
            .title_info {
                font-size: 12px;
                color: #909399;
                float: right;
                padding-right: 10px;
            }
        }
        ul {
            display: inline-block;
            border: 1px solid #DCDFE6;
            border-bottom: 0;
            li {
                width: 96px;
                border-style: solid;
                border-color: #DCDFE6;
                border-width: 0 1px 1px 0;
                float: left;
                text-align: center;
                font-size: 14px;
                color: #909399;
                position: relative;
                &:nth-child(4n){
                    border-right: 0;
                }
            }
            .select-time{
                background: #FFEBEA;
                /*border: 1px solid #FF3B30;*/
                color: #FF3B30;
                position: relative;
                &:before{
                    position: absolute;
                    top:-1px;left: -1px;right: -1px;bottom: -1px;
                    content: "";
                    display: block;
                    border: 1px solid #FF3B30;
                }
            }
            .peak-time{

            }
        }
        .emoticons {
            float: left;
            display: inline-block;
            margin-top: 1px;
            font-size: 12px;
            .icon-position{
                float: left;
                margin: 7px 5px;
            }
        }
        .emoticons-icon {
            width: 20px;
            height: 2px;
            border-radius: 1px;
        }
        .flat {
            border: 1px solid #BBBEC5;
        }
        .peak {
            background: #ff3b30;
        }
        .night {
            background: #319BF7;
        }
        .save-peak {
            float: right;
            margin-right: 35px;
            margin-top: 10px;
            .el-button{
                padding: 8px 20px;
            }

        }
        .time-table-title {
            width: 389px;
            margin-left: 10px;
            float: left;
            &:first-child{
                margin-left: 0;
            }
        }
        .emoticons-icon-solid{
            width: 100%;position: absolute;bottom: -1px;
            display: flex;height: 2px;
            .emoticons-icon{
                flex: 1;
            }
            .icon-solid-c{
                width: 1px;
            }
        }
        .select-after{
            color: #333;
        }
    }
</style>
<template>

    <div class="time-table clear">
        <div>
            <div class="time-table-title">
                <p><span class="title_time">AM 00:00-12:00</span><span class="title_info" v-if="exhibition&&isEdit">点击时段方框， 选中或取消选中</span></p>
                <ul>
                    <li v-for="(t,index) in timeList" :key="index" @click="selectTime(index)"
                        :class="{'select-time': t.isSelect&&isEdit, 'select-after':(t.isSelect || t.isNight ) && !isEdit}" :style="{'cursor': isEdit?'pointer':'', 'height': heightLi + 'px', 'line-height': heightLi + 'px'}">
                        {{t.flatPeriodBegin}}-{{t.flatPeriodEnd}}
                        <div class="emoticons-icon-solid" v-if="t.isSelect&&t.isNight&&!isEdit">
                            <p class="emoticons-icon peak"></p>
                            <p class="icon-solid-c">&nbsp;</p>
                            <p class="emoticons-icon night"></p>
                        </div>
                        <div v-if="t.isSelect&&!t.isNight&&!isEdit" class="emoticons-icon peak" style="width: 100%;position: absolute;bottom: -1px"></div>
                        <div v-if="!t.isSelect&&t.isNight&&!isEdit" class="emoticons-icon night" style="width: 100%;position: absolute;bottom: -1px"></div>
                    </li>
                </ul>

            </div>
            <div class="time-table-title">
                <p><span class="title_time">PM 12:00-24:00</span><span class="title_info" v-if="exhibition&&isEdit">点击时段方框， 选中或取消选中</span></p>
                <ul>
                    <li v-for="(t,index) in timeList1" :key="index" @click="selectTime1(index)"
                    :class="{'select-time': t.isSelect&&isEdit, 'select-after':(t.isSelect || t.isNight ) && !isEdit}" :style="{'height': heightLi + 'px', 'line-height': heightLi + 'px'}">
                        {{t.flatPeriodBegin}}-{{t.flatPeriodEnd}}
                        <div v-if="t.isSelect&&t.isNight&&!isEdit">
                            <div class="emoticons-icon peak" style="width: 50%;position: absolute;bottom: -1px"></div>
                            <div class="emoticons-icon night" style="width: 50%;position: absolute;bottom: -1px;left: 50%"></div>
                        </div>
                        <div v-if="t.isSelect&&!t.isNight&&!isEdit" class="emoticons-icon peak" style="width: 100%;position: absolute;bottom: -1px"></div>
                        <div v-if="!t.isSelect&&t.isNight&&!isEdit" class="emoticons-icon night" style="width: 100%;position: absolute;bottom: -1px"></div>
                    </li>
                </ul>
            </div>
        </div>
        <div>
            <div class="emoticons" v-if="!isEdit">
                <div style="float: left">图释：</div>
                <div style="float: left">
                    <div class="emoticons-icon flat icon-position"></div>
                    <div style="width: 100px">平峰时段</div>
                </div>
                <div style="float: left">
                    <div class="emoticons-icon peak icon-position"></div>
                    <div style="width: 100px">高峰时段</div>
                </div>
                <div style="float: left">
                    <div class="emoticons-icon night icon-position"></div>
                    <div style="width: 100px">夜间时段</div>
                </div>
            </div>
            <div class="save-peak" v-if="isEdit">
                <el-button style="border: 1px solid #319BF7;color:#319BF7" @click="closeEdit">取消</el-button>
                <el-button type="primary" @click="savePeak">保存高峰时段</el-button>
            </div>
        </div>
    </div>

</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            let timeList = [], timeList1 = [];
            for (let i = 0; i < 12; i++) {
                for (let j = 0; j < 2; j++) {
                    let time = {};
                    if (j === 0)
                        time = {
                            flatPeriodBegin: i < 10 ? ('0' + i + ':00') : (i + ':00'),
                            flatPeriodEnd: i < 10 ? ('0' + i + ':30') : (i + ':30'),
                            isSelect: false,
                            isNight: false,
                            // isPeak: false
                        };
                    if (j === 1)
                        time = {
                            flatPeriodBegin: i < 10 ? ('0' + i + ':30') : (i + ':30'),
                            flatPeriodEnd: i + 1 < 10 ? ('0' + (i + 1) + ':00') : ((i + 1) + ':00'),
                            isSelect: false,
                            isNight: false,
                            // isPeak: false
                        }
                    timeList.push(time)
                }
            }
            for (let i = 12; i < 24; i++) {
                for (let j = 0; j < 2; j++) {
                    let time = {};
                    if (j === 0)
                        time = {
                            flatPeriodBegin: i + ':00',
                            flatPeriodEnd: i + ':30',
                            isSelect: false,
                            isNight: false,
                            isPeak: false
                        };
                    if (j === 1)
                        time = {
                            flatPeriodBegin: i + ':30',
                            flatPeriodEnd: (i + 1) + ':00',
                            isSelect: false,
                            isNight: false,
                            isPeak: false
                        }
                    timeList1.push(time)
                }
            }
            // console.log(timeList, timeList1);
            return {
                timeData: timeList,
                timeData1: timeList1
            }
        },
        props:{
            nightTime:{
                type: Object,
                default: function () {
                    return {
                        nightPeriodBegin: '22:00',
                        nightPeriodEnd: '05:00'
                    }
                }
            },
            isEdit:{
                type: Boolean,
                default: false
            },
            peakTime: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            exhibition:{
                type: Boolean,
                default: true
            },
            heightLi: {
                type: String,
                default: "33"
            }
        },
        //计算属性
        computed: {
            timeList: function () {
//                console.log(this.nightTime, 'fsdfasdfasdfasfasdfsadf');
                this.timeData.forEach(item=>{
                    let t = +new Date('2018/12/24 ' + item.flatPeriodEnd);
                    let ns = +new Date('2018/12/24 ' + this.nightTime.nightPeriodBegin);
                    let ne = +new Date('2018/12/24 ' + this.nightTime.nightPeriodEnd);
                    if(t<=ne||t>ns) item.isNight = true;
                    else item.isNight = false;
                    if(this.peakTime&&this.peakTime.length>0){
                        this.peakTime.forEach(pt=>{
                            let p = +new Date('2018/12/24 ' + pt.peakPeriodEnd);
                            if(p==t) {
                                item.isSelect = true;
                                item.isPeak = true;
                            }
                        })
                    }
                })
                return this.timeData;
            },
            timeList1: function () {
//                console.log(this.nightTime, 'fsdfasdfasdfasfasdfsadf');
                this.timeData1.forEach(item=>{
                    let t = +new Date('2018/12/24 ' + item.flatPeriodEnd);
                    let ns = +new Date('2018/12/24 ' + this.nightTime.nightPeriodBegin);
                    let ne = +new Date('2018/12/24 ' + this.nightTime.nightPeriodEnd);
                    if(t>ns||t<ne) item.isNight = true;
                    else item.isNight = false;
                    if(this.peakTime&&this.peakTime.length>0){
                        this.peakTime.forEach(pt=>{
                            let p = +new Date('2018/12/24 ' + pt.peakPeriodEnd);
                            if(p==t) {
                                item.isSelect = true;
                                item.isPeak = true;
                            }
                        })
                    }
                })
                return this.timeData1;
            }
        },
        //主件被加载完成
        mounted: function () {
            // console.log(this.timeList);
        },
        //定义事件方法
        methods: {
            savePeak() {
                let peakTime = [];
                this.timeList.forEach(item=>{
                    if(item.isSelect) peakTime.push({
                        peakPeriodBegin: item.flatPeriodBegin,
                        peakPeriodEnd: item.flatPeriodEnd
                    })
                })
                this.timeList1.forEach(item=>{
                    if(item.isSelect) peakTime.push({
                        peakPeriodBegin: item.flatPeriodBegin,
                        peakPeriodEnd: item.flatPeriodEnd
                    })
                })
                this.$emit('saveEdit', peakTime)
            },
            selectTime(index) {
                if(!this.isEdit) return false;
                this.timeList[index].isSelect = !this.timeList[index].isSelect;
            },
            selectTime1(index) {
                if(!this.isEdit) return false;
                this.timeList1[index].isSelect = !this.timeList1[index].isSelect;
            },
            closeEdit(){
                this.timeList.forEach(item=>{
                    if(!item.isPeak)
                        item.isSelect = false;
                })
                this.timeList1.forEach(item=>{
                    if(!item.isPeak)
                        item.isSelect = false;
                })
                this.$emit('closeEdit')
            },
        },
        //监听模版,变量
        watch: {}

    }
</script>