<style lang="scss" scoped>
::v-deep {
    .list-search-after {
        width: 200px;
    }
}
</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <comListSearch>
                <div slot="list-search-before">
                    <el-row>
                        <el-col :span="6">
                            <SelectCity :isBorder="false" size="medium" clearable @update:city="handleCityChange"/>
                        </el-col>
                        <el-col :span="10" :offset="1">
                            <el-input placeholder="围栏名称" v-model="form.addressName" clearable size="medium"/>
                        </el-col>
                    </el-row>
                </div>
                <div slot="list-search-after">
                    <el-button type="primary" @click="getData(1)">搜索</el-button>
                    <el-button type="success" @click="handleAdd()">新增围栏</el-button>
                </div>
            </comListSearch>
            <div class="eTrip-section-cont quote-list">
                <el-table :data="result.data" style="width: 100%">
                    <el-table-column prop="cityName" label="城市"></el-table-column>
                    <el-table-column prop="addressName" label="围栏名称"></el-table-column>
                    <el-table-column prop="address" label="详情" width="250">
                        <template slot-scope="scope">
                            <enclosure-dialog :title="scope.row.cityName+' - '+scope.row.addressName"
                                              :ids="[scope.row.addressId]"
                                              :dataList="[scope.row]"/>
                            <el-button type="warning" size="small" @click="handleAdd(scope.row)">修改围栏</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div style="display: flex;justify-content: flex-end;margin: 10px 0;width: 100%">
                <el-pagination background layout="prev, pager, next" :total="result.total"
                               @current-change="getData"></el-pagination>
            </div>
        </el-footer>
    </div>
</template>

<script type="text/ecmascript-6">
import {getOperatorAddressApi} from '@/www/urls/quotationUrls'
import EnclosureDialog from '@/www/pages/quotationTool/components/quoteEditForm/enclosure-dialog'
import comListSearch from '@/www/components/comListSearch/index.vue'
import SelectCity from '@/components/selectCity';

export default {
    name: 'FenceList',
    components: {
        EnclosureDialog,
        comListSearch,
        SelectCity
    },
    data() {
        return {
            result: {
                data: [],
                total: 0
            },
            form: {
                pageIndex: 1,
                pageSize: 10,
                cityId: '',
                addressName: ''
            }
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData(pageIndex = 1) {
            this.form.pageIndex = pageIndex;
            const form = {
                ...this.form
            }
            !form.addressName && delete form.addressName
            !form.cityId && delete form.cityId
            const ret = await getOperatorAddressApi(form);
            if (ret.success)
                this.result = ret.data
        },
        handleAdd(data) {
            this.$router.push('/fenceMap?data=' + (data ? JSON.stringify({
                addressName: data.addressName,
                cityName: data.cityName,
                cityId: data.cityId,
                addressDetail: data.addressDetail,
                id: data.id
            }) : ''))
        },
        handleCityChange({shortCode}) {
            this.form.cityId = shortCode
        }
    }
}
</script>
