<style lang="scss" scoped>

</style>
<template>

    <div>
        <bms-dialog :isShow="isShow"
                    :title="(addOrEdit === 0 ? '新增':'编辑') + '客服时间'"
                    isShowFooter
                    @update:cancelBtn="cancelBtn"
                    @update:submitBtn="submitBtn"
                    :submitState="addOrEdit"
        >
            <div slot="content">
                <el-form :model="form" :rules="rules" ref="form" size="small" label-width="120px">
                    <el-form-item label="运营商："
                                  prop="operatorInfo.operatorName"
                                  :rules="{required: true, message: '请填写运营商', trigger: 'change'}">
                        <operator-info-input
                            :selectOperatorId="selectOperatorId"
                            :is-edit="userInfo.userType != 0"
                            ref="operatorInfoRef"
                            trigger
                            @update:getSelectOperator="getSelectOperator">
                        </operator-info-input>
                    </el-form-item>
                    <el-form-item label="供应商：">

                        <!--                        prop="supplierInfo.supplierName"-->
                        <!--                        :rules="{required: true, message: '请填写供应商', trigger: 'change'}"-->
                        <supplier-info-input
                            :selectSupplierId="selectSupplierId"
                            :is-edit="userInfo.userType == 1"
                            :operatorInfo="form.operatorInfo"
                            ref="supplierInfoRef"
                            trigger
                            @update:getSelectSupplier="getSelectSupplier">
                        </supplier-info-input>
                    </el-form-item>
                    <el-form-item label="客服时间：">
                        <div style="display: flex">
                            <bms-time
                                isTitle
                                heightBox="32"
                                placeholder="开始时间"
                                :defaultHour="defaultTime.startHour"
                                :defaultMinute="defaultTime.startMinute"
                                @onUpdate="handleStartTime"
                            >
                                <!--
                                                                        :timeMinuteStep="newTimeSel.timeMinuteStep"           -->
                            </bms-time>
                            <p style="text-align: center;margin: 0 10px">一</p>
                            <bms-time
                                isTitle
                                heightBox="32"
                                placeholder="结束时间"
                                :defaultHour="defaultTime.endHour"
                                :defaultMinute="defaultTime.endMinute"
                                @onUpdate="handleEndTime"
                            >
                                <!--
                                                                        :timeMinuteStep="newTimeSel.timeMinuteStep"           -->
                            </bms-time>
                        </div>

                    </el-form-item>
                    <el-form-item label="客服反馈时间："
                                  prop="customServiceLongestResponseTime">
                        <el-input v-model="form.customServiceLongestResponseTime" placeholder="客服反馈时间"
                                  @change="handleSelectMinute(0)"></el-input>
                    </el-form-item>
                    <el-form-item label="司机反馈时间："
                                  prop="driverLongestResponseTime">
                        <el-input v-model="form.driverLongestResponseTime" placeholder="司机反馈时间"
                                  @change="handleSelectMinute(1)"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </bms-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
import bmsDialog from "../../../../components/dialog/index"

import operatorInfoInput from "../../../../components/operatorInfoInput/index"
import supplierInfoInput from "../../../../components/supplierInfoInput/index"

import bmsTime from '@/components/timePickerWithTitle/index.vue'
import {getSupplierIdOperatorInfo} from '@/urls/index';

import service from "../../../../urls/quotationUrls"

let defaultTime = {
    startHour: '08',
    startMinute: '00',
    endHour: '18',
    endMinute: '00'
};

export default {
    //定义模版数据
    data() {
        let serviceTime = (rule, value, callback) => {
            if (!value && value !== 0) {
                callback(new Error('请填写客服反馈时间'));
            } else {
                callback();
            }
        };
        let driverTime = (rule, value, callback) => {
            if (!value && value !== 0) {
                callback(new Error('请填写司机反馈时间'));
            } else {
                callback();
            }
        };
        return {
            form: {
                operatorInfo: {},
                supplierInfo: {},
                customServiceLongestResponseTime: '',
                driverLongestResponseTime: ''
            },
            defaultTime,
            startTime: defaultTime.startHour + ':' + defaultTime.startMinute,
            endTime: defaultTime.endHour + ':' + defaultTime.endMinute,
            selectOperatorId: '',
            selectSupplierId: '',
            timeMinuteStep: ['00', '10', '20', '30', '40', '50'],
            rules: {
                customServiceLongestResponseTime: [{validator: serviceTime, trigger: 'blur'}],
                driverLongestResponseTime: [{validator: driverTime, trigger: 'blur'}],
            }

        }

    },
    props: {
        infoData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        addOrEdit: {
            type: Number,
            default: 0
        },  // 0 新增  1编辑
    },
    components: {
        bmsDialog,
        operatorInfoInput,
        supplierInfoInput,
        bmsTime
    },
    //计算属性
    computed: {
        isShow() {
            return this.$store.state.singleSetting.serviceTimeShow;
        },
        userInfo() {
            return this.$store.state.loginUserInfo
        },

    },
    //主件被加载完成
    mounted: function () {
    },
    //定义事件方法
    methods: {
        getUserInfo(type) {
            switch (parseInt(type)) {
                case 1:
                    this.getOperatorInfo(this.userInfo.refId);
                    this.selectSupplierId = this.userInfo.refId;
                    break;
                case 2:
                    this.selectOperatorId = this.userInfo.refId;
                    break;
            }
        },
        async getOperatorInfo(id) {
            const ret = await getSupplierIdOperatorInfo(id);
            if (ret)
                this.selectOperatorId = ret.operatorId;
        },
        initData(info) {
            let {
                operatorId,
                supplierId,
                customServiceWorkTime,
                customServiceLongestResponseTime,
                driverLongestResponseTime,
                id
            } = info;
            if (info) {
                this.selectOperatorId = operatorId;
                if (supplierId) this.selectSupplierId = supplierId;
                this.form.customServiceWorkTime = customServiceWorkTime;
                this.form.customServiceLongestResponseTime = customServiceLongestResponseTime;
                this.form.driverLongestResponseTime = driverLongestResponseTime;
                this.form.id = id;
                let serviceTime = customServiceWorkTime.split('-');
                this.startTime = serviceTime[0];
                this.endTime = serviceTime[1];
                let startTimeSplit = this.startTime.split(':');
                let endTimeSplit = this.endTime.split(':');
                this.defaultTime = {
                    startHour: startTimeSplit[0],
                    startMinute: startTimeSplit[1],
                    endHour: endTimeSplit[0],
                    endMinute: endTimeSplit[1]
                };
            }
        },
        getSelectOperator(item) {  // 运营商
            this.form.operatorInfo = item;
        },
        getSelectSupplier(item) {
            this.form.supplierInfo = item;
            this.selectSupplierId = item.id;
        },
        handleStartTime(value) {
            this.startTime = value
        },
        handleEndTime(value) {
            this.endTime = value
        },
        handleSelectMinute(index) {

            let time = '';
            switch (index) {
                case 0:
                    time = JSON.parse(JSON.stringify(this.form.customServiceLongestResponseTime));
                    if (parseFloat(time).toString() == "NaN") return this.$message.error('格式错误，请填写数字');
                    this.form.customServiceLongestResponseTime = parseInt(time);
                    break;
                case 1:
                    time = JSON.parse(JSON.stringify(this.form.driverLongestResponseTime));
                    if (parseFloat(time).toString() == "NaN") return this.$message.error('格式错误，请填写数字');
                    this.form.driverLongestResponseTime = parseInt(time);
                    break

            }
        },
        cancelBtn() {
            this.selectOperatorId = '';
            this.selectSupplierId = '';
            this.$refs.operatorInfoRef.clearOperatorName();
            this.$refs.supplierInfoRef.clearSupplierName();
            this.$refs.form.resetFields();
            this.form = {
                operatorInfo: {},
                supplierInfo: {},
                customServiceLongestResponseTime: '',
                driverLongestResponseTime: ''
            };
            this.$emit("update:cancelBtn")
        },
        submitBtn() {
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    let {
                            operatorInfo,
                            supplierInfo,
                            customServiceLongestResponseTime,
                            driverLongestResponseTime
                        } = this.form,
                        form = {
                            operatorId: operatorInfo.id,
                            customServiceLongestResponseTime,
                            driverLongestResponseTime,
                            customServiceWorkTime: this.startTime + '-' + this.endTime
                        };
                    if (supplierInfo.id) form.supplierId = supplierInfo.id;
                    if (this.form.id) form.id = this.form.id;
                    let res = {};
                    let type = this.addOrEdit;
                    switch (type) {
                        case 0:
                            res = await service.insertProductQuoteCustomService(form);
                            break;
                        case 1:
                            res = await this.$store.dispatch('updateServiceTime', form)
                            break
                    }
                    if (res.success && res.data) {
                        this.$message({
                            message: `${type === 0 ? '新增' : '编辑'}客服时间成功`,
                            type: 'success'
                        });
                        this.$parent.handleInitData(1);
                        this.cancelBtn()
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })

        },

    },
    //监听模版变量
    watch: {}

}
</script>

