//报价工具状态
export const status = [
    {
        value: '',
        label: '全部状态'
    },
    {
        value: '2',
        label: '待提交'
    },
    {
        value: '3',
        label: '待审核'
    },
    {
        value: '0',
        label: '失效'
    },
    {
        value: '1',
        label: '生效'
    }
]
/**
 * 包车类型
 */
export const carCharterTypeList = [
    {
        label: '半日包车4小时50公里',
        distance: 50,
        duration: 240,
        value: '999',
    }, {
        label: '半日包车4小时100公里',
        distance: 100,
        duration: 240,
        value: '998',
    }, {
        label: '全日包车8小时100公里',
        distance: 100,
        duration: 480,
        value: '997',
    },
    {
        label: '全日包车8小时200公里',
        distance: 200,
        duration: 480,
        value: '1'
    },
    {
        label: '全日包车8小时300公里',
        distance: 300,
        duration: 480,
        value: '993'
    },
    {
        label: '全日包车10小时100公里',
        distance: 100,
        duration: 600,
        value: '996'
    },
    {
        label: '全日包车10小时200公里',
        distance: 200,
        duration: 600,
        value: '995'
    },
    {
        label: '全日包车10小时300公里',
        distance: 300,
        duration: 600,
        value: '994'
    },
    {
        label: '全日包车10小时500公里',
        distance: 500,
        duration: 600,
        value: '992'
    },
    {
        label: '非标准套餐',
        value: 'X-Y',
        distance: 0,
        duration: 0
    }
]
