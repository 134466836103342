<style lang="scss" scoped>
    .public-dialog {

    }

    .public-dialog /deep/ {
        .el-form-item__label {
            padding: 0;
        }
        .el-button{
            margin-left: 10px;
        }
    }

    .public-dialog-close {
        position: absolute;
        right: 16px;
        top: 20px;
        cursor: pointer;

        i {
            font-size: 26px;
            color: #999;
            &:hover{
                color: #409EFF;
            }
        }
    }
</style>
<template>

    <div>
        <el-dialog :title="title"
                   :width="dialogWidth"
                   :close-on-click-modal="false"
                   :show-close="false"
                   custom-class="el-dialog-middle-center"
                   class="public-dialog"
                   :visible.sync="isShow">
            <div class="public-dialog-close" v-if="isShowClose">
                <i class="el-icon-close" @click="closeBtn"></i>
            </div>
            <slot name="content"></slot>
            <div slot="footer" v-if="isShowFooter">
                <el-button size="small" @click="cancelBtn">取 消</el-button>
                <el-button size="small"
                           type="primary"
                           v-if="submitState === 0"
                           v-debounce="submitBtn">
                    确 定
                </el-button>
                <el-popover
                        v-if="submitState === 1"
                        placement="top"
                        width="180"
                        trigger="click"
                        v-model="isSecond">
                    <p style="padding: 5px 0 10px 0;text-align: center">保存即为生效，是否继续？</p>
                    <div style="text-align: right; margin: 0">
                        <el-button
                                size="mini"
                                type="text"
                                style="padding: 7px 15px;"
                                @click="cancelBtn">
                            取消
                        </el-button>
                        <el-button
                                type="primary"
                                size="mini"
                                v-debounce="submitBtn">
                            确定
                        </el-button>
                    </div>
                    <el-button
                            slot="reference"
                            size="small"
                            type="primary">
                        保 存
                    </el-button>
                </el-popover>


            </div>
        </el-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            return {
                dialogWidth: this.width + 'px',
                isSecond: false

            }
        },
        props: {
            isShow: {
                type: Boolean,
                default: false
            },
            isShowClose: {
                type: Boolean,
                default: false
            },
            isShowFooter: {
                type: Boolean,
                default: false
            },
            title: null,
            width: {
                type: Number,
                default: 400
            },
            submitState: {
                type: Number,
                default: 0
            }
        },
        components: {},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
        },
        //定义事件方法
        methods: {
            cancelBtn() {
                this.isSecond = false;
                this.$emit("update:cancelBtn")
            },
            submitBtn() {
                this.isSecond = false;
                this.$emit("update:submitBtn")
            },
            closeBtn() {
                this.$emit("update:closeBtn")
            }
        },
        //监听模版变量
        watch: {}

    }
</script>

