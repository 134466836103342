<style lang="scss" scoped>
    .user {
        margin-right: 20px;
        color: #666;
    }
</style>
<template>
    <div>
        <span class="user">
            {{connectInfo.name}},{{connectInfo.mobile}},{{connectInfo.carType}}
        </span>
    </div>

</template>

<script type="text/ecmascript-6">
    import {getProductReceiverInfoList} from '@/www/urls/quotationUrls.js'

    export default {
        //定义模版数据
        data() {
            return {
                list: []
            }
        },
        props: {
            value: {
                type: String,
                default: ''
            },
            operatorId: {
                type: String,
                default: null
            },
            supplierId: {
                type: String,
                default: null
            },
            carType: {
                type: Number,
                default: null
            }
        },
        computed: {
            connectInfo() {
                if (this.list.length > 0)
                    return this.list.find(({id}) => this.value === id) || this.list[0]
                else return {}
            }
        },
        //定义事件方法
        methods: {
            async getData() {
                this.list = [];
                if (!this.operatorId || !this.carType)
                    return;
                let form = {};
                if (this.operatorId)
                    form = {operatorId: this.operatorId}
                if (this.supplierId)
                    form = {supplierId: this.supplierId}
                form.carType = this.carType;
                this.form = form;
                const ret = await getProductReceiverInfoList(form);
                if (ret.success) {
                    if (ret.data.length > 0)
                        this.list = ret.data || [];
                    else {
                        try {
                            await this.$confirm('当前车型没有设置应单人，请先前往设置！', '提示', {
                                confirmButtonText: '立即设置',
                                cancelButtonText: '取消',
                                type: 'error'
                            });
                            this.$router.push('/answerContact')
                        } catch (e) {
                            console.log(e);
                        }
                    }
                } else this.$message.error(ret.errors[0].message)
            },
            //用户选择值
            handleChange() {

            }
        },
        watch: {
            supplierId() {
                this.getData();
            },
            operatorId() {
                this.getData();
            },
            carType() {
                this.getData();
            },
            connectInfo(n) {
                this.$emit('input', n.id)
            }
        }
    }
</script>
