<style lang="scss" scoped>
.edit-quote-form {
    padding-bottom: 20px;

    .eTrip-box-card {
        p {
            margin-bottom: 10px;
        }
    }

    .label-title {
        border-left: 2px solid #319BF7;
        padding-left: 10px;
        font-weight: 700;
        font-size: 14px;
    }

    .application-scope {
        float: left;
        margin-right: 10px;

        tr {
            height: 34px;

            th {
                border-width: 1px;
                border-color: #DCDFE6;
                border-style: solid;
                text-align: left;
                background: #f0f2f5;

                span {
                    padding-left: 5px;
                }
            }

            td {
                text-align: left;
                padding: 0 5px;
                height: 34px;
                border-width: 0 1px 1px 1px;
                border-color: #DCDFE6;
                border-style: solid;
            }
        }

        .application-scope-btn {
            float: left;
        }
    }

    .next-day_title {
        width: 35px;
        float: left;
        color: #909399;
        line-height: 34px;
        padding-left: 25px;
        position: relative;

        &:before {
            content: "";
            display: inline-block;
            width: 10px;
            height: 1px;
            background: #909399;
            position: absolute;
            left: 10px;
            top: 50%;
            margin-top: -1px;
        }
    }

    .price-period-start, .price-period-end {
        width: 100px;
        float: left;
        margin-bottom: 0
    }

    .price-period-start /deep/ {
        .el-form-item__error {
            width: 235px;
        }
    }

    .price-period-end /deep/ {
        .el-form-item__error {
            display: none;
            opacity: 0;
        }
    }

    .add-delete-btn {
        width: 160px;
        float: left;
        margin-left: 10px;
        display: flex;
        align-items: center;
        height: 34px;

        .el-button {
            padding: 0;
        }
    }

    &::v-deep {
        .third-product-id {
            .el-input-group--append {
                .el-input__inner {
                    border-right: 0;
                }
            }

            .el-input-group__append {
                background: transparent;
                padding: 0 5px;

                .el-button {
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
}
</style>
<style lang="scss">
.edit-quote-form {
    .application-scope {
        .el-input__inner {
            border: none;
        }

        .el-form-item--small.el-form-item {
            margin-bottom: 0;
        }

        .el-input__inner {
            padding: 0;
        }
    }

    /*.el-input__inner {*/
    /*!*padding: 0 5px;*!*/
    /*}*/
    .el-input__suffix {
        font-size: 12px;
        letter-spacing: 1px
    }

    .el-form-item__label {
        padding-right: 5px;
    }
}

.add-night-dialog {
    width: 560px;

    .el-button {
        padding: 8px 20px;
    }

    .next-day_title {
        width: 35px;
        float: left;
        color: #909399;
        line-height: 34px;
        padding-left: 25px;
        position: relative;

        &:before {
            content: "";
            display: inline-block;
            width: 10px;
            height: 1px;
            background: #909399;
            position: absolute;
            left: 10px;
            top: 50%;
            margin-top: -1px;
        }
    }
}


</style>
<template>
    <div class="edit-quote-form">
        <div>
            <el-row>
                <el-form label-position="right" label-width="100px" :model="quoteForm" ref="quoteForm"
                         :rules="rulesForm" size="small">
                    <div class="eTrip-box-card" v-if="!isDisabled">
                        <p><span class="label-title">报价类型</span></p>
                        <quotation-type v-model="quoteForm.quoteType" :disabled="isDisabled"
                                        @change="quoteTypeChange"></quotation-type>
                    </div>
                    <div class="eTrip-box-card">
                        <p><span class="label-title">规则信息</span></p>
                        <el-form-item label="规则编号：" v-if="quoteForm.ruleNo&&!isDisabled" style="margin-bottom: 0">
                            <p>{{ quoteForm.ruleNo }}</p>
                        </el-form-item>
                        <base-info :info="quoteForm" v-if="isDisabled"></base-info>
                        <el-row v-else>
                            <el-col :span="6">
                                <el-form-item label="渠道：" prop="quoteRuleRelProductDto.quoteChannel"
                                              :rules="{ required: true, message: '请选择渠道', trigger: 'change' }">
                                    <el-select v-model="quoteForm.quoteRuleRelProductDto.quoteChannel"
                                               style="width: 100%" :disabled="isDisabled">
                                        <el-option v-for="item in channel"
                                                   :value="item.value"
                                                   :label="item.label"
                                                   :key="item.value"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6" v-if="quoteForm.quoteRuleRelProductDto.quoteChannel === 8">
                                <el-form-item label="飞猪规则编号：" label-width="120px"
                                              prop="quoteRuleRelProductDto.thirdProductId" class="third-product-id"
                                              :rules="{ required: true, message: '请填写飞猪规则编号', trigger: 'blur' }">
                                    <div class="box-flex">
                                        <el-input :disabled="isDisabled"
                                                  v-model.number="quoteForm.quoteRuleRelProductDto.thirdProductId">
                                            <template slot="append">
                                                <el-popover
                                                    placement="top-start"
                                                    width="200"
                                                    trigger="hover"
                                                    content="该车辆报价所适用的飞猪规则id（需要登录飞猪商户crs后台配置生成规则）">
                                                    <el-button slot="reference" type="text">
                                                        <i class="el-icon-warning-outline"></i>
                                                    </el-button>
                                                </el-popover>
                                            </template>
                                        </el-input>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="城市："
                                              prop="quoteRuleRelProductDto.cityId"
                                              :rules="{ required: true, message: '请选择城市', trigger: 'change' }">
                                    <el-select v-model="quoteForm.quoteRuleRelProductDto.cityId"
                                               style="width: 100%"
                                               @change="selectCity(0)"
                                               filterable
                                               placeholder="填写城市"
                                               :disabled="isDisabled">
                                        <el-option
                                            v-for="city in serverCityList"
                                            :key="city.shortCode"
                                            :label="city.name"
                                            :value="city.shortCode">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="目的地城市："
                                              prop="quoteRuleRelProductDto.destinationCityId">
                                    <el-select v-model="quoteForm.quoteRuleRelProductDto.destinationCityId"
                                               style="width: 100%"
                                               @change="selectCity(1)"
                                               filterable
                                               clearable
                                               placeholder="填写目的地城市"
                                               :disabled="isDisabled">
                                        <el-option
                                            v-for="city in serverCityList"
                                            :key="city.shortCode"
                                            :label="city.name"
                                            :value="city.shortCode">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="是否跨城：" v-if="quoteForm.quoteType=== 2"
                                              prop="quoteRuleRelProductDto.isAcross"
                                              :rules="{required:true,message:'请选择是否跨城'}">
                                    <el-radio-group v-model="quoteForm.quoteRuleRelProductDto.isAcross">
                                        <el-radio-button :label="vm.value" v-for="(vm,index) in crossCitys"
                                                         :key="index">
                                            {{ vm.label }}
                                        </el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="车型："
                                              prop="quoteRuleRelProductDto.vehicleType"
                                              :rules="{ required: true, message: '请选择车型', trigger: 'change' }">
                                    <div>
                                        <seat-model v-model="quoteForm.quoteRuleRelProductDto.vehicleType"
                                                    :disabled="isDisabled">
                                        </seat-model>
                                    </div>

                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="类型："
                                              v-if="quoteForm.quoteType !== 2"
                                              prop="quoteRuleRelProductDto.type"
                                              :rules="{ required: true, message: '请选择类型', trigger: 'change' }">
                                    <el-select v-model="quoteForm.quoteRuleRelProductDto.type"
                                               style="width: 100%"
                                               @change="selectForm"
                                               :disabled="isDisabled">
                                        <el-option
                                            v-for="productForm in productFormList"
                                            :key="productForm.value"
                                            :label="productForm.label"
                                            :value="productForm.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="运营商：" prop="quoteRuleRelProductDto.operatorId"
                                              :rules="{ required: true, message: '请选择运营商', trigger: 'change' }">
                                    <operator-select v-model="quoteForm.quoteRuleRelProductDto.operatorId"
                                                     :disabled="isDisabled">
                                    </operator-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="供应商：">
                                    <supplier-select v-model="quoteForm.quoteRuleRelProductDto.supplierId"
                                                     userType="2"
                                                     :operatorId="quoteForm.quoteRuleRelProductDto.operatorId"
                                                     :disabled="isDisabled">
                                    </supplier-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="渠道费率：" prop="quoteRuleRelProductDto.quoteChannelRatio"
                                              :rules="[{ required: true, message: '请填写费率', trigger: 'blur' },
                                                      {type:'number',min:0,max:100,message:'费率在0-100之间'}]">
                                    <el-input type="number" :disabled="isDisabled"
                                              :min="0"
                                              :max="100"
                                              :maxlength="8"
                                              v-model.number="quoteForm.quoteRuleRelProductDto.quoteChannelRatio">
                                        <span slot="suffix">%</span>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="平台费率："
                                              prop="quoteRuleRelProductDto.platformRatio"
                                              :rules="[{ required: true, message: '请填写费率', trigger: 'blur'},
                                                      {type:'number',min:0,max:100,message:'费率在0-100之间'}]">
                                    <el-input type="number" :disabled="isDisabled"
                                              v-model.number="quoteForm.quoteRuleRelProductDto.platformRatio"
                                              :maxlength="8"
                                              :min="0"
                                              :max="100">
                                        <span slot="suffix">%</span>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item label="包车类型："
                                      v-if="quoteForm.quoteType === 2"
                                      prop="quoteRuleRelProductDto.charteredCarDuration"
                                      :rules="{ required: true, message: '请选择类型', trigger: 'blur' }">
                            <el-select v-model="quoteForm.quoteRuleRelProductDto.charteredCarDuration"
                                       style="width: 370px"
                                       :disabled="!isEdit"
                                       @change="selectCharterType">
                                <el-option
                                    v-for="productForm in carCharterTypeList"
                                    :key="productForm.value"
                                    :label="productForm.label"
                                    :value="productForm.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <div v-if="quoteForm.quoteType !== 2" style="display: flex">
                            <el-form-item label="站点：">
                                <el-select v-if="[7,8,27,28].includes(quoteForm.quoteRuleRelProductDto.type)"
                                           v-model="quoteForm.quoteRuleRelProductDto.stationCode"
                                           @change="selectStation"
                                           :disabled="quoteForm.quoteRuleRelProductDto.type==12&&!isEdit">
                                    <el-option label="无站点限制" value=""></el-option>
                                    <el-option
                                        v-for="s in stationList"
                                        :key="s.airportCode"
                                        :label="s.station"
                                        :value="s.airportCode">
                                    </el-option>
                                </el-select>
                                <el-select v-else
                                           v-model="quoteForm.quoteRuleRelProductDto.stationName"
                                           :disabled="quoteForm.quoteRuleRelProductDto.type==12&&!isEdit">
                                    <el-option label="无站点限制" value=""></el-option>
                                    <el-option
                                        v-for="s in stationList"
                                        :key="s.station"
                                        :label="s.station"
                                        :value="s.station">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label-width="10px"
                                          v-if="quoteForm.quoteRuleRelProductDto.type==7||
                                          quoteForm.quoteRuleRelProductDto.type==8">
                                <el-select v-model="quoteForm.quoteRuleRelProductDto.stationTerminal"
                                           style="min-width: 200px"
                                           :disabled="!isEdit">
                                    <el-option value="" label="无航站楼限制">
                                    </el-option>
                                    <el-option
                                        v-for="(s,index) in stationNameList"
                                        :key="index"
                                        :label="s"
                                        :value="s">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <el-form-item label="供应商费率：" label-width="100px"
                                      prop="quoteRuleRelProductDto.supplierRatio"
                                      :rules="[{ required: true, message: '请填写费率', trigger: 'blur' },
                                          {type:'number',min:0,max:100,message:'费率在0-100之间'}]">
                            <el-input type="number"
                                      style="width: 370px"
                                      :maxlength="8"
                                      :disabled="!isEdit"
                                      v-model.number="quoteForm.quoteRuleRelProductDto.supplierRatio"
                                      :min="0"
                                      :max="100">
                                <span slot="suffix">%</span>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="订单联系人："
                                      prop="quoteRuleRelProductDto.connectInfoId"
                                      :rules="[{required: true, message: '请先新建订单联系人', trigger: 'change'}]">
                            <order-connect-select v-model="quoteForm.quoteRuleRelProductDto.connectInfoId"
                                                  :operatorId="quoteForm.quoteRuleRelProductDto.operatorId"
                                                  :supplierId="quoteForm.quoteRuleRelProductDto.supplierId">
                            </order-connect-select>
                        </el-form-item>
                        <el-form-item label="应单人："
                                      prop="quoteRuleRelProductDto.receiverInfoId"
                                      :rules="[{required: true, message: '请先新建车型对应应单人', trigger: 'change'}]">
                            <order-receiver-select v-model="quoteForm.quoteRuleRelProductDto.receiverInfoId"
                                                   :operatorId="quoteForm.quoteRuleRelProductDto.operatorId"
                                                   :carType="quoteForm.quoteRuleRelProductDto.vehicleType"
                                                   :supplierId="quoteForm.quoteRuleRelProductDto.supplierId">
                            </order-receiver-select>
                        </el-form-item>
                        <div>
                            <el-form-item label="预订规则：" prop="quoteRuleRelProductDto.reservationNotesId"
                                          :rules="{ required: true, message: '请选择【预订规则】', trigger: 'change'}">
                                <el-select v-model="quoteForm.quoteRuleRelProductDto.reservationNotesId"
                                           placeholder="请选择【预订规则】"
                                           :disabled="!isEdit"
                                           style="width: 370px;">
                                    <el-option v-for="item in operatorReservationNotes" :value="item.id"
                                               :key="item.id"
                                               :label="item.reservationNotesName"></el-option>
                                </el-select>
                                <span @click="handleNotice(quoteForm.quoteRuleRelProductDto.reservationNotesId, 1)"
                                      class="notice-btn"
                                      v-if="quoteForm.quoteRuleRelProductDto.reservationNotesId">
                                            <a href="javascript:void(0)" style="color: #319BF7">&nbsp;&nbsp;预览</a>
                                        </span>
                                <el-tooltip class="item" effect="light" content="如需添加【预订须知】模板请联系总部管理员"
                                            placement="top-end">
                                    <i class="icon icon-wenhao" style="color: #319BF7"></i>
                                </el-tooltip>
                            </el-form-item>

                        </div>
                        <div>
                            <el-form-item label="退改规则：" prop="quoteRuleRelProductDto.refundRuleId"
                                          :rules="{required: true, message: '请选择【退改规则】', trigger: 'change'}">
                                <el-select v-model="quoteForm.quoteRuleRelProductDto.refundRuleId"
                                           :disabled="!isEdit"
                                           placeholder="请选择【退改规则】" style="width: 370px;">
                                    <el-option v-for="item in operatorRefundRule" :value="item.id"
                                               :key="item.id"
                                               :label="item.refundRuleName"></el-option>
                                </el-select>
                                <span @click="handleNotice(quoteForm.quoteRuleRelProductDto.refundRuleId, 2)"
                                      class="notice-btn" v-if="quoteForm.quoteRuleRelProductDto.refundRuleId">
                            <a href="javascript:void(0)" style="color: #319BF7">&nbsp;&nbsp;预览</a>
                        </span>
                                <el-tooltip class="item" effect="light" content="如需添加【退改规则】模板请联系总部管理员"
                                            placement="bottom-end">
                                    <i class="icon icon-wenhao" style="color: #319BF7"></i>
                                </el-tooltip>
                            </el-form-item>

                        </div>
                        <div>
                            <el-form-item label="客服时间：" prop="quoteRuleRelProductDto.customServiceId"
                                          :rules="{required: true, message: '请选择客服时间', trigger: 'change'}">
                                <service-time-select
                                    :disabled="!isEdit"
                                    :operatorId="quoteForm.quoteRuleRelProductDto.operatorId"
                                    :supplierId="quoteForm.quoteRuleRelProductDto.supplierId"
                                    v-model="quoteForm.quoteRuleRelProductDto.customServiceId">
                                </service-time-select>
                            </el-form-item>

                        </div>
                    </div>
                    <div class="eTrip-box-card">
                        <p><span class="label-title">服务区域</span></p>
                        <el-row>
                            <el-col :span="9">
                                <el-form-item label="出发地栅栏：">
                                    <el-row>
                                        <el-col :span="9">
                                            <el-select multiple v-model="departureFenceCityList"
                                                       style="width: 80%"
                                                       filterable
                                                       @change="getAddressFence(0)"
                                                       placeholder="围栏城市">
                                                <el-option
                                                    v-for="city in serverCityList"
                                                    :key="city.shortCode"
                                                    :label="city.name"
                                                    :value="city.shortCode">
                                                </el-option>
                                            </el-select>
                                        </el-col>
                                        <el-col :span="15">
                                            <el-select
                                                v-model="departureAddressList"
                                                multiple
                                                filterable
                                                :disabled="!isEdit"
                                                placeholder="请选择出发地栅栏"
                                                @change="departureOperator(1, departureAddressList)">
                                                <el-option
                                                    v-for="s in departureAddressByMerchantList"
                                                    :key="s.addressId"
                                                    :label="s.addressName"
                                                    :value="s.addressId">
                                                </el-option>
                                            </el-select>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                            </el-col>
                            <el-col :span="14">
                                <enclosure-dialog :ids="departureAddressList"
                                                  :dataList="departureAddressByMerchantList"/>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="9">
                                <el-form-item label="到达地栅栏：" v-if="quoteForm.quoteType !== 2">
                                    <el-row>
                                        <el-col :span="9">
                                            <el-select multiple v-model="destinationFenceCityList"
                                                       style="width: 80%"
                                                       filterable
                                                       @change="getAddressFence(1)"
                                                       placeholder="围栏城市">
                                                <el-option
                                                    v-for="city in serverCityList"
                                                    :key="city.shortCode"
                                                    :label="city.name"
                                                    :value="city.shortCode">
                                                </el-option>
                                            </el-select>
                                        </el-col>
                                        <el-col :span="15">
                                            <el-select
                                                v-model="destinationAddressList"
                                                multiple
                                                filterable
                                                :disabled="!isEdit"
                                                placeholder="请选择到达地栅栏"
                                                @change="departureOperator(2, destinationAddressList)">
                                                <el-option
                                                    v-for="s in destinationAddressByMerchantList"
                                                    :key="s.addressId"
                                                    :label="s.addressName"
                                                    :value="s.addressId">
                                                </el-option>
                                            </el-select>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                            </el-col>
                            <el-col :span="14">
                                <enclosure-dialog :ids="destinationAddressList"
                                                  :dataList="destinationAddressByMerchantList"/>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="eTrip-box-card">
                        <p><span class="label-title">基础报价</span></p>
                        <div v-if="quoteForm.quoteType === 0 || quoteForm.quoteType === 2">
                            <el-form-item label="基础价：" label-width="120px" prop="basicPrice">
                                <c-input style="width: 140px"
                                         type="number"
                                         :min="0"
                                         :disabled="!isEdit"
                                         v-model="quoteForm.basicPrice">
                                    <span slot="suffix">元</span>
                                </c-input>
                            </el-form-item>
                            <div class="clear" v-if="quoteForm.quoteType === 0">
                                <div style="width: 260px;float: left;">
                                    <el-form-item label="超公里价：" label-width="120px" prop="exceedDistancePrice">
                                        <c-input style="width: 140px"
                                                 type="number"
                                                 :min="0"
                                                 :disabled="!isEdit"
                                                 v-model="quoteForm.exceedDistancePrice">
                                            <span slot="suffix">元/公里</span>
                                        </c-input>
                                    </el-form-item>
                                </div>
                                <div style="width: 260px;float: left;padding-left: 20px">
                                    <el-form-item label="基础公里数：" label-width="120px" prop="basicDistance">
                                        <c-input style="width: 140px"
                                                 type="number"
                                                 :min="0"
                                                 :disabled="!isEdit"
                                                 v-model="quoteForm.basicDistance">
                                            <span slot="suffix">公里</span>
                                        </c-input>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="clear" v-if="quoteForm.quoteType === 0">
                                <div style="width: 260px;float: left;">
                                    <el-form-item label="超时长价："
                                                  label-width="120px"
                                                  prop="exceedTimePrice"
                                                  :style="{marginBottom:(quoteForm.quoteType === 1? '':0)}">
                                        <c-input style="width: 140px"
                                                 type="number"
                                                 :min="0"
                                                 :disabled="!isEdit"
                                                 v-model="quoteForm.exceedTimePrice">
                                            <span slot="suffix">元/分钟</span>
                                        </c-input>
                                    </el-form-item>
                                </div>
                                <div style="width: 240px;float: left;padding-left: 20px">
                                    <el-form-item label="基础时长："
                                                  label-width="120px"
                                                  prop="basicTime"
                                                  :style="{marginBottom:(quoteForm.quoteType === 1? '':0)}">
                                        <c-input style="width: 140px"
                                                 type="number"
                                                 :min="0"
                                                 :disabled="!isEdit||quoteForm.quoteType === 2"
                                                 v-model="quoteForm.basicTime">
                                            <span slot="suffix">分钟</span>
                                        </c-input>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                        <div v-if="quoteForm.quoteType === 1">
                            <div class="clear" style="display: flex"
                                 v-for="(item, index) in quoteForm.intervalTimePeriodRecommendDtos"
                                 :key="index">
                                <div style="width: 260px;float: left;">
                                    <el-form-item :label="'一口价' + (index + 1) + '：'" label-width="100px"
                                                  :rules="[{validator:intervalTimePeriodRecommendPriceValidator,trigger: 'blur'}]"
                                                  :prop="'intervalTimePeriodRecommendDtos.' + index + '.price'">
                                        <c-input style="width: 140px"
                                                 type="number"
                                                 :min="0"
                                                 :disabled="!isEdit"
                                                 v-model="item.price">
                                            <span slot="suffix">元</span>
                                        </c-input>
                                    </el-form-item>
                                </div>
                                <div style="width: auto;float: left;">
                                    <el-form-item label="时段：" label-width="58px" required>
                                        <div style="width: auto">
                                            <div class="clear" style="display: flex">
                                                <el-form-item
                                                    class="price-period-start"
                                                    :rules="rulesForm.timeInterval1"
                                                    :prop="'intervalTimePeriodRecommendDtos.' + index + '.periodBegin'">
                                                    <time-picker-with-title
                                                        is-title
                                                        :index-ident="{index}"
                                                        height-box="32"
                                                        :dateHourSelect="daytimePeriodHourStep"
                                                        :default-hour="item.periodTime.start[0]"
                                                        :default-minute="item.periodTime.start[1]"
                                                        placeholder="选择时段"
                                                        :disabled="!isEdit"
                                                        @onUpdate="handleTimeInterval1"
                                                        :time-minute-step="['00', '30']"
                                                    ></time-picker-with-title>

                                                </el-form-item>
                                                <div class="next-day_title" style="width: 5px">&nbsp;</div>

                                                <el-form-item class="price-period-end"
                                                              :rules="rulesForm.timeInterval1"
                                                              :prop="'intervalTimePeriodRecommendDtos.' + index + '.periodEnd'">
                                                    <time-picker-with-title
                                                        is-title
                                                        :index-ident="{index}"
                                                        height-box="32"
                                                        :disabled="!isEdit"
                                                        :dateHourSelect="daytimePeriodHourStep"
                                                        :default-hour="item.periodTime.end[0]"
                                                        :default-minute="item.periodTime.end[1]"
                                                        placeholder="选择时段"
                                                        @onUpdate="handleTimeInterval2"
                                                        :time-minute-step="['00', '30']"
                                                    ></time-picker-with-title>

                                                </el-form-item>
                                            </div>
                                        </div>
                                    </el-form-item>
                                </div>
                                <div class="add-delete-btn">
                                    <el-button type="text"
                                               v-if="index!==0"
                                               :disabled="!isEdit"
                                               style="padding-bottom: 0"
                                               @click="handelDelete(index,'intervalTimePeriodRecommendDtos')">
                                        <i style="font-size: 24px" class="icon el-icon-remove-outline"></i>
                                    </el-button>
                                    <el-button type="text"
                                               :disabled="!isEdit"
                                               @click="handleAdd('intervalTimePeriodRecommendDtos')"
                                               style="padding-bottom: 0"
                                               v-if="index===quoteForm.intervalTimePeriodRecommendDtos.length-1 && quoteForm.intervalTimePeriodRecommendDtos.length < 10">
                                        <i style="font-size: 24px" class="icon el-icon-circle-plus-outline"></i>
                                    </el-button>
                                </div>
                            </div>
                        </div>
                        <div class="clear" style="margin-top: 16px" v-if=" quoteForm.quoteType === 2">
                            <div style="width: 260px;float: left;">
                                <el-form-item label="包车夜间费加价：" label-width="120px" prop="charterNightPrice">
                                    <c-input style="width: 140px"
                                             type="number"
                                             :min="0"
                                             :disabled="!isEdit"
                                             v-model="quoteForm.charterNightPrice">
                                        <span slot="suffix">元</span>
                                    </c-input>
                                </el-form-item>
                            </div>
                            <div style="width: 640px;float: left;padding-left: 20px">
                                <el-form-item label="包车夜间时间段：" label-width="120px" prop="charterNightPeriod">
                                    <el-row>
                                        <el-col :span="8">
                                            <el-time-picker
                                                style="width: 140px"
                                                value-format="HH:mm"
                                                format="HH:mm"
                                                v-model="quoteForm.charterNightPeriod.nightPeriodBegin"
                                                :picker-options="{format:'HH:mm'}">
                                            </el-time-picker>
                                        </el-col>
                                        <el-col :span="1">
                                            -
                                        </el-col>
                                        <el-col :span="8">
                                            <span style="color: #666">次日</span>
                                            <el-time-picker
                                                style="width: 140px"
                                                value-format="HH:mm"
                                                format="HH:mm"
                                                v-model="quoteForm.charterNightPeriod.nightPeriodEnd"
                                                :picker-options="{format:'HH:mm'}">
                                            </el-time-picker>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                            </div>
                        </div>
                        <div style="margin-top: 16px;"
                             v-if="quoteForm.quoteRuleRelProductDto.quoteChannel===1&&['0','2'].includes(userInfo.userType)">
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="商旅倍率：" label-width="100px">
                                        <el-input-number style="width: 200px"
                                                         placeholder="请输入商旅倍率"
                                                         :step=".1"
                                                         v-model="quoteForm.quoteRuleRelProductDto.miceRatio"
                                                         :min="0"
                                                         :max="1"
                                                         :controls="false"
                                                         label="请输入商旅倍率">
                                        </el-input-number>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="南航倍率：" label-width="100px">
                                        <el-input-number style="width: 200px"
                                                         placeholder="请输入南航倍率"
                                                         :step=".1"
                                                         :controls="false"
                                                         v-model="quoteForm.quoteRuleRelProductDto.nanhangRatio"
                                                         :min="0"
                                                         label="请输入南航倍率">
                                        </el-input-number>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="航旅倍率：" label-width="100px">
                                        <el-input-number style="width: 200px"
                                                         placeholder="请输入航旅倍率"
                                                         :step=".1"
                                                         :controls="false"
                                                         v-model="quoteForm.quoteRuleRelProductDto.hanglvRatio"
                                                         :min="0"
                                                         label="请输入航旅倍率">
                                        </el-input-number>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                    <div class="eTrip-box-card" v-if="quoteForm.quoteType === 0">
                        <p><span class="label-title">设置特殊时段报价：</span></p>
                        <special-time-quotation ref="special-time-quotation"
                                                v-model="quoteForm.quoteRuleSpecialTimePriceDtos">
                        </special-time-quotation>
                    </div>
                    <div class="eTrip-box-card" v-if="quoteForm.quoteType === 0">
                        <p><span class="label-title">超额报价</span></p>
                        <el-form-item label-width="112px" label="等候费：" prop="waitingFee">
                            <c-input style="width: 140px"
                                     type="number"
                                     :min="0"
                                     :disabled="!isEdit"
                                     v-model="quoteForm.waitingFee">
                                <span slot="suffix">元/分钟</span>
                            </c-input>
                        </el-form-item>
                        <el-row>
                            <el-col>
                                <div style="width: 300px;float: left;">
                                    <el-form-item label-width="140px" label="回空里程费：超出" prop="allowBackDistance">
                                        <c-input style="width: 140px"
                                                 type="number"
                                                 :min="0"
                                                 :disabled="!isEdit"
                                                 :decimal="1"
                                                 v-model="quoteForm.allowBackDistance">
                                            <span slot="suffix">公里</span>
                                        </c-input>
                                    </el-form-item>
                                </div>
                                <div style="width: 240px;float: left;">
                                    <el-form-item label="以上计回空" prop="exceedBackPrice">
                                        <c-input style="width: 140px"
                                                 type="number"
                                                 :min="0"
                                                 :disabled="!isEdit"
                                                 v-model="quoteForm.exceedBackPrice">
                                            <span slot="suffix">元/公里</span>
                                        </c-input>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col v-for="(vm,index) in quoteForm.longDistanceGradeList"
                                    style="margin-bottom: 10px"
                                    :key="vm.key">
                                <div style="width: 300px;float: left;">
                                    <el-form-item label-width="140px"
                                                  :label="`长途费${index+1}：超出`"
                                                  :prop="'longDistanceGradeList.' + index + '.longDistance'"
                                                  :rules="[{required: true, message: '请填写公里数', trigger: 'blur' },
                                                  {validator:longDistanceValidator}]"
                                                  style="margin-bottom: 0">
                                        <c-input style="width: 140px"
                                                 type="number"
                                                 :decimal="1"
                                                 :disabled="!isEdit"
                                                 v-model="vm.longDistance">
                                            <span slot="suffix">公里</span>
                                        </c-input>
                                    </el-form-item>
                                </div>
                                <div style="width: 240px;float: left;">
                                    <el-form-item label="以上计长途"
                                                  :rules="{required: true, message: '请填写金额', trigger: 'blur' }"
                                                  :prop="'longDistanceGradeList.' + index + '.longDistancePrice'"
                                                  style="margin-bottom: 0">
                                        <c-input style="width: 140px"
                                                 type="number"
                                                 :disabled="!isEdit"
                                                 v-model="vm.longDistancePrice">
                                            <span slot="suffix">元/公里</span>
                                        </c-input>
                                    </el-form-item>
                                </div>
                                <div class="add-delete-btn">
                                    <el-button type="text"
                                               v-if="index!==0"
                                               :disabled="!isEdit"
                                               style="padding-bottom: 0"
                                               @click="handelDelete(index,'longDistanceGradeList')">
                                        <i style="font-size: 24px" class="icon el-icon-remove-outline"></i>
                                    </el-button>
                                    <el-button type="text"
                                               :disabled="!isEdit"
                                               @click="handleAdd('longDistanceGradeList')"
                                               style="padding-bottom: 0"
                                               v-if="index===quoteForm.longDistanceGradeList.length-1">
                                        <i style="font-size: 24px" class="icon el-icon-circle-plus-outline"></i>
                                    </el-button>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <!--包车阶段超公里加价-->
                    <div class="eTrip-box-card"
                         v-if="quoteForm.quoteType === 2&&quoteForm.quoteRuleRelProductDto.isAcross===1">
                        <p>
                            <span class="label-title">
                                阶段超公里加价(默认减50公里)
                                 <el-button type="text"
                                            :disabled="!isEdit"
                                            @click="handleAdd('returnDistanceGradeList')"
                                            style="padding-bottom: 0">
                                        <i style="font-size: 24px" class="icon el-icon-circle-plus-outline"></i>
                                    </el-button>
                            </span>
                        </p>
                        <el-row>
                            <el-col v-for="(vm,index) in quoteForm.returnDistanceGradeList"
                                    style="margin-bottom: 10px"
                                    :key="vm.key">
                                <div style="width: 300px;float: left;">
                                    <el-form-item label-width="140px"
                                                  :label="`回程长途费${index+1}：超出`"
                                                  :prop="'returnDistanceGradeList.' + index + '.longDistance'"
                                                  :rules="{required: true, message: '请填写金额', trigger: 'blur' }"
                                                  style="margin-bottom: 0">
                                        <c-input style="width: 140px"
                                                 type="number"
                                                 :decimal="1"
                                                 :min="0"
                                                 :disabled="!isEdit"
                                                 v-model="vm.longDistance">
                                            <span slot="suffix">公里</span>
                                        </c-input>
                                    </el-form-item>
                                </div>
                                <div style="width: 240px;float: left;">
                                    <el-form-item label="以上计长途"
                                                  :rules="{required: true, message: '请填写金额', trigger: 'blur' }"
                                                  :prop="'returnDistanceGradeList.' + index + '.longDistancePrice'"
                                                  style="margin-bottom: 0">
                                        <c-input style="width: 140px"
                                                 type="number"
                                                 :disabled="!isEdit"
                                                 v-model="vm.longDistancePrice">
                                            <span slot="suffix">元/公里</span>
                                        </c-input>
                                    </el-form-item>
                                </div>
                                <div class="add-delete-btn">
                                    <el-button type="text"
                                               :disabled="!isEdit"
                                               style="padding-bottom: 0"
                                               @click="handelDelete(index,'returnDistanceGradeList')">
                                        <i style="font-size: 24px" class="icon el-icon-remove-outline"></i>
                                    </el-button>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="eTrip-box-card" v-if="quoteForm.quoteType === 0||quoteForm.quoteType === 2">
                        <p>
                            <span class="label-title">栅栏加价设置</span>
                            <span style="font-size: 12px;color: #FF9500;padding-left: 10px">
                                    添加栅栏，当[上车地址/下车地址]处于栅栏内，将进行加价；当[上车地址/下车地址]处于栅栏外，不进行加价
                                </span>
                        </p>
                        <div v-if="!quoteForm.quoteRuleRelProductDto.libraryOperatorAddressList||
                            quoteForm.quoteRuleRelProductDto.libraryOperatorAddressList.length==0">
                            <el-button :disabled="!isEdit" type="primary" size="small" @click="addBtn()">新增
                            </el-button>
                        </div>
                        <div class="clear" v-else
                             v-for="(item,index) in quoteForm.quoteRuleRelProductDto.libraryOperatorAddressList"
                             :key="index">
                            <div style="width: 450px;float: left;">
                                <el-row>
                                    <el-col :span="17">
                                        <el-form-item label="栅栏：" label-width="110px"
                                                      :prop="'quoteRuleRelProductDto.libraryOperatorAddressList.'+index+'.libraryOperatorAddressId'"
                                                      :rules="{ required: true, message: '请选择栅栏范围', trigger: 'change' }">
                                            <el-select v-model="item.libraryOperatorAddressId"
                                                       :disabled="!isEdit"
                                                       filterable
                                                       style="width: 200px" placeholder="请选择栅栏范围">
                                                <el-option
                                                    v-for="s in addressList"
                                                    :key="s.addressId"
                                                    :label="s.addressName"
                                                    :value="s.addressId">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <enclosure-dialog :ids="[item.libraryOperatorAddressId]"
                                                          :dataList="addressList"/>

                                    </el-col>
                                </el-row>
                            </div>
                            <div style="float: left;">
                                <el-form-item label="栅栏内加价倍率：" label-width="140px"
                                              :rules="[{ required: true, message: '请填写倍率', trigger: 'blur' },{validator:(rule, value, callback)=>callback(value<0||value==0?'倍率必须大于0':undefined),trigger: 'blur'}]"
                                              :prop="'quoteRuleRelProductDto.libraryOperatorAddressList.'+index+'.operatorAddressRatio'">
                                    <el-input style="width: 160px" type="number"
                                              placeholder="加价20%, 则设置为1.2"
                                              :disabled="!isEdit"
                                              v-model="item.operatorAddressRatio">
                                    </el-input>
                                </el-form-item>
                            </div>
                            <div style="float: left;margin-left: 20px;">
                                <el-button size="small" style="border: 1px solid #ff3b30;color: #ff3b30"
                                           :disabled="!isEdit"
                                           @click="deleteBtn(index)">删除
                                </el-button>
                            </div>
                            <div style="float: left;margin-left: 10px"
                                 v-if="index==quoteForm.quoteRuleRelProductDto.libraryOperatorAddressList.length-1">
                                <el-button type="primary" size="small" :disabled="!isEdit"
                                           @click="addBtn()">新增
                                </el-button>
                            </div>
                        </div>
                    </div>
                </el-form>
            </el-row>
            <el-dialog
                title="编辑夜间时段"
                :visible.sync="dialogVisible"
                custom-class="add-night-dialog"
            >
                <div style="min-width: 300px">
                    <div class="clear">
                        <time-picker-with-title style="width: 100px;float: left"
                                                is-title
                                                height-box="32"
                                                :default-hour="initNightTime.start[0]"
                                                :default-minute="initNightTime.start[1]"
                                                placeholder="选择用车时间"
                                                ref="timePickerStart"
                                                :time-minute-step="['00', '30']"
                        ></time-picker-with-title>
                        <div class="next-day_title" style="">次日</div>
                        <time-picker-with-title style="width: 100px;float: left"
                                                is-title
                                                height-box="32"
                                                :default-hour="initNightTime.end[0]"
                                                :default-minute="initNightTime.end[1]"
                                                placeholder="选择用车时间"
                                                ref="timePickerEnd"
                                                :time-minute-step="['00', '30']"
                        ></time-picker-with-title>
                    </div>
                    <div slot="footer" style="text-align: right">
                        <el-button @click="closeNight"
                                   style="border: 1px solid #319BF7;color:#319BF7;margin-left: 10px">取 消
                        </el-button>
                        <el-button type="primary" @click="saveNight">保存夜间时段</el-button>
                    </div>
                </div>
            </el-dialog>
            <!--预定须知-->
            <el-dialog
                :title="title"
                :visible.sync="noticeVisible"
                width="50%">
                <div v-html="noticeContent" style="line-height: 1.8em"></div>
            </el-dialog>
        </div>
    </div>

</template>

<script type="text/ecmascript-6">
import {getCityData} from '@/urls/index';
import timePickerWithTitle from '@/components/timePickerWithTitle/index.vue';
import {isCellphone, isRealName} from "@/common/js/Utils"
import SeatModel from "@/components/seatModel/index";
import {channelList} from '@/data/index'
import CInput from '@/components/c-input/index'
import OrderConnectSelect from '../orderConnectPanel/index.vue'
import OrderReceiverSelect from '../orderReceiverPanel/index.vue'
import ServiceTimeSelect from '../serviceTimeSelect/index.vue'
import SupplierSelect from '../../../../components/supplier-select/index.vue'
import OperatorSelect from '../../../../components/operator-select/index.vue'
import QuotationType from '../quotationType/index'
import BaseInfo from './base-info.vue'
import {quotationProductionTypes, crossCitys} from '@/data/index'
import {carCharterTypeList} from '@/data/quotationTool'
import SpecialTimeQuotation from './special-time-quotation.vue'
import EnclosureDialog from './enclosure-dialog'

const initIntervalTimePeriodRecommend = {
    periodBegin: '00:00',
    periodTime: {start: ['00', '00'], end: ['01', '00']},
    periodEnd: '01:00',
    price: ''
}

export default {
    //定义模版数据
    name: 'quoteForm',
    data() {
        return {
            crossCitys,
            validatorName: (rule, value, callback) => {
                if (!value)
                    callback("请填写姓名")
                else if (!isRealName(value))
                    callback("姓名格式错误")
                else
                    callback();
            },
            validatorPhone: (rule, value, callback) => {
                if (!value)
                    callback("请填写手机号")
                else if (!isCellphone(value))
                    callback("手机号格式错误")
                else
                    callback();
            },
            longDistanceValidator: (rule, value, callback) => {
                const ret = this.quoteForm.longDistanceGradeList.filter((item) => item.longDistance === value)
                if (ret.length > 1) {
                    callback('不能输入两个相同的公里数')
                } else
                    callback()
            },
            intervalTimePeriodRecommendPriceValidator(rule, value, callback) {
                console.log(value);
                if (Number(value) === 0)
                    return callback('不能为0')
                if (!value)
                    return callback('请填写')
                return callback();
            },
            productFormList: quotationProductionTypes,
            carCharterTypeList,
            serverCityList: [],
            quoteForm: {
                basicDistance: null,
                basicTime: null,
                charterNightPrice: null,
                charterNightPeriod: {
                    nightPeriodBegin: '19:00',
                    nightPeriodEnd: '06:00'
                },
                longDistanceGradeList: [
                    {
                        longDistance: null,
                        longDistancePrice: null
                    }
                ],
                returnDistanceGradeList: [
                    {
                        longDistance: null,
                        longDistancePrice: null
                    }
                ],
                quoteRuleRelProductDto: {
                    isAcross: 0,
                    supplierId: '',
                    quoteChannel: null,
                    thirdProductId: '',
                    type: '7',
                    cityId: '3101',
                    destinationCityId: '3101',
                    libraryOperatorAddressId: '',
                    stationCode: '0',
                    stationTerminal: '',
                    vehicleType: 1,
                    orderContactMobile: '',
                    orderContactName: '',
                    libraryOperatorAddressList: [],
                    customServiceId: '',
                    charteredCarDuration: '999',
                },
                quoteType: 0,
                peakPeriod: [],
                nightPeriod: {
                    nightPeriodBegin: '22:00',
                    nightPeriodEnd: '05:00'
                },
                intervalTimePeriodRecommendDtos: [
                    {...initIntervalTimePeriodRecommend}
                ]
            },
            rulesForm: {
                "ruleName": [
                    {required: true, message: '请填写规则名称', trigger: 'blur'},
                ],
                "basicPrice": [
                    {required: true, message: '请填写金额', trigger: 'blur'}
                ],
                "exceedDistancePrice": [
                    {required: true, message: '请填写金额', trigger: 'blur'}
                ],
                "exceedTimePrice": [
                    {required: true, message: '请填写金额', trigger: 'blur'}
                ],
                "basicDistance": [
                    {required: true, message: '请填写公里数', trigger: 'blur'}
                ],
                "basicTime": [
                    {required: true, message: '请填写时长', trigger: 'blur'}
                ],
                "flatTimePrice": [
                    {required: true, message: '请填写金额', trigger: 'blur'},
                ],
                "peakTimePrice": [
                    {required: true, message: '请填写金额', trigger: 'blur'},
                ],
                "nightFee": [
                    {required: true, message: '请填写金额', trigger: 'blur'},
                ],
                "allowBackDistance": [
                    {required: true, message: '请填写公里数', trigger: 'blur'},
                ],
                "waitingFee": [
                    {required: true, message: '请填写金额', trigger: 'blur'},
                ],
                "longDistance": [
                    {required: true, message: '请填写公里数', trigger: 'blur'},
                ],
                "exceedBackPrice": [
                    // {required: true, message: '请填写金额', trigger: 'blur'},
                    {
                        validator: function (rule, value, callback) {
                            if (!value && value !== 0) {
                                callback(new Error('请填写金额'));
                            } else {
                                callback();
                            }
                        }, trigger: 'blur'
                    }
                ],
                "longDistancePrice": [
                    {
                        validator: function (rule, value, callback) {
                            if (!value && value !== 0) {
                                callback(new Error('请填写金额'));
                            } else {
                                callback();
                            }
                        }, trigger: 'blur'
                    }
                ],
                "price": [
                    {required: true, message: '请填写金额', trigger: 'blur'},
                ],
                "timeInterval1": [
                    {
                        validator: (rule, value, callback) => {
                            if (value === '') {
                                callback(new Error('请选择时段'));
                            } else {
                                let form = JSON.parse(JSON.stringify(this.quoteForm));
                                if (form.intervalTimePeriodRecommendDtos && form.intervalTimePeriodRecommendDtos.length > 0) {
                                    let hourStart = []
                                    let hourEnd = []
                                    form.intervalTimePeriodRecommendDtos.forEach(item => {
                                        if (item.periodEnd <= item.periodBegin) callback(new Error(`结束时间必须大于开始时间`));
                                        hourStart.push(item.periodBegin)
                                        hourEnd.push(item.periodEnd)
                                    })
                                    hourStart = hourStart.sort()
                                    hourEnd = hourEnd.sort()
                                    for (let i = 1; i < hourStart.length; i++) {
                                        if (JSON.stringify(hourEnd[i - 1]) > JSON.stringify(hourStart[i])) callback(new Error(`时段重复`));
                                    }
                                }
                                callback();
                            }
                        },
                        trigger: 'blur, change'
                    }
                ],
                "nightFixedPrice": [
                    {required: true, message: '请填写金额', trigger: 'blur'},
                ],
                "distributionPriceRatio": [
                    {required: true, message: '请填写百分比', trigger: 'blur'},
                ],
                "settlePriceRatio": [
                    {required: true, message: '请填写百分比', trigger: 'blur'},
                ],
                "driverPriceRatio": [
                    {required: true, message: '请填写百分比', trigger: 'blur'},
                ]
            },
            editPeakPeriod: false,
            dialogVisible: false,
            nightTime: {nightPeriodBegin: '22:00', nightPeriodEnd: '05:00'},
            initNightTime: {start: ['22', '00'], end: ['05', '00']},
            stationList: [],
            stationNameList: [],
            channel: channelList,//渠道
            operatorRefundRule: [],
            operatorReservationNotes: [],
            noticeContent: "",
            noticeVisible: false,
            title: "预订须知：",
            serviceTimes: [],
            departureAddressByMerchantList: [],
            destinationAddressByMerchantList: [],
            departureAddressList: [],
            destinationAddressList: [],
            // daySignsTime: {daytimePeriodBegin: '06:00', daytimePeriodEnd: '19:00'},
            initPriceTimeInterval: {start: ['00', '00'], end: ['01', '00']},
            initDaySignsTime: {start: ['06', '00'], end: ['19', '00']},
            nightFixedPriceTime: {nightPeriodBegin: '19:00', nightPeriodEnd: '06:00'},
            initNightFixedPriceTime: {start: ['19', '00'], end: ['06', '00']},
            detail: {},
            //出发地理围栏城市
            departureFenceCityList: [],
            //到大地围栏城市
            destinationFenceCityList: []
        }
    },
    props: {
        isEdit: {
            type: Boolean,
            value:
                false
        }
    },
    components: {
        timePickerWithTitle,
        SpecialTimeQuotation,
        SeatModel,
        CInput,
        OrderConnectSelect,
        OrderReceiverSelect,
        ServiceTimeSelect,
        SupplierSelect,
        OperatorSelect,
        QuotationType,
        BaseInfo,
        EnclosureDialog
    },
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo
        },
        isDisabled() {
            if (!this.isEdit)
                return true
            let isDisabled = true;
            let userInfo = this.$store.state.loginUserInfo;
            if (this.$route.params.id)
                isDisabled = userInfo.refType !== '0'
            return isDisabled;
        },
        daytimePeriodHourStep: function () {
            let daytime = [];
            for (let i = 0; i < 25; i++) {
                let j = i;
                if (i < 10)
                    j = '0' + i;
                daytime.push(j.toString())
            }
            return daytime;
        },
        nightPeriodHourStep: function () {
            let night = [];
            for (let i = 0; i < 7; i++) {
                let j = i;
                if (i < 10)
                    j = '0' + i;
                night.push(j.toString())
            }
            for (let i = 19; i < 24; i++) {
                night.push(i.toString())
            }
            return night;
        },
        addressList() {
            return [...this.departureAddressByMerchantList, ...this.destinationAddressByMerchantList]
        }
    },
    //主件被加载完成
    mounted() {
        this.getServerCity();
        this.getChannelData()
        if (this.$route.params.id && this.$route.params.id !== this._id) {
            this._id = this.$route.params.id;
            this.loadingShow();
            this.getQuoteInfo();
        }
    },
    //定义事件方法
    methods: {
        getChannelData() {
            if (!this.quoteForm.quoteRuleRelProductDto.quoteChannel)
                this.quoteForm.quoteRuleRelProductDto.quoteChannel = this.channel[0].value
        },
        async getServerCity() {
            const ret = await
                getCityData(this, '2');
            if (ret)
                this.serverCityList = ret;
        },
        //提交审核 做校验
        async submit(status) {
            try {
                let valid = null;
                if (this.$refs['special-time-quotation'])
                    valid = await await Promise.all([this.$refs.quoteForm.validate(), this.$refs['special-time-quotation'].validate()])
                else
                    valid = await Promise.all([this.$refs.quoteForm.validate()])
                if (valid) {
                    try {
                        await this.$confirm('我已确认填写内容并提交审核?', '确认提交', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        })
                        this.save(status)
                    } catch (e) {
                        console.log(e);
                    }
                }
            } catch (e) {
                console.log(e);
                this.messageError('还有信息未填写')
            }
        },
        //保存 直接保存 不做校验
        async save(status) {
            let url = '/galaxyQuote/addQuote';
            let form = JSON.parse(JSON.stringify(this.quoteForm));
            form.longDistanceGradeList = JSON.stringify(form.longDistanceGradeList)
            form.returnDistanceGradeList = JSON.stringify(form.returnDistanceGradeList)
            form.quoteRuleRelProductDto.status = status;
            if (!form.quoteRuleRelProductDto.supplierId) delete form.quoteRuleRelProductDto.supplierId;
            if (form.intervalTimePeriodRecommendDtos && form.intervalTimePeriodRecommendDtos.length > 0) {
                form.intervalTimePeriodRecommendDtos.forEach(item => {
                    delete item.periodTime
                })
            }
            if (this.$route.params.id) {
                form.id = this.$route.params.id;
                url = '/galaxyQuote/updateQuote';
            }
            //包车
            if (form.quoteType === 2)
                form.quoteRuleRelProductDto.type = 2;
            //南航
            if (form.quoteRuleRelProductDto.quoteChannel === 9) {
                form.quoteRuleRelProductDto.nanhangRatio = 1;
            }
            //航旅
            if (form.quoteRuleRelProductDto.quoteChannel === 10) {
                form.quoteRuleRelProductDto.hanglvRatio = 1;
            }
            //特殊时段报价
            if (this.$refs['special-time-quotation']) {
                const formData = this.$refs['special-time-quotation'].getFormData();
                form.quoteRuleSpecialTimePriceDtos = formData.list.map(({
                                                                            distancePrice,
                                                                            timePrice,
                                                                            times,
                                                                            addPriceratio,
                                                                            addPricer
                                                                        }) => ({
                    distancePrice,
                    timePrice,
                    addPriceratio,
                    addPricer,
                    startTime: times && times.length > 0 ? times[0].startTime : null,
                    endTime: times && times.length > 0 ? times[times.length - 1].endTime : null
                }));
            }
            try {
                this.loadingShow()
                let res = await this.http(url, form, 'POST');
                this.loadingHide();
                if (res.success) {
                    this.$message.success(status === 3 ? '提交审核成功！' : '保存成功！');
                    this.$store.commit('delete_tabs', this.$route.path);
                    this.$router.push({name: 'quoteList', query: {refresh: +new Date()}});
                }

            } catch (error) {
                this.messageError()
            }
        },
        async getQuoteInfo() {
            let id = this.$route.params.id;
            let res = await this.http(`/galaxyQuote/getQuoteRuleDetail/${id}`);
            this.loadingHide();
            if (res.success) {
                if (res.data.longDistanceGradeList)
                    res.data.longDistanceGradeList = JSON.parse(res.data.longDistanceGradeList);
                else {
                    const {
                        longDistance,
                        longDistancePrice
                    } = res.data;
                    res.data.longDistanceGradeList = [{
                        longDistance,
                        longDistancePrice
                    }]
                }
                //格式化特殊时段
                const {quoteRuleSpecialTimePriceDtos} = res.data
                if (quoteRuleSpecialTimePriceDtos && quoteRuleSpecialTimePriceDtos.length > 0) {
                    const gTime = (time, index) => {
                        let times = time.split(':');
                        times[1] = parseInt(times[1]) + 30 * index;
                        times[0] = parseInt(times[0]) + Math.floor(parseInt(times[1]) / 60);
                        times[1] = parseInt(times[1]) % 60;
                        return (times[0] + '').padStart(2, '0') + ':' + (times[1] + '').padStart(2, '0')
                    }
                    res.data.quoteRuleSpecialTimePriceDtos = quoteRuleSpecialTimePriceDtos.map((item) => {
                        let times = [];
                        if (item.startTime && item.endTime) {
                            let i = 0;
                            do {
                                times.push({
                                    startTime: gTime(item.startTime, i),
                                    endTime: gTime(item.startTime, i + 1)
                                })
                                i++;
                            } while (times[times.length - 1].endTime.replace(':', '') < item.endTime.replace(':', ''))
                        }
                        return {
                            times,
                            ...item
                        }
                    })
                }
                if (res.data.returnDistanceGradeList)
                    res.data.returnDistanceGradeList = JSON.parse(res.data.returnDistanceGradeList)
                else res.data.returnDistanceGradeList = []
                if (res.data.intervalTimePeriodRecommendDtos && res.data.intervalTimePeriodRecommendDtos.length > 0) {
                    let intervalTimePeriod = JSON.parse(JSON.stringify(res.data.intervalTimePeriodRecommendDtos))
                    intervalTimePeriod.map(item => {
                        item.periodTime = {
                            start: item.periodBegin.split(':'),
                            end: item.periodEnd.split(':')
                        }
                    })
                    res.data.intervalTimePeriodRecommendDtos = intervalTimePeriod
                } else res.data.intervalTimePeriodRecommendDtos = [{...initIntervalTimePeriodRecommend}]


                res.data.charterNightPeriod = {
                    nightPeriodBegin: res.data.charterNightPeriod.nightPeriodBegin || '19:00',
                    nightPeriodEnd: res.data.charterNightPeriod.nightPeriodEnd || '06:00'
                }
                this.quoteForm = res.data;
                this.detail = JSON.parse(JSON.stringify(res.data));
                this.$store.commit('change_quote_detail', res.data)
                if (res.data.nightPeriod) {
                    if (res.data.nightPeriod.nightPeriodBegin)
                        this.initNightTime.start = res.data.nightPeriod.nightPeriodBegin.split(':');
                    if (res.data.nightPeriod.nightPeriodEnd)
                        this.initNightTime.end = res.data.nightPeriod.nightPeriodEnd.split(':');
                    this.nightTime = JSON.parse(JSON.stringify(this.quoteForm.nightPeriod));
                }
                const operatorId = res.data.quoteRuleRelProductDto.operatorId;
                this.quoteForm.quoteRuleRelProductDto.quoteChannel = parseInt(this.quoteForm.quoteRuleRelProductDto.quoteChannel);
                this.quoteForm.quoteRuleRelProductDto.vehicleType = parseInt(this.quoteForm.quoteRuleRelProductDto.vehicleType) || 1;
                this.quoteForm.quoteRuleRelProductDto.type = parseInt(this.quoteForm.quoteRuleRelProductDto.type) || 0;
                if (this.quoteForm.quoteRuleRelProductDto.charteredCarDuration) {
                    this.quoteForm.quoteRuleRelProductDto.charteredCarDuration = String(this.quoteForm.quoteRuleRelProductDto.charteredCarDuration);
                    if (this.quoteForm.quoteType === 2) this.selectCharterType(this.quoteForm.quoteRuleRelProductDto.charteredCarDuration)
                }
                this.getStation();
                this.getOperatorReservationNotes(operatorId);
                this.getOperatorRefundRule(operatorId);
                const quoteProductDto = res.data.quoteRuleRelProductDto;
                this.departureAddressList = [];
                this.destinationAddressList = [];
                const destinationFenceCityList = []
                const departureFenceCityList = []

                if (quoteProductDto) {
                    quoteProductDto.departureOperatorAddressList.forEach(item => {
                        this.departureAddressList.push(item.libraryOperatorAddressId)
                        departureFenceCityList.push(item.cityId)
                    })
                    quoteProductDto.destinationOperatorAddressList.forEach(item => {
                        this.destinationAddressList.push(item.libraryOperatorAddressId)
                        destinationFenceCityList.push(item.cityId)
                    })
                    this.departureFenceCityList = Array.from(new Set(departureFenceCityList))
                    this.destinationFenceCityList = Array.from(new Set(destinationFenceCityList))
                }
                let {cityId, destinationCityId} = this.quoteForm.quoteRuleRelProductDto;
                if (!departureFenceCityList.length && cityId)
                    this.departureFenceCityList = [cityId]
                if (!destinationFenceCityList.length && destinationCityId)
                    this.destinationFenceCityList = [destinationCityId]
                this.getAddressFence(0);
                this.getAddressFence(1);
            } else {
                this.messageError(res.errors[0].message);
            }
        },
        closeEdit() {
            this.editPeakPeriod = false;
        },
        saveEdit(val) {
            this.quoteForm.peakPeriod = val;
            this.editPeakPeriod = false;
        },
        //编辑夜间时段 begin
        closeNight() {
            this.dialogVisible = false;
        },
        saveNight() {
            this.quoteForm.nightPeriod.nightPeriodBegin = this.$refs['timePickerStart'].getTime()
            this.quoteForm.nightPeriod.nightPeriodEnd = this.$refs['timePickerEnd'].getTime()
            this.nightTime = JSON.parse(JSON.stringify(this.quoteForm.nightPeriod));
            this.dialogVisible = false;
        },
        handleTimeInterval1(val, indexIdent) {
            if (indexIdent) this.quoteForm.intervalTimePeriodRecommendDtos[indexIdent.index].periodBegin = val;
        },
        handleTimeInterval2(val, indexIdent) {
            if (indexIdent) this.quoteForm.intervalTimePeriodRecommendDtos[indexIdent.index].periodEnd = val;
        },
        selectForm() {
            this.quoteForm.quoteRuleRelProductDto.stationCode = '';
            this.quoteForm.quoteRuleRelProductDto.stationName = '';
            this.getStation()
            this.getAddressFence(0);
            this.getAddressFence(1);
        },
        selectCharterType(value) {
            const res = this.carCharterTypeList.filter((item) => item.value === value)
            this.quoteForm.quoteRuleRelProductDto.charteredCarDuration = value;
            this.quoteForm.basicDistance = res[0].distance;
            this.quoteForm.basicTime = res[0].duration;

        },
        selectCity(type) {
            this.quoteForm.quoteRuleRelProductDto.customServiceId = "";
            this.getStation();
            let {cityId, destinationCityId} = this.quoteForm.quoteRuleRelProductDto;
            if (type === 0) {
                this.departureFenceCityList = [cityId]
                this.departureAddressList = []
            } else {
                this.destinationFenceCityList = [destinationCityId]
                this.destinationAddressList = []
            }
            this.getAddressFence(type);
        },
        async getStation() {
            let {type, cityId, destinationCityId} = this.quoteForm.quoteRuleRelProductDto;
            if (type === 12)
                return;
            let shortCode = cityId;
            if (type === 8 || type === 10)
                shortCode = destinationCityId
            let stationType = 1;
            if ([7, 8].includes(type))
                stationType = 2
            if ([27, 28].includes(type))
                stationType = 3
            let form = {
                shortCode,
                stationType
            }
            let res = await this.http('/galaxyStationApi/doStationListRemoveDup', form, 'POST');
            if (res.success) {
                this.stationList = res.data;
                this.stationNameList = (res.data.find(({airportCode}) => airportCode ===
                    this.quoteForm.quoteRuleRelProductDto.stationCode) || {}).stationNameList
            }
        },
        //根据城市ids获取城市下的地理围栏
        async getAddressFence(type) {
            let cityIds = this.departureFenceCityList;
            if (type === 1)
                cityIds = this.destinationFenceCityList;
            let list = []
            if (cityIds.length) {
                let res = await this.http('/galaxyStationApi/getOperatorAddressByCityIds', cityIds, 'POST');
                if (res && res.success) {
                    list = res.data
                }
            }
            if (type === 0) {
                this.departureAddressByMerchantList = list
            } else if (type === 1) {
                this.destinationAddressByMerchantList = list
            }
        },
        // 获取出发地目的地栅栏
        quoteTypeChange(value) {
            this.$refs.quoteForm.clearValidate();
            if (value === 2) {
                const charteredCarDuration = this.quoteForm.quoteRuleRelProductDto.charteredCarDuration;
                const res = this.carCharterTypeList.filter((item) => item.value === charteredCarDuration);
                if (res.length > 0) {
                    this.quoteForm.basicDistance = res[0].distance;
                    this.quoteForm.basicTime = res[0].duration;
                }
            }
            this.quoteForm.quoteRuleRelProductDto.type = parseInt(this.detail.quoteRuleRelProductDto.type);
            if (value === 2 && this.detail.quoteType !== 2)
                this.quoteForm.quoteRuleRelProductDto.type = 2;
            if (value !== 2 && this.detail.quoteType === 2)
                this.quoteForm.quoteRuleRelProductDto.type = 7;
        },
        selectStation(val) {
            let station = this.stationList.find(({airportCode}) => val == airportCode);
            let stationName = '';
            let stationNameList = []
            if (station) {
                stationName = station.station;
                stationNameList = station.stationNameList || [];
            }
            this.quoteForm.quoteRuleRelProductDto.stationName = stationName;
            this.stationNameList = stationNameList
            this.quoteForm.quoteRuleRelProductDto.stationTerminal = '';
        },
        addBtn() {
            let data = {
                libraryOperatorAddressId: '',
                operatorAddressRatio: '',
                operatorAddressType: 0
            }
            this.quoteForm.quoteRuleRelProductDto.libraryOperatorAddressList.push(data);
        },
        deleteBtn(index) {
            this.quoteForm.quoteRuleRelProductDto.libraryOperatorAddressList.splice(index, 1)
        },
        departureOperator(type, data) {
            let list = [];
            data.forEach(item => {
                let data = {
                    libraryOperatorAddressId: item,
                    operatorAddressType: type
                }
                list.push(data)
            })
            switch (type) {
                case 1:
                    this.quoteForm.quoteRuleRelProductDto.departureOperatorAddressList = list;
                    break;
                case 2:
                    this.quoteForm.quoteRuleRelProductDto.destinationOperatorAddressList = list;
                    break
            }
        },
        // 服务区域
        //运营商退款规则
        async getOperatorRefundRule(operatorId) {
            const ret = await
                this.http(`/galaxyOperatorRefundRule/getOperatorRefundRule/${operatorId}`);
            if (ret.success)
                this.operatorRefundRule = ret.data;
        },
        //运营商预定规则
        async getOperatorReservationNotes(operatorId) {
            const ret = await
                this.http(`/galaxyOperatorReservationNotes/getOperatorReservationNotes/${operatorId}`);
            if (ret.success)
                this.operatorReservationNotes = ret.data;
        },
        handleNotice(id, type) {
            this.title = type == 1 ? '预订须知：' : '退改规则：';
            let content = "";
            if (type == 1) {
                content = this.operatorReservationNotes.find(item => {
                    return id == item.id
                }).reservationNotes;
            }
            if (type == 2) {
                content = this.operatorRefundRule.find(item => {
                    return id == item.id
                }).refundRule
            }
            this.noticeContent = content.replace(/\n/g, '<br/>');
            this.noticeVisible = true
        },
        //添加长途费
        handleAdd(key) {
            let data = {}
            switch (key) {
                case 'intervalTimePeriodRecommendDtos':
                    data = {...initIntervalTimePeriodRecommend};
                    break;
                default:
                    data = {
                        longDistance: "",
                        longDistancePrice: ""
                    }
                    break;
            }
            this.quoteForm[key].push(data)
        },
        //删除长途费
        handelDelete(index, key) {
            this.quoteForm[key].splice(index, 1)
        },
        //
    }
}
</script>

