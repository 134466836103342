<template>
    <div>
        <el-radio-group :value="value" v-model="type" @change="onChange" :disabled="disabled">
            <el-radio v-for="item in quotationTypeList" :key="item.value" :label="item.value">
                {{item.label}}
            </el-radio>
        </el-radio-group>
    </div>
</template>

<script type="text/ecmascript-6">
    import {quotationTypeList} from "@/data/index"

    export default {
        //定义模版数据
        data() {
            return {
                quotationTypeList,
                type: 0
            }
        },
        props: {
            value: {
                type: Number,
                value: 0
            },
            disabled: {
                type: Boolean,
                value: false
            }
        },
        mounted() {
            this.type = this.value;
        },
        methods: {
            onChange(value) {
                this.$emit('input', value);
                this.$emit('change', value)
            }
        },
        watch: {
            value(n) {
                this.type = n;
            }
        }
    }
</script>

