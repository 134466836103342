<style lang="scss" scoped>

.el-button {
    margin-right: 10px;
}
</style>
<template>

    <div>
        <order-table :columns="data.columns" :dataList="orderContactList" :show-buttons="true">
            <template v-slot:buttons="slotRow">
                <el-button type="text" @click="handleEditBtn(slotRow.row)">编辑</el-button>
                <!--<el-popover-->
                <!--v-if="userInfo.userType != 1"-->
                <!--placement="top"-->
                <!--width="120"-->
                <!--trigger="click"-->
                <!--v-model="slotRow.row.showPopup">-->
                <!--<p>确认删除</p>-->
                <!--<div style="text-align: right; margin: 0">-->
                <!--<el-button size="mini" type="text" @click="slotRow.row.showPopup = false">取消</el-button>-->
                <!--<el-button type="primary" size="mini" @click="handleDelBtn(slotRow.row)">确定</el-button>-->
                <!--</div>-->
                <!--<el-button slot="reference" type="text" style="color: #f56c6c">删除</el-button>-->
                <!--</el-popover>-->
            </template>
        </order-table>
        <order-contact-dialog
            @update:cancelBtn="handleCancelBtn"
            ref="orderContactDialogRef"
            :addOrEdit="addOrEdit"></order-contact-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
import data from "./data"
import orderTable from "../../../../components/table/index"
import orderContactDialog from "../orderContactDialog/index"

export default {
    //定义模版数据
    data() {
        return {
            data,
            listLoading: false,
            addOrEdit: 0,
            form: {},
            pageInfo: {
                pageIndex: 1,
                pageSize: 10,
                total: 1
            },
            orderContactList: [],
        }
    },
    components: {
        orderTable,
        orderContactDialog
    },
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo
        }
    },
    //主件被加载完成
    mounted: function () {
        this.handleInitData(1)
    },
    //定义事件方法
    methods: {
        async handleInitData(index) {
            this.pageInfo.pageIndex = index;
            let {
                    operatorId,
                    supplierId,
                    nameFirst,
                    mobileFirst
                } = this.form,
                form = {
                    operatorId,
                    supplierId,
                    nameFirst,
                    mobileFirst,
                    pageIndex: index,
                    pageSize: this.pageInfo.pageSize,
                };
            let res = await this.$store.dispatch('orderContactList', form)
            if (res) {
                let list = res.data;
                list.forEach(item => {
                    if (item.nameFirst && item.mobileFirst)
                        item.orderContactFirst = item.nameFirst + '，' + item.mobileFirst;
                    if (item.nameSecond && item.mobileSecond)
                        item.orderContactSecond = item.nameSecond + '，' + item.mobileSecond
                    if (item.nameThird && item.mobileThird)
                        item.orderContactThird = item.nameThird + '，' + item.mobileThird
                });
                this.orderContactList = list;
                this.pageInfo.total = res.total;
                this.$emit('update:list', {pageInfo: this.pageInfo, data: this.orderContactList})
            }
        },
        handleCancelBtn() {
            this.$store.dispatch("orderContactShow", false);
        },
        handleAddOrEditBtn(type) {
            this.$refs.orderContactDialogRef.getUserInfo(this.userInfo.userType);
            this.addOrEdit = type;  // 0 新增  1 编辑

        },
        handleEditBtn(item) {
            if (item) {
                this.$store.dispatch("orderContactShow", true);
                this.$refs.orderContactDialogRef.initData(item);
                this.handleAddOrEditBtn(1);
            }
        },
        async handleDelBtn(item) {
            item.showPopup = false;
            let form = {
                id: item.id,
                isDelete: 1
            }
            let res = await this.$store.dispatch('updateOrderContact', form)
            if (res.success && res.data) {
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.handleInitData(1)
            }
        },
        handleSearch(form) {
            this.form = form;
            this.handleInitData(1)
        },
        handleReset() {
            this.form = {};
            this.handleInitData(1)

        }
        // handleSubmitBtn(form, type) {
        //     this.$emit("update:handleSubmitBtn", form, type)
        // },

    },
    //监听模版变量
    watch: {}

}
</script>

