<style lang="scss" scoped>
    .rt-box {
        width: calc(100% - 248px);
        padding: 0 17px;
    }

    .bt-form {
        margin: 20px 0;
        background-color: #f8f8f8;
        padding: 15px 15px 0 15px;
        h2 {
            font-size: 14px;
            margin-bottom: 10px;
            color: #333333;
        }
        input {
            font-size: 18px;
            color: #333333;
        }
        .form-btn {
            width: 250px;
            text-align: right;
            button {
                width: 82.5px;
            }
        }
    }
</style>
<template>
    <section-content>
        <div style="margin-left: 30px">
            <div class="rt-box">
                <quote-date @updateMonth="updateMonth"
                            @dayClick="handleDateClick"
                            :inventory="inventory"
                            ref="calendar"></quote-date>
                <div class="bt-form">
                    <h2>设置选中的日期：</h2>
                    <el-form ref="form"
                             :inline="true"
                             :model="form"
                             :rules="rules"
                             label-width="90px"
                             label-position="left"
                             size="small">
                        <el-form-item label="加价倍率：" prop="quotePriceRatio">
                            <el-input v-model="form.quotePriceRatio" clearable type="number" min="0"
                                      style="width: 120px">
                                <span slot="suffix">倍</span>
                            </el-input>
                        </el-form-item>

                        <el-form-item class="form-btn">
                            <el-button @click="handleCancel">取消</el-button>
                            <el-button type="primary" @click="handleSubmit">确定</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </section-content>

</template>

<script type="text/ecmascript-6">
    import quoteDate from './quoteDate'
    import SectionContent from '@/components/sectionContent/'

    export default {
        //定义模版数据
        data() {
            return {
                form: {},
                inventory: [],
                rules: {
                    quotePriceRatio: [
                        {
                            validator: (rule, value, callback) => {
                                if (!value)
                                    callback("请输入加价倍率")
                                else
                                    callback();
                            }, trigger: 'blur'
                        },
                    ],
                }
            }
        },
        components: {
            quoteDate,
            SectionContent
        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
            let month = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);
            this.getDateList((new Date().getFullYear() + '' + month));
        },
        //定义事件方法
        methods: {
            async getDateList(date) {
                let form = {
                    ruleId: this.$route.params.id,
                    quoteMonth: date
                }
                let res = await this.http('/galaxyQuote/specialDatePriceList', form, 'POST');
                if (res.success) {
                    this.inventory = res.data;
                }
            },
            updateMonth(date) {
                console.log(date);
                this.getDateList(date);
            },
            handleCancel() {
                this.form = {};
                this.$refs["form"].resetFields();
            },
            handleSubmit() {
                this.$refs["calendar"].validateForm();
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        const dateObj = this.$refs["calendar"].getCalendar();
                        console.log(dateObj);
                        if (dateObj.data.length === 0)
                            return;
                        let datas = [];
                        // this.form.ruleId = this.$route.params.id;
                        if (dateObj.type == 0) {
                            let calendar = dateObj.data;
                            const month = calendar.year + (calendar.month < 10 ? ('0' + calendar.month) : '' + calendar.month);
                            // this.form.quoteMonth = month;
                            calendar.days.forEach((row) => {
                                row.forEach((col) => {
                                    if (col.selected) {
                                        let form = JSON.parse(JSON.stringify(this.form));
                                        form.quoteDay = month + (col.day < 10 ? ('0' + col.day) : '' + col.day)
                                        if (col.inventory)
                                            form.id = col.inventory.id
                                        datas.push(form)
                                    }
                                })
                            })
                        } else {
                            dateObj.data.forEach((data) => {
                                let form = Object.assign({}, this.form);
                                const month = data.month < 10 ? '0' + data.month : data.month;
                                const day = data.day < 10 ? '0' + data.day : data.day;
                                form.quoteMonth = `${data.year}${month}`
                                form.quoteDay = `${data.year}${month}${day}`
                                datas.push(form);
                            })
                        }
                        this.loading = false;
                        if (datas.length === 0) {
                            this.$message.error('请先选择日期');
                            return;
                        }
                        console.log(datas);
                        if (valid) {
                            this.$confirm('保存后数据将即使生效', '保存设置', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(async () => {
                                this.loading = true;
                                let month = dateObj.data.year + (dateObj.data.month < 10 ? ('0' + dateObj.data.month) : '' + dateObj.data.month);
                                let form = {
                                    ruleId: this.$route.params.id,
                                    quoteMonth: month,
                                    data: datas
                                }
                                const ret = await this.http('/galaxyQuote/updateBatchQuoteSedDate', form, 'POST', false);
                                if (ret.success) {
                                    this.$message.success('设置已生效!')
                                    if (dateObj.type == 0) {
                                        this.getDateList(month);
                                    }
                                }
                                else
                                    this.$message.error('出错了，请稍后重试!')
                                this.loading = false;

                            }).catch(() => {
                            });
                        }
                    }
                })
            },
            handleDateClick() {

            }
        },
        //监听模版变量
        watch: {}

    }
</script>
