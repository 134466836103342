<style lang="scss" scoped>
    .edit-quote {
        .eTrip-footer-cont {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .notice {
            color: #666;
            margin-right: 20px;
            font-size: 14px;
        }
    }
</style>
<template>

    <div class="edit-quote">
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <quote-edit-form isEdit ref="quoteEditForm"></quote-edit-form>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont">
                <p class="notice">注：请所有车型的栅栏价格设置，全部编辑完后再提交</p>
                <el-button v-if="status!=='1'"
                           type="primary"
                           style="margin-right: 20px" plain @click="save()">
                    保存
                </el-button>
                <el-button type="primary" @click="submit()">
                    {{status === '1' ? '保存' : '提交审核'}}
                </el-button>
            </div>
        </el-footer>
    </div>

</template>

<script type="text/ecmascript-6">
    import QuoteEditForm from '../components/quoteEditForm/index.vue'

    export default {
        //定义模版数据
        name: 'editForm',
        components: {
            QuoteEditForm
        },
        //计算属性
        computed: {
            status() {
                if (this.$store.state.quotationToolList.quoteDetail &&
                    this.$store.state.quotationToolList.quoteDetail.quoteRuleRelProductDto)
                    return this.$store.state.quotationToolList.quoteDetail.quoteRuleRelProductDto.status
                else return ''
            }
        },
        //定义事件方法
        methods: {
            save() {
                this.$refs['quoteEditForm'].save(2)
            },
            submit() {
                this.$refs['quoteEditForm'].submit(this.status === '1' ? '1' : '3')
            }
        }
    }
</script>

