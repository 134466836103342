<style lang="scss" scoped>
    .user {
        margin-right: 20px;
        color: #333;
    }
</style>
<template>
    <div>
        <el-select placeholder="请选择客服时间"
                   @change="handleChange"
                   :value="value"
                   :disabled="disabled"
                   style="width: 370px;">
            <el-option v-for="item in list"
                       :value="item.id"
                       :key="item.id"
                       :label="`${item.customServiceWorkTime},${item.customServiceLongestResponseTime},${item.driverLongestResponseTime}`">
            </el-option>
        </el-select>
        <el-tooltip class="item" effect="light"
                    content="[客服工作时间],[客服最长处理时间],[司机响应时间]"
                    placement="bottom-end">
            <i class="icon icon-wenhao" style="color: #319BF7"></i>
        </el-tooltip>
    </div>

</template>

<script type="text/ecmascript-6">
    import {getProductQuoteCustomServiceList} from '@/www/urls/quotationUrls.js'

    export default {
        //定义模版数据
        data() {
            return {
                list: []
            }
        },
        props: {
            value: {
                type: String,
                default: ''
            },
            operatorId: {
                type: String,
                default: null
            },
            supplierId: {
                type: String,
                default: null
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        computed: {},
        //定义事件方法
        methods: {
            async getData() {
                this.list = []
                if (!this.operatorId)
                    return;
                let form = {};
                if (this.operatorId)
                    form = {operatorId: this.operatorId}
                if (this.supplierId)
                    form = {supplierId: this.supplierId}
                const ret = await getProductQuoteCustomServiceList(form);
                if (ret.success) {
                    this.list = ret.data || [];
                } else this.$message.error(ret.errors[0].message)

            },
            //用户选择值
            handleChange(val) {
                this.$emit('input', val)
            }
        },
        watch: {
            supplierId() {
                this.getData();
            },
            operatorId() {
                this.getData();
            }
        }
    }
</script>
