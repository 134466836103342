<style lang="scss" scoped>
    .input {
        width: 200px;
    }

    .form-item {
        margin-left: 20px
    }
</style>
<template>

    <div>
        <div v-for="(item,index) in value"
             :key="item.value"
             style="display: flex;margin-bottom: 10px">
            <el-checkbox :label="item.label"
                         v-model="item.checked"
                         style="width: 120px"
                         @change="handleChange">
                {{item.label}}
            </el-checkbox>
            <el-form-item label="渠道费率："
                          label-width="100px"
                          class="form-item"
                          v-if="item.checked"
                          :prop="'quoteRuleProductChannelDtoList.'+index+'.quoteChannelRatio'"
                          :rules="[{required: !!item.checked, message: '请输入渠道费率', trigger: 'blur'},
                                {type:'number',min:0,max:100,message:'费率在0-100之间'}]"
            >
                <el-input placeholder="渠道费率"
                          class="input"
                          clearable
                          :min="0"
                          :max="100"
                          :maxlength="8"
                          type="number"
                          v-model.number="item.quoteChannelRatio">
                    <span slot="suffix">%</span>
                </el-input>
            </el-form-item>
            <el-form-item label="平台费率："
                          class="form-item"
                          label-width="100px"
                          v-if="item.checked"
                          :prop="'quoteRuleProductChannelDtoList.'+index+'.platformRatio'"
                          :rules="[{required: !!item.checked, message: '请输入平台费率', trigger: 'blur'},
                           {type:'number',min:0,max:100,message:'费率在0-100之间'}]"
            >
                <el-input placeholder="平台费率"
                          class="input"
                          :min="0"
                          :max="100"
                          :maxlength="8"
                          type="number"
                          clearable
                          v-model.number="item.platformRatio">
                    <span slot="suffix">%</span>
                </el-input>
            </el-form-item>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">

    export default {
        //定义模版数据
        data() {
            return {
                selectProductList: []
            }
        },
        props: {
            value: {
                type: Array,
                default: () => []
            }
        },
        //定义事件方法
        methods: {
            handleChange() {
                this.$emit('input', this.value)
            }
        }
    }
</script>

