<style lang="scss" scoped>
    .edit-quote {
        .eTrip-footer-cont {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .notice {
            color: #666;
            margin-right: 20px;
            font-size: 14px;
        }
    }
</style>
<template>

    <div class="edit-quote">
        <el-main class="com-eTrip-section " :class="showButton?'section-has-footer':''">
            <div class="eTrip-section-cont">
                <quote-edit-form ref="quoteEditForm"></quote-edit-form>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px" v-if="showButton">
            <div class="eTrip-footer-cont">
                <el-button type="primary" @click="submit()">审核</el-button>
            </div>
        </el-footer>
    </div>

</template>

<script type="text/ecmascript-6">
    import QuoteEditForm from '../components/quoteEditForm/index.vue'

    export default {
        //定义模版数据
        name: 'editForm',
        data() {
            return {}
        },
        components: {
            QuoteEditForm
        },
        //计算属性
        computed: {
            showButton() {
                const {quoteRuleRelProductDto = {}} = this.$store.state.quotationToolList.quoteDetail;
                return quoteRuleRelProductDto.status === '3'
            }
        },
        //定义事件方法
        methods: {
            async submit() {
                try {
                    await  this.$confirm('确认审核?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    const ret = await this.$store.dispatch('update_quote_status', {
                        ruleId: this.$route.params.id,
                        status: '1'
                    })
                    if (ret) {
                        this.$store.commit('delete_tabs', this.$route.path);
                        this.$router.push('/quoteList');
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        },
        //监听模版变量
        watch: {
            quoteDetail(n) {
                console.log(n);
            }
        }

    }
</script>

