<style lang="scss" scoped>
    .title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        position: relative;
        color: #409EFF;
        padding-left: 10px;

        &:before {
            content: "";
            display: block;
            width: 2px;
            height: 16px;
            background: #409EFF;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -8px;
        }

    }
</style>
<template>

    <div style="margin-bottom: 30px">
        <div class="title">{{infoData.title}}信息</div>
        <div v-if="infoData.value === 0">
            <answer-contact :list-type="0"></answer-contact>
        </div>
        <div v-if="infoData.value === 1">
            <order-contact></order-contact>
        </div>
        <div v-if="infoData.value === 2">
            <service-time></service-time>
        </div>
    </div>


    <!--    singleBasicSetting  应单基础设置-->
    <!--    serviceTime  客服时间管理-->
    <!--    orderContact  订单联系人管理-->
    <!--    answerContact  应单联系人管理-->
</template>

<script type="text/ecmascript-6">
    import answerContact from "../../../answerContact/index"
    import orderContact from "../../../orderContact/index"
    import serviceTime from "../../../serviceTime/index"

    export default {
        //定义模版数据
        data() {
            return {}
        },
        props: {
            infoData: {
                type: Object,
                default: function () {
                    return {}
                }
            }
        },
        components: {
            answerContact,
            orderContact,
            serviceTime
        }
    }
</script>

