<template>

    <div>
        <com-list-search>
            <div slot="list-search-before">
                <el-form ref="form" :model="form" :inline="true" size="small">
                    <el-form-item style="width: 200px" v-if="userInfo.userType === '0'">
                        <operator-info-input
                            :selectOperatorId="selectOperatorId"
                            ref="operatorInfoRef"
                            clearable
                            @update:getSelectOperator="handleOperator"
                            @clear="handleClearOperator">
                        </operator-info-input>
                        <!--&lt;!&ndash;                        :selectOperatorId="selectOperatorId"&ndash;&gt;-->
                        <!--&lt;!&ndash;                        :is-edit="isOperatorUser"&ndash;&gt;-->
                    </el-form-item>
                    <el-form-item style="width: 200px" v-if="userInfo.userType === '0'||userInfo.userType === '2'">
                        <supplier-info-input
                            ref="supplierInfoRef"
                            clearable
                            :operatorInfo="form.operatorInfo"
                            @update:getSelectSupplier="handleSupplier"
                            @clear="handleClearSupplier">

                            <!--                            :selectSupplierId="selectSupplierId"-->
                            <!--                            :operatorInfo="operatorInfo"-->
                        </supplier-info-input>
                    </el-form-item>
                    <el-form-item label="">
                        <el-input v-model="form.name" placeholder="姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <el-input v-model="form.mobile" placeholder="手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <seat-model v-model="form.carType" :clearable="true"></seat-model>
                        <!--                        <el-input v-model="form.carNumberPlate" placeholder="车牌号"></el-input>-->
                    </el-form-item>
                    <el-form-item label="">
                        <el-button @click="resetBtn()">重置</el-button>
                        <el-button type="primary" @click="searchBtn()">搜索</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="list-search-after" style="text-align: right;">
                <el-button type="primary" @click="addBtn()">+新增应单人</el-button>
            </div>
        </com-list-search>
    </div>

</template>

<script type="text/ecmascript-6">
import comListSearch from '@/www/components/comListSearch/index.vue'
import operatorInfoInput from "@/www/components/operatorInfoInput/index"
import supplierInfoInput from "@/www/components/supplierInfoInput/index"
import SeatModel from '@/components/seatModel'

export default {
    //定义模版数据
    data() {
        return {
            form: {
                carType: null
            },
            selectOperatorId: '',
        }
    },
    components: {
        comListSearch,
        operatorInfoInput,
        supplierInfoInput,
        SeatModel
    },
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo
        },
    },
    //主件被加载完成
    mounted: function () {
        this.resetBtn()
    },
    //定义事件方法
    methods: {
        resetBtn() {
            this.form = {
                carType: null
            };
            if (this.userInfo.userType == 0) {
                this.$refs.operatorInfoRef.clearOperatorName();
            }
            if (this.userInfo.userType == 2) {
                this.selectOperatorId = this.userInfo.refId;
                this.form.operatorInfo = {
                    id: this.userInfo.refId
                }
            }
            this.$refs.supplierInfoRef && this.$refs.supplierInfoRef.clearSupplierName();
            this.$emit("update:resetBtn")
        },
        searchBtn() {
            let {
                    operatorInfo,
                    supplierInfo,
                    name,
                    mobile,
                    carNumberPlate,
                    carType
                } = this.form,
                form = {};
            if (operatorInfo) form.operatorId = operatorInfo.id;
            if (supplierInfo) form.supplierId = supplierInfo.id;
            if (name) form.name = name;
            if (carType) form.carType = carType;
            if (mobile) form.mobile = mobile;
            if (carNumberPlate) form.carNumberPlate = carNumberPlate;
            this.$emit("update:searchBtn", form);
        },
        handleOperator(data) {
            this.form.operatorInfo = data;
        },
        handleSupplier(data) {
            this.form.supplierInfo = data;
        },
        addBtn() {
            this.$emit("update:addBtn")

        },
        handleClearOperator() {
            this.selectOperatorId = null
            this.form.operatorInfo = undefined
        },
        handleClearSupplier() {
            this.form.supplierInfo = undefined;
        }
    },
    //监听模版变量
    watch: {}

}
</script>

