//是否闰年
import moment from "moment";

//获取每月天数
const getMonthlyTotalDays = (_year, _month) => {
    return moment(`${_year}-${_month}`, 'YYYY-M').daysInMonth()
};
//判断某天是星期几
const getFirstDay = function (_date) {
    return new Date(_date).getDay();
}

//生成某个月的日历数据
export const initCalendar = (date = +new Date()) => {
    const _nowDate = new Date(),
        _nowYear = _nowDate.getFullYear(),
        _nowMonth = _nowDate.getMonth() + 1,
        _nowDay = _nowDate.getDate();

    const _date = new Date(date),
        _year = _date.getFullYear(),
        _month = _date.getMonth() + 1;

    const totalDays = getMonthlyTotalDays(_year, _month);
    const firstDay = getFirstDay(_year + '/' + _month + '/1');
    let calendars = {
        year: _year,
        month: _month,
        days: []
    };

    const totalRow = (totalDays + firstDay) / 7 + 1

    for (let i = 1; i < totalRow; i++) {
        let _row = [];
        for (let j = 0; j < 7; j++) {
            let dayObj = {};
            const day = (i - 1) * 7 + j - firstDay + 1;
            if (day > 0 && day <= totalDays) {
                dayObj = {
                    year: _year,
                    month: _month,
                    date: _year + '/' + _month + '/' + day + ' 00:00:00',
                    day: day,
                    week: j
                }
            } else if (day <= 0) {
                //上个月
                let _preMonth = _month - 1;
                let _preYear = _year;
                if (_preMonth === 0) {
                    _preYear -= 1;
                    _preMonth = 12;
                }
                let _preTotalDays = getMonthlyTotalDays(_preYear, _preMonth);
                let _preDay = _preTotalDays + day;
                dayObj = {
                    year: _preYear,
                    month: _preMonth,
                    date: _preYear + '/' + _preMonth + '/' + _preDay + ' 00:00:00',
                    day: _preDay,
                    week: j
                }
            } else if (day > totalDays) {
                //下个月
                let _nextMonth = _month + 1;
                let _nextYear = _year;
                if (_nextMonth === 13) {
                    _nextYear += 1;
                    _nextMonth = 1;
                }
                let _nextDay = day - totalDays;
                dayObj = {
                    year: _nextYear,
                    month: _nextMonth,
                    date: _nextYear + '/' + _nextMonth + '/' + _nextDay + ' 00:00:00',
                    day: _nextDay,
                    week: j
                }
            }
            if (_nowYear === _year && _nowMonth === _month && _nowDay === day)
                dayObj.today = true;
            else if (_year < _nowYear || (_year == _nowYear && _month < _nowMonth) || (_year == _nowYear && _month == _nowMonth && day < _nowDay))
                dayObj.ever = true
            else
                dayObj.future = true

            //生成农历日历
            // dayObj.lunar=chinaCalendar.solar2lunar(dayObj.year,dayObj.month,dayObj.day);

            //节假日
            // dayObj.holiday=holidays_cn[dayObj.year]&&holidays_cn[dayObj.year][dayObj.month]&&holidays_cn[dayObj.year][dayObj.month][dayObj.day]

            _row.push(dayObj)
        }
        calendars.days.push(_row)
    }
    return calendars;
}
