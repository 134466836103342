<style lang="scss" scoped>
    .box-value_picker_date {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        border-radius: 4px;
        border: 1px solid #D8DCE6;
        &:hover {
            .box-value_icon {
                &.el-icon-circle-close {
                    display: block;
                }
            }
        }

        .box-value_before {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0;
            transition: all 0.2s ease-in-out;
            z-index: 14;
            &.box-value_before_input {
                opacity: 1;
                transition: all 0.2s ease-in-out;
            }

        }

        .box-value_after {
            padding: 2px 12px 0 12px;
            &.box-value_after_empty {
                padding: 0 12px;
                span {
                    transition: all 0.2s ease-in-out;
                    font-size: 14px;
                    line-height: 40px;
                }
            }
            span {
                transition: all 0.2s ease-in-out;
                font-size: 11px;
                color: #C0C4CC;
                line-height: 14px;
                display: block;
            }
            p {
                font-size: 14px;
                color: #333333;
                line-height: 19px;
            }
        }

        .box-value_icon {
            position: absolute;
            right: 8px;
            top: 2px;
            bottom: 2px;
            color: #c0c4cc;
            &.el-icon-caret-bottom, &.el-icon-arrow-down {
                z-index: 12;
                &.value_icon_caret_focus {
                    -webkit-transition: -webkit-transform .3s;
                    transition: -webkit-transform .3s;
                    transition: transform .3s, -webkit-transform .3s;
                    -webkit-transform: rotateZ(-180deg);
                    transform: rotateZ(-180deg);
                }
            }
            &.el-icon-circle-close {
                background: #fff;
                z-index: 18;
                display: none;
                &.box-value_icon_close {
                    display: none;
                }
            }
        }

    }

    .date-picker-take /deep/ {
        input {
            cursor: pointer;
        }
        .el-input__suffix {
            display: none;
        }

    }
</style>
<template>
    <div class="box-value_picker_date" :id="selectedDate" :style="{'height': heightBox + 'px','width':width}">
        <div class="box-value_before">
            <el-date-picker class="date-picker-take"
                            style="width: 100%"
                            v-model="selectedDate"
                            type="date"
                            clearable
                            :picker-options="pickerOptions"
                            @change="handleChange"
                            @blur="picker.isFocus = false"
                            @focus="picker.isFocus = true"
                            value-format="yyyy/MM/dd">
            </el-date-picker>
        </div>
        <div class="box-value_after" v-if="isTitle"
             :class="{'box-value_after_empty':!selectedDate}">
            <p :style="{'line-height': (heightBox - 4) + 'px'}">{{selectedDate | dateCus('yyyy-MM-dd')}}</p>
        </div>
        <div class="box-value_after" v-else
             :class="{'box-value_after_empty':!selectedDate}"
             style="margin-top: 3px">
            <span v-text="title" v-if="selectedDate"></span>
            <span v-text="placeholder" v-else></span>
            <p>{{selectedDate | dateCus('yyyy-MM-dd')}}</p>
        </div>
        <i class="box-value_icon"
           :class="{'value_icon_caret_focus':picker.isFocus,
               'el-icon-caret-bottom': !isIconLine,
               'el-icon-arrow-down': isIconLine}"
           :style="{'line-height': (heightBox - 4) + 'px'}">
        </i>
    </div>
</template>

<script type="text/ecmascript-6">
    import filterVue from 'vue';
    const dateCus = filterVue.filter('dateCus');
    export default {
        //定义模版数据
        data() {
            return {
                selectedDate: '',
                picker: {
                    isFocus: false
                }
            }
        },
        props: {
            defaultDate: {
                type: String,
                default: ""
            },
            title: {
                type: String,
                default: "title"
            },
            placeholder: {
                type: String,
                default: ""
            },
            pickerOptions: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            isTitle: {
                type: Boolean,
                default: false
            },
            isIconLine: {
                type: Boolean,
                default: false
            },
            heightBox: {
                type: String,
                default: "40"
            },
            width: {
                type: String,
                default: '100%'
            }
        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted() {
            if (this.defaultDate)
                this.selectedDate = new Date(dateCus(this.defaultDate, 'yyyy/MM/dd'));
        },
        //定义事件方法
        methods: {
            handleChange() {
                this.$emit("onUpdate", this.selectedDate);
            },
            getDatePicker() {
                return this.selectedDate
            }
        },
        //监听模版变量
        watch: {
            defaultDate(n) {
                this.selectedDate = dateCus(n, 'yyyy/MM/dd hh:mm:ss');
            }
        }

    }
</script>
