<template>
    <el-form :inline="true" ref="form" size="small" :model="form">
        <div v-for="(vm,index) in form.list" :key="index">

            <el-form-item label="时段:"
                          :prop="'list.' + index + '.times'"
                          :rules="{ required: true, message: '时段不能为空', trigger: 'submit'}">
                <el-input placeholder="时段" type="text"
                          :value="vm.times&&vm.times.length>0?(vm.times[0].startTime+' - '+vm.times[vm.times.length-1].endTime):null">
                    <v-periods title="选择时段"
                               slot="suffix"
                               v-model="vm.times"
                               :index="index"
                               :periodOptions="periodOptions">
                        <el-button slot="reference" type="primary" plain size="mini">选择时段</el-button>
                    </v-periods>
                </el-input>
            </el-form-item>
            <el-form-item label="单价："
                          :prop="'list.' + index + '.timePrice'"
                          :rules="{ required: true, message: '单价不能为空', trigger: 'submit'}">
                <c-input placeholder="单价" v-model="vm.timePrice" type="number">
                    <span slot="suffix">元/分钟</span>
                </c-input>
            </el-form-item>
            <el-form-item style="width: 13%" :prop="'list.' + index + '.distancePrice'"
                          :rules="{ required: true, message: '公里数不能为空', trigger: 'submit'}">
                <c-input placeholder="单价" v-model="vm.distancePrice" type="number">
                    <span slot="suffix">元/公里</span>
                </c-input>
            </el-form-item>
            <el-form-item style="width: 13%" :prop="'list.' + index + '.addPriceratio'">
                <el-input placeholder="倍率" v-model="vm.addPriceratio" clearable type="number">
                    <span slot="suffix">倍</span>
                </el-input>
            </el-form-item>
            <el-form-item style="width: 13%" :prop="'list.' + index + '.addPricer'">
                <c-input placeholder="加价金额" v-model="vm.addPricer" clearable type="number">
                    <span slot="suffix">元</span>
                </c-input>
            </el-form-item>
            <el-form-item>
                <!--<el-button type="primary" v-if="index===0">保存</el-button>-->
                <el-button type="primary" plain v-if="index!==0" @click="handleDel(index)">删除</el-button>
                <el-button type="success" @click="handleAdd" v-if="index===form.list.length-1">新增</el-button>
            </el-form-item>
        </div>
    </el-form>
</template>

<script type="text/ecmascript-6">
import CInput from '@/components/c-input/index'
import VPeriods from '@/components/v-periods/index'

export default {
    //定义模版数据
    data() {
        return {
            form: {
                list: [{}]
            },
            periodOptions: {
                range: true,
                custom: false,
                customHead: false,
                endTimeMinus: true,
                disabledPeriod: ({startTime, endTime}, index) => {
                    //被其他选中的时间段不可选则
                    const res = this.form.list.some((item, idx) => {
                        const ret = idx !== index && item.times && item.times.length > 0 &&
                            item.times[0].startTime <= startTime &&
                            item.times[item.times.length - 1].endTime >= endTime
                        return ret
                    })
                    return res;

                }
            }
        }
    },
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    components: {
        CInput,
        VPeriods
    },
    //主件被加载完成
    mounted() {
        this.setValue()
    },
    //定义事件方法
    methods: {
        handleAdd() {
            this.form.list.push({})
        },
        handleDel(index) {
            this.form.list.splice(index, 1)
        },
        getFormData() {
            return this.form
        },
        async validate() {
            return await this.$refs['form'].validate()
        },
        setValue() {
            const value = this.value;
            if (value.length > 0) {
                this.form.list = [...value].sort((a, b) => a.startTime > b.startTime ? 1 : -1);
            } else this.form.list = [{}]
        }
    },
    //监听模版变量
    watch: {
        value() {
            this.setValue()
        }
    }

}
</script>
