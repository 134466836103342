<style lang="scss" scoped>
    .answer-contact-name /deep/ {
        display: flex;

        .el-form-item--small.el-form-item {
            margin-bottom: 0;
        }
    }
</style>
<template>
    <div>
        <bms-dialog :isShow="isShow"
                    :title="(addOrEdit === 0 ? '新增':'编辑') + '应单人'"
                    isShowFooter
                    :width="450"
                    @update:cancelBtn="cancelBtn"
                    @update:submitBtn="submitBtn"
                    :submitState="addOrEdit"
        >
            <div slot="content">
                <el-form :model="form" :rules="rules" ref="form" size="small" label-width="130px">
                    <el-form-item label="运营商："
                                  prop="operatorInfo.operatorName"
                                  >
                        <operator-info-input
                                :selectOperatorId="selectOperatorId"
                                :is-edit="userInfo.userType != 0"
                                clearable
                                ref="operatorInfoRef"
                                trigger
                                @update:getSelectOperator="getSelectOperator">
                        </operator-info-input>
                    </el-form-item>
                    <el-form-item label="供应商：">
                        <!--                        prop="supplierInfo.supplierName"-->
                        <!--                        :rules="{required: true, message: '请填写供应商', trigger: 'change'}"-->
                        <supplier-info-input
                                :selectSupplierId="selectSupplierId"
                                :is-edit="userInfo.userType == 1"
                                :operatorInfo="form.operatorInfo"
                                ref="supplierInfoRef"
                                trigger
                                @update:getSelectSupplier="getSelectSupplier">
                        </supplier-info-input>
                    </el-form-item>
                    <el-form-item label="应单人：" required>
                        <div class="answer-contact-name" style="margin-bottom: 15px">
                            <el-form-item prop="name">
                                <el-input v-model="form.name" style="width: 135px;margin-right: 10px"
                                          placeholder="姓名"></el-input>
                            </el-form-item>
                            <el-form-item prop="mobile">
                                <el-input v-model="form.mobile" maxlength="11" placeholder="手机号"></el-input>
                            </el-form-item>
                        </div>
                        <el-form-item prop="idCardNumber" style="margin-bottom: 0">
                            <el-input v-model="form.idCardNumber" :maxLength="18" placeholder="身份证号"
                                      @change="idCardChange"></el-input>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item label="车牌号码：" required>
                        <div class="answer-contact-name">
                            <el-form-item>
                                <el-select v-model="form.provincial" placeholder=""
                                           style="width: 85px;margin-right: 10px">
                                    <el-option :value="vm.value" v-for="vm in plateCity"
                                               :key="vm.value"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item prop="carNumberPlate">
                                <el-input v-model="form.carNumberPlate" maxlength="7"
                                          @change="carNumChange()"
                                          minlength="5" placeholder="5-7位数字、字母组成"></el-input>
                            </el-form-item>
                        </div>
                    </el-form-item>
                    <el-form-item label="车辆品牌型号：" required>
                        <el-radio v-model="carModelRadio" label="1">选择</el-radio>
                        <el-radio v-model="carModelRadio" label="2">输入</el-radio>
                        <div class="answer-contact-name">
                            <el-form-item
                                    style="width: 100%"
                                    v-if="carModelRadio === '1'"
                                    prop="carModel"
                                    :rules="{required: true, message: '请选择品牌型号', trigger: 'change'}">
                                <car-brand-model
                                        ref="brandModelRef"
                                        @update:selectBrandModel="selectBrandModel"></car-brand-model>
                            </el-form-item>
                            <div style="display: flex" v-if="carModelRadio === '2'">

                                <el-form-item
                                        style="margin-right: 10px"
                                        prop="carBrand"
                                        :rules="{required: true, message: '请输入车辆品牌', trigger: 'blur'}"
                                >
                                    <el-input v-model="form.carBrand" placeholder="请输入车辆品牌"></el-input>
                                </el-form-item>

                                <el-form-item
                                        prop="carModel"
                                        :rules="{required: true, message: '请输入车辆型号', trigger: 'blur'}"
                                >
                                    <el-input v-model="form.carModel" placeholder="请输入车辆型号"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="车型：" required>
                        <div class="answer-contact-name">
                            <el-form-item
                                    prop="carType"
                                    :rules="{required: true, message: '请选择车型', trigger: 'change'}">
                                <seat-model v-model="form.carType" :disabled="carModelRadio === '1'"
                                            style="margin-right: 10px"></seat-model>
                            </el-form-item>
                            <el-form-item prop="carColor"
                                          :rules="{required: true, message: '请选择车身颜色', trigger: 'change'}">
                                <el-select v-model="form.carColor" placeholder="车身颜色" style="width: 85px;">
                                    <el-option
                                            v-for="car in vehicleColors"
                                            :key="car"
                                            :label="car"
                                            :value="car"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </el-form-item>
                </el-form>
            </div>


        </bms-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
    import bmsDialog from "../../../../components/dialog/index"
    import plateCity from '@/common/js/data/plateCity.json'
    import {vehicleColors} from '@/data/vehicleData/index'
    import seatModel from "@/components/seatModel/index";

    import operatorInfoInput from "../../../../components/operatorInfoInput/index"
    import supplierInfoInput from "../../../../components/supplierInfoInput/index"
    import carBrandModel from "../../../../components/carBrandModel/index"
    import {onlyChineseAndLetter, isCellphone, onlyLettersOrNumber, isIDCardNumber} from '@/common/js/Utils';
    import {getSupplierIdOperatorInfo} from '@/urls/index';


    import service from "../../../../urls/quotationUrls"

    export default {
        //定义模版数据
        data() {
            let name = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('请填写姓名'));
                } else if (!onlyChineseAndLetter(value)) {
                    callback(new Error('姓名格式错误'));
                } else {
                    callback();
                }
            };
            let mobile = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('请填写手机号'));
                } else if (!isCellphone(value)) {
                    callback(new Error('手机号格式错误'));
                } else {
                    callback();
                }
            };

            let idCardNumber = (rule, value, callback) => {
                if (!value) {
                    callback();
                } else if (!isIDCardNumber(value)) {
                    callback("身份证号格式错误");
                } else callback();
            };
            let carNumber = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('请填写车牌号'));
                } else if (!onlyLettersOrNumber(value) || value.length < 5 || value.length > 7) {
                    callback(new Error('车牌号格式错误'));
                } else {
                    callback();
                }
            };
            return {
                vehicleColors,
                plateCity,
                carModelRadio: '1',
                form: {
                    operatorInfo: {},
                    supplierInfo: {},
                    name: '',
                    mobile: '',
                    idCardNumber:'',
                    carType: null,
                    provincial: '沪',
                    carNumberPlate: '',
                    carBrand: '',
                    carModel: '',
                    carColor: vehicleColors[0],
                },

                selectOperatorId: '',
                selectSupplierId: '',

                rules: {
                    name: [{validator: name, trigger: 'blur'}],
                    mobile: [{validator: mobile, trigger: 'blur'}],
                    idCardNumber: [{validator: idCardNumber, trigger: 'blur'}],
                    carNumberPlate: [{validator: carNumber, trigger: 'blur'}],

                }
            }
        },
        props: {
            infoData: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            addOrEdit: {
                type: Number,
                default: 0
            },  // 0 新增  1编辑
        },
        components: {
            bmsDialog,
            seatModel,
            operatorInfoInput,
            supplierInfoInput,
            carBrandModel
        },
        //计算属性
        computed: {
            isShow() {
                return this.$store.state.singleSetting.answerContactShow;

            },
            userInfo() {
                return this.$store.state.loginUserInfo
            },
        },
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {
            idCardChange(val) {
                this.form.idCardNumber = val;
            },
            getUserInfo(type) {
                switch (parseInt(type)) {
                    case 1:
                        this.getOperatorInfo(this.userInfo.refId);
                        this.selectSupplierId = this.userInfo.refId;
                        break;
                    case 2:
                        this.selectOperatorId = this.userInfo.refId;
                        // this.form.operatorInfo = {
                        //     id: this.userInfo.refId
                        // };
                        break;
                }
            },
            async getOperatorInfo(id) {
                const ret = await getSupplierIdOperatorInfo( id);
                if (ret)
                    this.selectOperatorId = ret.operatorId;
            },
            initData(info) {
                this.carModelRadio = '2';
                let {
                    operatorId,
                    supplierId,
                    mobile,
                    name,
                    idCardNumber,
                    carType,
                    carNumberPlate,
                    carBrand,
                    carModel,
                    carColor,
                    id,
                } = info;

                if (info) {
                    this.selectOperatorId = operatorId;
                    this.selectSupplierId = supplierId;
                    this.form.mobile = mobile;
                    this.form.name = name;
                    this.form.idCardNumber = idCardNumber;
                    this.form.id = id;
                    this.form.carType = Number(carType);
                    this.form.provincial = carNumberPlate.slice(0, 1);
                    this.form.carNumberPlate = carNumberPlate.slice(1);
                    this.form.carBrand = carBrand;
                    this.form.carModel = carModel;
                    this.form.carColor = carColor;
                    let initCarBrandModel = [carBrand, carModel];
                    this.$store.commit('initCarBrandModel', initCarBrandModel)
                }
            },
            getSelectOperator(item) {  // 运营商
                this.form.operatorInfo = item;
            },
            getSelectSupplier(item) {
                this.form.supplierInfo = item;
                this.selectSupplierId = item.id;
            },
            cancelBtn() {
                this.selectOperatorId = '';
                this.selectSupplierId = '';
                this.$refs.operatorInfoRef.clearOperatorName();
                this.$refs.supplierInfoRef.clearSupplierName();
                this.$refs.form.resetFields();
                if(this.carModelRadio === '1') this.$refs.brandModelRef.clearBrandModel();
                this.carModelRadio = '1';
                this.form = {
                    operatorInfo: {},
                    supplierInfo: {},
                    name: '',
                    mobile: '',
                    idCardNumber: '',
                    carType: null,
                    provincial: '沪',
                    carNumberPlate: '',
                    carBrand: '',
                    carModel: '',
                    carColor: vehicleColors[0],
                };
                this.$store.dispatch('initCarBrandModel', []);

                this.$emit("update:cancelBtn")
            },
            submitBtn() {
                this.$refs.form.validate(async (valid) => {
                    if (valid) {
                        let {
                                operatorInfo,
                                supplierInfo,
                                mobile,
                                name,
                                carType,
                                provincial,
                                carNumberPlate,
                                carBrand,
                                carModel,
                                carColor,
                            } = this.form,
                            form = {
                                operatorId: operatorInfo.id,
                                supplierId: supplierInfo.id,
                                mobile,
                                name,
                                carType,
                                carNumberPlate: provincial + carNumberPlate,
                                carBrand,
                                carModel,
                                carColor,
                            };
                        if(this.form.idCardNumber) form.idCardNumber = this.form.idCardNumber;
                        if (this.form.id) form.id = this.form.id;
                        let res = {};
                        let type = this.addOrEdit;
                        switch (type) {
                            case 0:
                                res = await service.insertProductReceiverInfo(form);
                                break;
                            case 1:
                                res = await this.$store.dispatch('updateAnswerContact', form)
                                break
                        }
                        if (res.success && res.data) {
                            this.$message({
                                message: `${type === 0 ? '新增' : '编辑'}应单人成功`,
                                type: 'success'
                            });
                            // this.handleInitData(1);
                            this.$parent.handleInitData(1);
                            this.cancelBtn()
                        }


                        // this.$emit("update:submitBtn", form, )
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },


            selectBrandModel(item) {
                console.log(item, "98999999999999999999");
                this.form.carBrand = item.carBrand;
                this.form.carModel = item.carModel;
                this.form.carType = Number(item.carType);
            },


            carNumChange() {
                this.form.carNumberPlate = this.form.carNumberPlate.toUpperCase()
            },

        },
        //监听模版变量
        watch: {}

    }
</script>

