<style lang="scss" scoped>
.el-button {
    margin-right: 10px;
}
</style>
<template>

    <div>
        <answer-table :columns="data.columns" :dataList="answerContactList" :show-buttons="true">
            <template v-slot:buttons="slotRow">
                <el-button type="text" @click="handleEditBtn(slotRow.row)">编辑</el-button>
                <!--<el-popover-->
                <!--v-if="userInfo.userType != 1"-->
                <!--placement="top"-->
                <!--width="120"-->
                <!--trigger="click"-->
                <!--v-model="slotRow.row.showPopup">-->
                <!--<p>确认删除</p>-->
                <!--<div style="text-align: right; margin: 0">-->
                <!--<el-button size="mini" type="text" @click="slotRow.row.showPopup = false">取消</el-button>-->
                <!--<el-button type="primary" size="mini" @click="handleDelBtn(slotRow.row)">确定</el-button>-->
                <!--</div>-->
                <!--<el-button slot="reference" type="text" style="color: #f56c6c">删除</el-button>-->
                <!--</el-popover>-->
            </template>
        </answer-table>
        <answer-contact-dialog
            @update:cancelBtn="handleCancelBtn"
            ref="answerContactDialogRef"
            :addOrEdit="addOrEdit"></answer-contact-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
import data from "./data"
import answerTable from "../../../../components/table/index"
import answerContactDialog from "../answerContactDialog/index"
import {seatModelList} from "@/data/index"
export default {
    //定义模版数据
    data() {
        return {
            data,
            seatModelList,
            listLoading: false,
            addOrEdit: 0,
            form: {},
            pageInfo: {
                pageIndex: 1,
                pageSize: 10,
                total: 1
            },

            answerContactList: [],
        }
    },
    props: {
        listType: {
            type: Number,
            default: null
        }
    },
    components: {
        answerTable,
        answerContactDialog
    },
    //计算属性
    computed: {

        userInfo() {
            return this.$store.state.loginUserInfo
        }
    },
    //主件被加载完成
    mounted: function () {
        this.handleInitData(1)
    },
    //定义事件方法
    methods: {
        async handleInitData(index) {
            this.pageInfo.pageIndex = index;
            let {
                    operatorId,
                    supplierId,
                    name,
                    mobile,
                    carNumberPlate,
                    carType
                } = this.form,
                form = {
                    operatorId,
                    supplierId,
                    name,
                    mobile,
                    carNumberPlate,
                    pageIndex: index,
                    carType,
                    pageSize: this.pageInfo.pageSize,
                };

            if (this.listType === 0) {
                form.pageSize = 100
            }
            let res = await this.$store.dispatch('answerContactList', form)
            if (res) {
                let list = res.data;
                list.forEach(item => {
                    item.brandModelTitle = item.carBrand + ' ' + item.carModel;
                    item.carTypeTitle = (this.seatModelList.find(list => (item.carType == list.value)) || {}).name;
                });
                this.answerContactList = list;
                this.pageInfo.total = res.total;
                this.$emit('update:list', {pageInfo: this.pageInfo, data: this.answerContactList})
            }
        },


        handleCancelBtn() {
            this.$store.dispatch("answerContactShow", false);
        },
        handleAddOrEditBtn(type) {
            this.$refs.answerContactDialogRef.getUserInfo(this.userInfo.userType);
            this.addOrEdit = type;  // 0 新增  1 编辑
        },
        handleEditBtn(item) {
            if (item) {
                this.$refs.answerContactDialogRef.initData(item);
                this.$store.dispatch("answerContactShow", true);
                this.handleAddOrEditBtn(1);
            }
        },
        async handleDelBtn(item) {
            item.showPopup = false;
            let form = {
                id: item.id,
                isDelete: 1
            }
            let res = await this.$store.dispatch('updateAnswerContact', form)
            if (res.success && res.data) {
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.handleInitData(1)
            }
        },
        handleSearch(form) {
            this.form = form;
            this.handleInitData(1)
        },
        handleReset() {
            this.form = {};
            this.handleInitData(1)

        }
    },
    //监听模版变量
    watch: {}

}
</script>

