/**
 * 车身颜色
 */
export const vehicleColors = ["黑色", "白色", "银色", "灰色", "金色", "红色", "黄色", "蓝色", "绿色", "橙色", "棕色", "紫色", "其它"];
/**
 * 燃油类型
 */
export const fuelTypes = ["汽油", "电", "混合动力", "柴油", "天然气", "液化石油气", "其它"];
/**
 * 发动机类型
 */
export const engineDisplaces = ["1.5L", "1.6L", "1.8L", "2.0L", "2.4L", "2.5L", "3.0L"];

/**
 * 车牌颜色
 */
export const plateColors = ['蓝色', '绿色'];

