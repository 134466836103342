<style lang="scss" scoped>
    .period_container {
        padding: 0 16px;
        h3 {
            margin-bottom: 10px;
            font-size: 16px;
        }
        & > ul {
            display: flex;
            & > li {
                flex: 1;
                margin-right: 40px;
            }
            .head {
                display: flex;
                padding-bottom: 8px;
                justify-content: space-between;
                font-size: 14px;
                .tips {
                    color: #999;
                }
            }
            ul {
                display: flex;
                flex-wrap: wrap;
                border-left: 1px solid #f8f8f8;
                border-top: 1px solid #f8f8f8;
                li {
                    width: 25%;
                    text-align: center;
                    border-right: 1px solid #f8f8f8;
                    border-bottom: 1px solid #f8f8f8;
                    padding: 8px 0;
                    cursor: pointer;
                    box-sizing: border-box;
                    color: #333;
                    font-size: 12px;
                    transition: .2s;
                    margin-left: -1px;
                    margin-top: -1px;
                    span, p {
                        font-size: 12px;
                    }
                    &.selected {
                        background-color: #FFEBEA;
                        border: 1px solid #FF3B30;
                        color: #FF3B30;
                    }
                    &.disabled {
                        color: #999;
                    }
                }
            }
        }
        .tools {
            padding: 12px 0;
            display: flex;
            justify-content: flex-end;
        }
    }
</style>
<template>
    <div class="period_container">
        <h3>时段库存：</h3>
        <ul>
            <li v-for="(vm,idx) in periodsList" :key="idx">
                <div class="head" v-if="!options.customHead">
                    <p>
                        {{vm.list[0].startTime}} - {{vm.list[vm.list.length - 1].endTime | formatMinusDate(options.endTimeMinus)}}</p>
                    <p class="tips">点击时段方框，勾选或者取消勾选</p>
                </div>
                <slot v-else name="head" :data="vm"></slot>
                <ul>
                    <li v-for="period in vm.list" :key="period.startTime"
                        :class="{selected:period.selected,disabled:period.disabled}"
                        @click="handleItemClick(period)">
                        <span v-if="!options.custom">
                        {{period.startTime}} - {{period.endTime | formatMinusDate(options.endTimeMinus)}}
                        </span>
                        <slot v-else :data="period" name="period"></slot>
                    </li>
                </ul>
            </li>
        </ul>
        <div class="tools">
            <el-button size="mini" type="success" @click="handleTool(0)" v-if="!options.range">全选</el-button>
            <el-button size="mini" type="warning" @click="handleTool(1)">清空</el-button>
            <slot name="tools"></slot>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">

    export const formatMinusDate = (date, bool) => {
        if (!bool)
            return date
        const dates = date.split(':');
        if (dates[1] === '30') {
            dates[1] = 29;
        }
        if (dates[1] === '00') {
            dates[0] = ((parseInt(dates[0]) - 1) + '').padStart(2, '0');
            dates[1] = 59
        }
        return dates.join(':')
    }

    const getPeriods = () => {
        const g = (start = 0) => {
            let num2 = 0;
            let num1 = 0;
            let list = new Array(24).fill('').map((item, idx) => {
                num1 = 30 * idx;
                num2 = start + Math.floor(num1 / 60);
                num1 = num1 % 60
                return {
                    startTime: `${(num2 + '').padStart(2, 0)}:${(num1 + '').padStart(2, 0)}`,
                    endTime: `${((num1 === 30 ? (num2 + 1) : num2 ) + '').padStart(2, 0)}:${((num1 === 30 ? 0 : 30) + '').padStart(2, 0)}`,
                }
            })
            return {
                title: `AM ${list[0].startTime}-${list[list.length - 1].endTime}`,
                list
            }
        }
        return [g(), g(12)]
    }
    export default {
        props: {
            value: {
                type: Array,
                default: () => []
            },
            dataList: {
                type: Array,
                default: () => []
            },
            options: {
                type: Object,
                default: () => ({
                    customHead: false,
                    custom: false,
                    range: false,
                    disabledPeriod: null,
                    endTimeMinus: false
                })
            },
            index: Number
        },
        filters: {
            formatMinusDate
        },
        computed: {
            periodsList() {
                const isExist = (data) => this.value.some((item) => item.startTime === data.startTime && item.endTime === data.endTime)
                let periods = getPeriods().map((period) => {
                    period.list = period.list.map(({startTime, endTime}) => {
                        //将数据库里面的值对应
                        let data = this.dataList.find((item) => item.startTime === startTime && item.endTime === endTime);
                        data = data || {startTime, endTime}
                        //当前项是否选中
                        data.selected = isExist(data);
                        if (this.options.disabledPeriod)
                            data.disabled = this.options.disabledPeriod(data, this.index);
                        return data;
                    })
                    return period;
                })
                //当选择是范围时只能选择连贯区间 不连贯的全部不能选择
                if (this.options.range && this.value.length > 0) {
                    const lastValue = this.value[this.value.length - 1];
                    const firstValue = this.value[0];
                    let disabledEndTime = false;
                    let disabledStartTime = false;
                    periods.forEach((period) => {
                        period.list.forEach((item) => {
                            const {startTime, disabled} = item;
                            if (startTime >= lastValue.endTime) {
                                if (disabled && !disabledEndTime)
                                    disabledEndTime = startTime;
                                if (disabledEndTime)
                                    item.disabled = true;
                            }
                        })
                    })
                    for (let j = periods.length - 1; j >= 0; j--) {
                        const period = periods[j]
                        for (let i = period.list.length - 1; i >= 0; i--) {
                            const {endTime, startTime, disabled} = period.list[i];
                            if (endTime < firstValue.startTime) {
                                if (disabled && !disabledStartTime)
                                    disabledStartTime = startTime;
                            }
                            if (disabledStartTime && period.list[i].endTime <= disabledStartTime)
                                period.list[i].disabled = true;
                        }
                    }
                }
                return periods
            }
        },
        //定义事件方法
        methods: {
            //用户点击某个时段
            handleItemClick(item) {
                if (item.disabled)
                    return;
                let value = this.value;
                const index = value.findIndex(({startTime, endTime}) => item.startTime === startTime && item.endTime === endTime)
                if (index >= 0) {
                    //选择是范围把后面的去要去掉
                    if (this.options.range && value.length > 1) {
                        if (index === value.length - 1)
                            value.splice(index, 1)
                        else
                            value = value.slice(0, index + 1);
                        console.log(value);
                    }
                    else
                        value.splice(index, 1)
                }
                else {
                    if (this.options.range && value.length > 0) {
                        //选择是范围的时候把前后都选中
                        this.periodsList.forEach(({list}) => {
                            list.forEach((it) => {
                                if (((it.startTime <= value[0].startTime && it.startTime >= item.endTime) ||
                                        it.startTime >= value[value.length - 1].endTime && it.endTime <= item.startTime) &&
                                    value.some(({startTime, endTime}) => startTime !== it.startTime && endTime !== it.endTime))
                                    value.push({
                                        ...it
                                    })
                            })
                        })
                    }
                    value.push(item);
                }
                value = value.sort((a, b) => a.startTime.replace(':', '') - b.startTime.replace(':', '') > 0 ? 1 : -1)
                this.$emit('input', value)
                this.$emit('change', value)
            },
            handleTool(index) {
                let list = [];
                switch (index) {
                    case 0:
                        list = [...this.periodsList[0].list, ...this.periodsList[1].list]
                        this.$emit('input', list)
                        this.$emit('change', list)
                        break;
                    case 1:
                        this.$emit('input', [])
                        this.$emit('change', [])
                        break;
                }
            }
        }
    }
</script>