<style lang="scss" scoped>
.eTrip-section-cont{
    background: #fff;
    padding: 20px;
}
</style>
<template>

    <div>
        <el-main class="com-eTrip-section">
            <div class="eTrip-section-cont">
                <title-btn-list v-for="(item, index) in singleSettingList" :infoData="item" :key="index"></title-btn-list>
            </div>
        </el-main>
    </div>

</template>

<script type="text/ecmascript-6">
    import data from "./components/data/index"
    import titleBtnList from "./components/titleBtnList/index"

    export default {
        //定义模版数据
        data() {
            return {
                singleSettingList: data.singleSettingList
            }
        },
        components: {
            titleBtnList
        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
            this.$store.dispatch("isSingleSettingShow", true)
        },
        //定义事件方法
        methods: {
            async aaa() {  //
                let form = {};
                try {
                    let res = await this.http('', form, 'POST');
                    if (res.success) {
                        console.log(res);
                    } else {
                        this.messageError('系统出错，请稍后!');
                    }

                } catch (error) {
                    this.messageError()
                }


            },

        }

    }
</script>

