<style lang="scss" scoped>
::v-deep {
    .list-search-after {
        width: 200px;
    }

    .el-descriptions {
        .el-descriptions__body, tr {
            background-color: transparent;
        }
    }

    .el-table__body tbody:after {
        content: ' ';
        width: 100%;
        height: 200px;
    }
}

.list-container {
    margin-bottom: 150px;
}
</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <comListSearch>
                <div slot="list-search-before">
                    <el-row>
                        <el-col :span="5">
                            <el-input size="small" v-model="form.cityName" clearable placeholder="城市"/>
                        </el-col>
                        <el-col :span="5" :offset="1">
                            <SeatModel size="small" v-model="form.vehicleType" placeholder="车型" clearable/>
                        </el-col>
                        <el-col :offset="1" :span="5">
                            <el-select size="small" v-model="form.priceLimitType" placeholder="屏蔽类型" clearable>
                                <el-option v-for="vm in priceLimitTypes" :label="vm.label" :value="vm.value"
                                           :key="vm.value"></el-option>
                            </el-select>
                        </el-col>
                        <el-col :offset="1" :span="5">
                            <el-select size="small" v-model="form.categoryCode" placeholder="服务类型" clearable>
                                <el-option v-for="vm in categorys" :label="vm.label" :value="vm.value"
                                           :key="vm.value"></el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 10px">
                        <el-col :span="5">
                            <el-input size="small" clearable type="number" placeholder="开始公里数"
                                      v-model="form.startEstimateDistance"/>
                        </el-col>
                        <el-col :offset="1" :span="5">
                            <el-input size="small" clearable type="number" placeholder="结束公里数"
                                      v-model="form.endEstimateDistance"/>
                        </el-col>
                        <el-col :offset="1" :span="4">
                            <el-date-picker value-format="yyyy-MM-dd" clearable size="small" style="width: 90%"
                                            v-model="form.date" type="date"
                                            placeholder="选择日期"></el-date-picker>
                        </el-col>
                        <el-col :span="6">
                            <el-time-picker
                                style="width: 100%;"
                                is-range
                                size="small"
                                clearable
                                format="HH:mm"
                                value-format="HH:mm"
                                v-model="form.time"
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                placeholder="选择时间范围">
                            </el-time-picker>
                        </el-col>
                    </el-row>
                </div>
                <div slot="list-search-after">
                    <el-button type="primary" :loading="loading" @click="getData(1)">搜索</el-button>
                </div>
            </comListSearch>
            <div class="eTrip-section-cont list-container">
                <el-table :data="result.data"
                          style="width: 100%;"
                          height="calc(100vh - 285px)"
                          :highlight-current-row="false"
                          v-loading="loading"
                          @sort-change="handleSortChange">
                    <el-table-column prop="create_time" sortable="custom" label="创建时间">
                        <template slot-scope="scope">
                            <el-descriptions :column="1">
                                <el-descriptions-item label="创建时间">
                                    {{ scope.row.createTime | dateCus }}
                                </el-descriptions-item>
                                <el-descriptions-item label="屏蔽类型">
                                    <el-link
                                        :type="scope.row.priceLimitType===priceLimitTypes[0].value?'danger':'warning'">
                                        {{ scope.row.priceLimitType|formatDesc(priceLimitTypes) }}
                                    </el-link>
                                </el-descriptions-item>
                                <el-descriptions-item label="屏蔽价格">{{
                                        scope.row.price | currency
                                    }}
                                </el-descriptions-item>
                                <el-descriptions-item label="价格屏蔽线">{{
                                        scope.row.priceLimit | currency
                                    }}
                                </el-descriptions-item>
                                <el-descriptions-item label="高速费金额">{{ scope.row.toll | currency }}
                                </el-descriptions-item>
                                <el-descriptions-item label="是否是最低兜底价格">{{
                                        scope.row.lowestKeep === 1 ? '是' : '否'
                                    }}
                                </el-descriptions-item>
                            </el-descriptions>
                        </template>
                    </el-table-column>
                    <el-table-column prop="use_time" sortable="custom" label="用车时间">
                        <template slot-scope="scope">
                            <el-descriptions :column="1">
                                <el-descriptions-item label="用车时间">
                                    {{ scope.row.useTime }}
                                </el-descriptions-item>
                                <el-descriptions-item label="服务类型">
                                    {{ scope.row.categoryCode | formatDesc(categorys) }}
                                </el-descriptions-item>
                                <el-descriptions-item label="车型">
                                    {{ scope.row.vehicleType|seatModelDesc }}
                                </el-descriptions-item>
                                <el-descriptions-item label="预估里程">
                                    {{ scope.row.estimateDistance / 1000 }}公里
                                </el-descriptions-item>
                                <el-descriptions-item label="预估时间">
                                    {{ scope.row.estimateTime | formatSecond }}
                                </el-descriptions-item>
                            </el-descriptions>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="地址">
                        <template slot-scope="scope">
                            <el-descriptions :column="1">
                                <el-descriptions-item label="城市名称">
                                    {{ scope.row.cityName }}
                                </el-descriptions-item>
                                <el-descriptions-item label="站点名称">
                                    {{ scope.row.fixedLocationName }}
                                </el-descriptions-item>
                                <el-descriptions-item label="出发地址">
                                    {{ scope.row.duseLocation | formatAddressJson('address') }}<br/>
                                    {{ scope.row.duseLocation | formatAddressJson('detailAddress') }}
                                </el-descriptions-item>
                                <el-descriptions-item label="到达地址">
                                    {{ scope.row.auseLocation | formatAddressJson('address') }}<br/>
                                    {{ scope.row.auseLocation | formatAddressJson('detailAddress') }}
                                </el-descriptions-item>
                            </el-descriptions>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div style="display: flex;justify-content: flex-end;margin: 10px 0;width: 100%">
                <el-pagination background layout="prev, pager, next" :total="result.total"
                               @current-change="getData"></el-pagination>
            </div>
        </el-footer>
    </div>
</template>

<script type="text/ecmascript-6">
import {getCtripPriceLimitApi} from '@/www/urls/quotationUrls'
import comListSearch from '@/www/components/comListSearch/index.vue'
import SeatModel from '@/components/seatModel'
import dayjs from "dayjs";
const priceLimitTypes = [
    {
        label: '高价屏蔽',
        value: 'upperLimit'
    },
    {
        label: '低价屏蔽',
        value: 'lowerLimit'
    }
]

const categorys = [
    {
        label: '接机',
        value: 'airport-pickup'
    },
    {
        label: '送机',
        value: 'airport-dropoff'
    },
    {
        label: '接站',
        value: 'station-pickup'
    },
    {
        label: '送站',
        value: 'station-dropoff '
    }
]
export default {
    name: 'ctripPriceShielding',
    components: {
        comListSearch,
        SeatModel
    },
    data () {
        return {
            loading: false,
            priceLimitTypes,
            categorys,
            result: {
                data: [],
                total: 0
            },
            form: {
                pageIndex: 1,
                pageSize: 10,
                cityName: '',
                vehicleType: null,
                priceLimitType: '',
                categoryCode: null,
                date: null,
                time: null,
                startEstimateDistance: null,
                endEstimateDistance: null,
                sortField: 'create_time',
                sortOrder: 'desc'
            }
        }
    },
    mounted () {
        this.getData();
    },
    methods: {
        handleSortChange ({prop, order}) {
            this.form.form = 1;
            this.form.sortField = prop;
            this.form.sortOrder = order ? (order === 'ascending' ? 'asc' : 'desc') : null
            this.getData();
        },
        async getData (pageIndex = 1) {
            this.loading = true
            this.form.pageIndex = pageIndex;
            const form = {
                ...this.form
            }
            !form.cityName && delete form.cityName
            !form.vehicleType && delete form.vehicleType
            !form.priceLimitType && delete form.priceLimitType
            if (form.date) {
                form.startUseTime = dayjs(form.date, 'YYYY-MM-DD').startOf('d').valueOf()
                form.endUseTime = dayjs(form.date, 'YYYY-MM-DD').endOf('d').valueOf()
                if (form.time && form.time.length === 2) {
                    form.startUseTime = dayjs(`${form.date} ${form.time[0]}`, 'YYYY-MM-DD HH:mm').valueOf()
                    form.endUseTime = dayjs(`${form.date} ${form.time[1]}`, 'YYYY-MM-DD HH:mm').valueOf()
                }
            }
            if (form.startEstimateDistance)
                form.startEstimateDistance = form.startEstimateDistance * 1000
            if (form.endEstimateDistance)
                form.endEstimateDistance = form.endEstimateDistance * 1000
            delete form.date
            delete form.time
            const ret = await getCtripPriceLimitApi(form);
            if (ret.success)
                this.result = ret.data
            else this.$message.error(ret.errors[0].message)
            this.loading = false
        }
    }
}
</script>