<style lang="scss" scoped>
.container {
    position: relative;

    .amap {
        width: 100%;
        height: 100%;
        min-height: 500px;
        overflow: hidden;
        margin: 0;
    }
}

</style>
<template>
    <div class="container">
        <div class="amap" :id="id"></div>
    </div>
</template>

<script type="text/ecmascript-6">

export default {
    //定义模版数据
    data() {
        return {
            map: null,
            result: {}
        }
    },
    props: {
        id: {
            type: String,
            default: 'polyline-map'
        },
        list: {
            type: Array,
            default: () => []
        }
    },
    //主件被加载完成
    async mounted() {
        //加载地图
        const url = '//webapi.amap.com/maps?v=1.4.13&key=0bb2ba3ff63722595ab21b36357841cc&callback=';
        const mapUiUrl = '//webapi.amap.com/ui/1.0/main.js?v=1.0.11';
        if (!window.AMap) {
            await this.loadScrip(url, true);
            await this.loadScrip(mapUiUrl, false);
        }
        this.map = new window.AMap.Map(this.id, {
            zoom: 9,
            resizeEnable: true
        });
        console.log(this.list);

        this.driverPolicy();

    },
    //定义事件方法
    methods: {
        //加载地图
        loadScrip(url, load) {
            return new Promise(function (resolve) {
                const eventName = ['onMapLoad' + (+new Date())];
                window[eventName] = function () {
                    resolve(true);
                }
                const script = document.createElement('script');
                script.charset = 'utf-8';
                script.src = url + (load ? eventName : '');
                script.onload = function () {
                    if (!load)
                        resolve(true);
                }
                document.head.appendChild(script);
            })
        },
        driverPolicy() {
            if (!window.AMap)
                return;
            this.map.clearMap();
            const path = this.list.map((item) => new window.AMap.LngLat(item[0], item[1]));
            // 创建折线实例
            const polyline = new window.AMap.Polyline({
                path: path,
                borderWeight: 1, // 线条宽度，默认为 1
                strokeColor: '#0425d0', // 线条颜色
                lineJoin: 'round' // 折线拐点连接处样式
            });
            // 将折线添加至地图实例
            this.map.add(polyline);
            if (path.length > 0)
                this.map.setCenter(path[0])
            this.map.setFitView();
        }
    },
    beforeDestroy() {
        this.map.destroy();
    },
    watch: {
        list() {
            this.driverPolicy();
        }
    }
}
</script>
