<style lang="scss" scoped>
    .com-eTrip-section.is-setting {
        padding: 0;
        height: auto;
    }
</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer" :class="{'is-setting':isSingleSettingShow}">
            <div class="eTrip-section-cont">
                <service-time-search
                        @update:searchBtn="handleSearchBtn"
                        @update:resetBtn="handleResetBtn"
                        @update:addBtn="handleAddBtn"
                        v-if="!isSingleSettingShow"></service-time-search>
                <service-time-table
                        ref="serviceTimeTable"
                        @update:list="handleList">
                </service-time-table>
                <div style="background: #fff;padding-top: 10px" v-if="isSingleSettingShow">
                    <el-button type="primary" size="small" v-if="serviceTimeList.length == 0" @click="handleAddBtn">+ 新增客服时间</el-button>
                </div>
            </div>
        </el-main>
        <footer-pagination
                :pageInfo="pageInfo"
                @update:initData="handleInitData"
                v-if="!isSingleSettingShow">
        </footer-pagination>
    </div>
</template>

<script type="text/ecmascript-6">
    import serviceTimeSearch from "./components/search/index"
    import serviceTimeTable from "../components/serviceTimeTable/index"
    import footerPagination from "../../../components/footerPagination/index"
    export default {
        //定义模版数据
        data() {
            return {
                form: {},
                pageInfo: {},
                serviceTimeList: [],
            }
        },
        components: {
            serviceTimeSearch,
            serviceTimeTable,
            footerPagination
        },
        //计算属性
        computed: {
            isSingleSettingShow(){
                return this.$store.state.singleSetting.isSingleSettingShow;
            }
        },
        //主件被加载完成
        mounted: function () {
            this.$store.dispatch("isSingleSettingShow", false);
        },
        //定义事件方法
        methods: {
            handleList(form){
                this.pageInfo = form.pageInfo;
                this.serviceTimeList = form.data
            },
            handleInitData(index){
                this.$refs.serviceTimeTable.handleInitData(index);
            },


            handleAddBtn() {
                this.$store.dispatch("serviceTimeShow", true);
                this.$refs.serviceTimeTable.handleAddOrEditBtn(0);
            },
            handleSearchBtn(form) {
                this.$refs.serviceTimeTable.handleSearch(form);
            },
            handleResetBtn() {
                this.$refs.serviceTimeTable.handleReset();
            },
        },
        //监听模版变量
        watch: {}

    }
</script>

