const columns = [
    {label: "运营商", prop: "operatorName"},
    {label: "供应商", prop: "supplierName"},
    {label: "订单联系人1", prop: "orderContactFirst"},
    {label: "订单联系人2", prop: "orderContactSecond"},
    {label: "订单联系人3", prop: "orderContactThird"},
]

export default {
    columns
}
