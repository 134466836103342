<style lang="scss" scoped>
.quote-tool-table {
    margin-top: 10px;
}

.tool-table-price {
    li {
        display: flex;
    }

    .table-price-l {
        width: 180px;
    }

    .table-price-r {
        width: calc(100% - 180px);
    }

    .quote-popover_time_btn {
        padding: 3px 0;
    }
}

.tool-table_range {
    span {
        float: left;
    }
}
</style>
<template>

    <div class="quote-tool-table el-table_border_none">
        <el-table
            border
            :data="list"
            row-key="id"
            tooltip-effect="dark"
            style="width: 100%;calc(100vh - 306px)">
            <el-table-column
                label="批量删除"
                width="80"
                align="center"
                v-if="batchShow">
                <template slot="header">
                    <el-checkbox @change="handleSelectAll" :checked="checkedAll">
                        <span style="font-size: 14px">全选</span>
                    </el-checkbox>
                </template>
                <template slot-scope="scope">
                    <el-checkbox
                        :disabled="(batchShow===1&&scope.row.quoteRuleRelProductDto.status==='1')||
                        (batchShow===2&&scope.row.quoteRuleRelProductDto.status==='0')||
                        ([1,2].includes(batchShow)&&!['0','1'].includes(scope.row.quoteRuleRelProductDto.status))"
                        v-model="scope.row.checked"
                        @change="handleCheckboxChange(scope.row)"></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column
                label="规则编号"
                width="80">
                <template slot-scope="scope">
                    <p>{{ scope.row.ruleNo }}</p>
                </template>
            </el-table-column>
            <el-table-column
                label="渠道/城市/车型/类型/站点"
                min-width="260">
                <template slot-scope="scope">
                    <el-row>
                        <el-col :span="12">
                            <p>渠道：{{ scope.row.quoteRuleRelProductDto.quoteChannel | channelDesc }}</p>
                            <p>站点：{{ scope.row.quoteRuleRelProductDto.stationName || '无' }}</p>
                            <p>
                                出发地栅栏：{{
                                    scope.row.quoteRuleRelProductDto.departureOperatorAddressList | formatOperatorAddress
                                }}
                                {{
                                    scope.row.quoteRuleRelProductDto.departureOperatorAddressList.length > 0 ? '等' : ''
                                }}</p>
                        </el-col>
                        <el-col :span="12">
                            <p>
                                类型：
                                <span v-if="scope.row.quoteRuleRelProductDto.isCharteredCar === 1">
                                包车
                            </span>
                                <span>
                                      {{ scope.row.quoteRuleRelProductDto.type | productTypeDesc }}
                                </span>
                                <span v-if="scope.row.quoteType===2">
                                    {{ scope.row.quoteRuleRelProductDto.isAcross | formatDesc(crossCitys) }}
                                </span>
                            </p>
                            <p>车型：{{ scope.row.quoteRuleRelProductDto.vehicleType | seatModelDesc }}</p>
                            <p>
                                目的地栅栏：{{
                                    scope.row.quoteRuleRelProductDto.destinationOperatorAddressList | formatOperatorAddress
                                }}
                                {{
                                    scope.row.quoteRuleRelProductDto.destinationOperatorAddressList.length > 0 ? '等' : ''
                                }}</p>
                        </el-col>
                    </el-row>

                </template>
            </el-table-column>
            <el-table-column
                label="运营商/供应商"
                width="180">
                <template slot-scope="scope">
                    <p>城市：{{ scope.row.quoteRuleRelProductDto.cityName }}</p>
                    <p>运营商：{{ scope.row.quoteRuleRelProductDto.operatorName }}</p>
                    <p>供应商：{{ scope.row.quoteRuleRelProductDto.supplierName || '-' }}</p>
                </template>
            </el-table-column>
            <el-table-column
                label="价格参数"
                width="360">
                <template slot-scope="scope">
                    <ul class="tool-table-price" v-if="scope.row.quoteType === 1">
                        <li>
                            <div class=""
                                 v-if="scope.row.intervalTimePeriodRecommendDtos && scope.row.intervalTimePeriodRecommendDtos.length > 0">
                                <p v-for="(item, index) in scope.row.intervalTimePeriodRecommendDtos" :key="index">
                                    一口价：{{ item.price }}元，时段：{{ item.periodBegin }} - {{ item.periodEnd }}
                                </p>

                                <!--                                <p>-->
                                <!--                                    白天一口价：{{scope.row.daytimeFixedPrice}}元，白天时段：{{scope.row.daytimePeriod.daytimePeriodBegin}} - {{scope.row.daytimePeriod.daytimePeriodEnd}}</p>-->
                                <!--                                <p>-->
                                <!--                                    夜间一口价：{{scope.row.nightFixedPrice}}元，夜间时段：{{scope.row.nightPeriodFixedPrice.nightPeriodBegin}} - 次日{{scope.row.nightPeriodFixedPrice.nightPeriodEnd}}</p>-->
                            </div>
                        </li>
                    </ul>
                    <ul class="tool-table-price" v-else>
                        <li>
                            <div class="table-price-l">
                                <p v-if="scope.row.basicPrice">基础价：{{ scope.row.basicPrice }}元</p>
                                <p v-if="scope.row.exceedDistancePrice">
                                    超公里价：{{ scope.row.exceedDistancePrice }}元/公里</p>
                                <p v-if="scope.row.exceedTimePrice">
                                    超时长价：{{ scope.row.exceedTimePrice }}元/分钟</p>
                                <div v-if="scope.row.isWhole">
                                    <p v-if="scope.row.flatTimePrice">
                                        平峰价：{{ scope.row.flatTimePrice }}元/公里</p>
                                    <p v-if="scope.row.peakTimePrice">
                                        高峰价：{{ scope.row.peakTimePrice }}元/公里</p>
                                </div>
                            </div>
                            <div class="table-price-r">
                                <p>-</p>
                                <p v-if="scope.row.basicDistance">基础公里数：{{ scope.row.basicDistance }}公里</p>
                                <p v-if="scope.row.basicTime">基础时长：{{ scope.row.basicTime }}分钟</p>
                                <p v-if="scope.row.peakPeriod.length>0">
                                    <el-popover
                                        placement="right"
                                        width="390"
                                        popper-class="quote-popover_time"
                                        trigger="click">
                                        <time-table :exhibition="false"
                                                    :peak-time="scope.row.peakPeriod"
                                                    :night-time="scope.row.nightPeriod"
                                        ></time-table>
                                        <el-button slot="reference" class="quote-popover_time_btn"
                                                   type="text">时段表
                                        </el-button>
                                    </el-popover>
                                </p>
                            </div>
                        </li>
                        <li v-if="scope.row.isWhole">
                            <div class="table-price-l">
                                <p v-if="scope.row.nightFee">夜间费：{{ scope.row.nightFee }}元/公里</p>
                                <p v-if="scope.row.waitingFee">等候费：{{ scope.row.waitingFee }}元/分钟</p>
                                <p v-if="scope.row.exceedBackPrice">
                                    回空里程费：{{ scope.row.exceedBackPrice }}元/公里</p>
                                <p v-if="scope.row.longDistancePrice">
                                    长途费：{{ scope.row.longDistancePrice }}元/公里</p>

                            </div>
                            <div class="table-price-r">
                                <p>&nbsp;</p>
                                <p>-</p>
                                <p v-if="scope.row.allowBackDistance">
                                    超出公里数：{{ scope.row.allowBackDistance }}公里</p>
                                <p v-if="scope.row.longDistance">超出公里数：{{ scope.row.longDistance }}公里</p>
                            </div>
                        </li>
                        <li style="margin-top: 3px">
                            <el-button type="text"
                                       v-if="scope.row.basicPrice"
                                       @click="handleIsWhole(scope.$index)"
                                       style="padding: 0">{{ scope.row.isWhole ? '收起' : '显示全部' }}<i
                                :class="scope.row.isWhole ? 'el-icon-arrow-up':'el-icon-arrow-down'"></i>
                            </el-button>
                        </li>
                    </ul>
                </template>
            </el-table-column>
            <el-table-column
                label="状态"
                width="80">
                <template slot-scope="scope">
                    <p :style="{color: scope.row.quoteRuleRelProductDto.status==1?'#2FCF1B':''}">
                        {{ scope.row.quoteRuleRelProductDto.status | statusDesc }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                label="操作"
                width="150">
                <template slot-scope="scope">
                    <div class="operation-btn clear">

                        <el-button type="text" @click="goToNext(scope.row,'quoteDate')">特定日期加价</el-button>
                        <el-button type="text" @click="goToNext(scope.row,'periodInventory')">库存时段</el-button>
                        <el-button type="text" @click="goToNext(scope.row,'editQuote')">编辑
                        </el-button>
                        <el-button type="text"
                                   :style="{color:scope.row.quoteRuleRelProductDto.status == 1?'#ff3b30':'rgb(47, 207, 27)'}"
                                   v-if="isHeadquarters&&(scope.row.quoteRuleRelProductDto.status=='1'||
                                   scope.row.quoteRuleRelProductDto.status==='0')"
                                   @click="updateStatus(scope.row.quoteRuleRelProductDto.ruleId,
                                   scope.row.quoteRuleRelProductDto.status === '1'?'0':'1')">
                            {{ scope.row.quoteRuleRelProductDto.status === '1' ? '失效' : '生效' }}
                        </el-button>
                        <el-button type="text" @click="goToNext(scope.row,'quoteDetail')"
                                   v-if="isHeadquarters&&scope.row.quoteRuleRelProductDto.status==='3'">
                            审核
                        </el-button>
                        <el-popconfirm v-if="isHeadquarters"
                                       title="确定复制当前项吗？"
                                       @confirm="handleAction(1,scope.row.quoteRuleRelProductDto.ruleId)">
                            <el-button style="margin-left: 10px" type="text" slot="reference">复制
                            </el-button>
                        </el-popconfirm>
                        <el-popconfirm v-if="isHeadquarters"
                                       title="确定删除当前项吗？"
                                       @confirm="handleAction(2,scope.row.quoteRuleRelProductDto.ruleId,scope.row.quoteRuleRelProductDto.status)">
                            <el-button style="margin-left: 10px" type="text" slot="reference">删除
                            </el-button>
                        </el-popconfirm>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>

</template>

<script type="text/ecmascript-6">
import TimeTable from '../../../components/timeTable/index'
import {status} from '@/data/quotationTool'
import {crossCitys} from '@/data/'
import {findItem} from '@/common/js/common'
import {copyQuoteRuleProduct} from '@/www/urls/quotationUrls'

export default {
    //定义模版数据
    data() {
        return {
            crossCitys,
            channels: [],
            status,
            checkedAll: false
        }
    },
    props: {},
    //计算属性
    computed: {
        list() {
            return this.$store.state.quotationToolList.list
        },
        isHeadquarters() {
            let userInfo = this.$store.state.loginUserInfo;
            let userType = userInfo.userType
            return userType === '0'
        },
        batchShow() {
            return this.$store.state.quotationToolList.batchShow
        }
    },
    components: {
        TimeTable
    },
    filters: {
        formatOperatorAddress(data) {
            let address = '无';
            if (data[0])
                address = data[0].addressName;
            return address
        },
        statusDesc(value) {
            return findItem(status, value)
        }
    },
    //定义事件方法
    methods: {
        handleIsWhole(index) {
            let list = JSON.parse(JSON.stringify(this.list));
            list[index].isWhole = !list[index].isWhole;
            this.$store.commit('change_quotation_list', list)
        },
        goToNext(data, path) {
            this.$router.push(`/${path}/${data.id}`);
        },
        async updateStatus(ruleId, status) {
            try {
                await this.$confirm('是否进行此操作', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                let form = {
                    ruleId,
                    status
                }
                this.$store.dispatch('update_quote_status', form)
            } catch (e) {
                console.log(e);
            }
        },
        //用户操作
        async handleAction(type, ruleId, status) {
            //复制
            if (type === 1) {
                this.loadingShow()
                const ret = await copyQuoteRuleProduct(ruleId)
                this.loadingHide()
                if (ret.success) {
                    if (ret.data) {
                        this.$store.dispatch('search_quotation_tool_list', {pageIndex: 1})
                        this.$message.success('复制成功！');
                    } else this.$message.error('复制失败！')
                } else this.$message.error(ret.errors[0].message)
            }
            //删除
            if (type === 2) {
                if (status === '1')
                    return this.$alert('生效的报价规则无法直接删除，请先做[失效]操作。', '提示', {type: 'error'})
                this.$store.dispatch('delete_quote_tool', [ruleId])
            }
        },
        //用户选中要删除报价公式
        handleCheckboxChange() {
            this.$store.commit('change_quotation_list', JSON.parse(JSON.stringify(this.list)))
        },
        //全部选中
        handleSelectAll(checked) {
            let list = JSON.parse(JSON.stringify(this.list));
            for (let item of list) {
                if (this.batchShow === 1 && item.quoteRuleRelProductDto.status === '0')
                    item.checked = checked;
                if (this.batchShow === 2 && item.quoteRuleRelProductDto.status === '1')
                    item.checked = checked;
                if (this.batchShow === 3)
                    item.checked = checked;
            }
            this.$store.commit('change_quotation_list', [...list])
        }
    },
    //监听模版变量
    watch: {
        list(n) {
            this.checkedAll = n.every(({checked}) => checked)
        }
    }

}
</script>
