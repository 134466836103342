<style lang="scss" scoped>
    .user {
        margin-right: 20px;
        color: #666;
    }
</style>
<template>
    <div>
        <span class="user">{{connectInfo.nameFirst}},{{connectInfo.mobileFirst}}</span>
        <span class="user" v-if="connectInfo.nameSecond">{{connectInfo.nameSecond}},{{connectInfo.mobileSecond}}</span>
    </div>

</template>

<script type="text/ecmascript-6">
    import {getProductOrderConnectInfoList} from '@/www/urls/quotationUrls.js'

    export default {
        //定义模版数据
        data() {
            return {
                list: []
            }
        },
        props: {
            value: {
                type: String,
                default: ''
            },
            operatorId: {
                type: String,
                default: null
            },
            supplierId: {
                type: String,
                default: null
            }
        },
        computed: {
            connectInfo() {
                if (this.list.length > 0)
                    return this.list.find(({id}) => id === this.value) || this.list[0]
                else return {}
            }
        },
        //定义事件方法
        methods: {
            async getData() {
                this.list = []
                if (!this.operatorId)
                    return;
                let form = {};
                if (this.operatorId)
                    form = {operatorId: this.operatorId}
                if (this.supplierId)
                    form = {supplierId: this.supplierId}
                console.log(form);
                const ret = await getProductOrderConnectInfoList(form);
                if (ret.success) {
                    if (ret.data.length > 0) {
                        this.list = ret.data || [];
                        console.log(this.list);
                        if (!this.value && this.list.length > 0) {
                            this.$emit('input', this.list[0].id)
                        }
                    }
                } else this.$message.error(ret.errors[0].message)
            },
            //用户选择值
            handleChange() {

            }
        },
        watch: {
            supplierId() {
                this.getData();
            },
            operatorId() {
                this.getData();
            },
            connectInfo(n){
                this.$emit('input', n.id)
            }
        }
    }
</script>
