<style lang="scss" scoped>
.container {
    position: relative;
    height: 100vh;

    .amap {
        width: 100%;
        height: 100%;
    }

    .tools {
        padding: 5px 20px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
        background-color: rgba(0, 0, 0, .3);
        border-radius: 4px;
    }
}

</style>
<template>
    <el-main class="com-eTrip-section">
        <div class="container">
            <el-row class="tools">
                <el-col :span="10" v-if="toolModel===0||toolModel===1">
                    <SearchAddress size="small" :address="form.address" :isSlot="true"
                                   @update:searchAddress="handleAddressChange"/>
                </el-col>
                <el-col :span="10" v-if="toolModel===2||toolModel===3" :offset="toolModel===0||toolModel===1?1:0">
                    <el-input type="text" size="small" placeholder="名称" v-model="form.addressName"/>
                </el-col>
                <el-col :span="12" :offset="1">
                    <el-button size="small" type="primary"
                               @click="handleStartDraw(1)"
                               v-if="toolModel===0">添加折线
                    </el-button>
                    <el-button size="small" type="warning" style="margin-left: 20px"
                               @click="handleStartDraw(2)"
                               v-if="toolModel===0">添加面
                    </el-button>
                    <el-button size="small" type="success" @click="handleStopDraw" v-if="toolModel===1">完成绘制
                    </el-button>
                    <el-button size="small" type="primary" v-if="toolModel===2" @click="handleSave">保存围栏
                    </el-button>
                    <el-button size="small" type="warning" style="margin-left: 20px" @click="handleEdit(0)"
                               v-if="toolModel===2">编辑围栏
                    </el-button>
                    <el-button size="small" type="success" style="margin-left: 20px" @click="handleEdit(1)"
                               v-if="toolModel===3">完成编辑
                    </el-button>
                    <el-button size="small" type="danger" style="margin-left: 20px"
                               @click="handleClear" v-if="this.form.path">
                        清空数据
                    </el-button>
                </el-col>
            </el-row>
            <div class="amap" id="addFenceMap"></div>
        </div>
    </el-main>
</template>

<script type="text/ecmascript-6">
import SearchAddress from '@/components/searchAddress';
import {addOperatorAddressApi, updateOperatorAddressApi} from '@/www/urls/quotationUrls';

export default {
    components: {SearchAddress},
    //定义模版数据
    data() {
        return {
            map: null,
            result: {},
            toolModel: 0,
            form: {
                addressName: '',
                address: {
                    cityId: '3101',
                    city: '上海市'
                },
                path: []
            }
        }
    },
    //主件被加载完成
    async mounted() {
        //加载地图
        const url = '//webapi.amap.com/maps?v=1.4.13&key=0bb2ba3ff63722595ab21b36357841cc&plugin=AMap.MouseTool,AMap.PolyEditor&callback=';
        await this.loadScrip(url, true);
        this.map = new window.AMap.Map('addFenceMap', {
            zoom: 9,
            resizeEnable: true
        });
        this.mouseTool = new window.AMap.MouseTool(this.map);
        this.mouseTool.on('draw', (e) => {
            this.form.path = [...this.form.path, ...e.obj.getPath()]
        })
        if (this.$route.query.data) {
            try {
                const data = JSON.parse(this.$route.query.data)
                this.form.addressName = data.addressName;
                this.form.address = {
                    cityId: data.cityId,
                    city: data.cityName
                };
                this.form.path = data.addressDetail.split(';').map((str) => new window.AMap.LngLat(str.split(',')[0], str.split(',')[1]))
                this.form.id = data.id;
                this.driverPolicy(true)
                this.handleEdit(0)
            } catch (err) {
                console.log(err);
            }
        } else this.form = {
            addressName: '',
            address: {
                cityId: '3101',
                city: '上海市'
            },
            path: []
        }
    },
    //定义事件方法
    methods: {
        //加载地图
        loadScrip(url, load) {
            return new Promise(function (resolve) {
                const eventName = ['onMapLoad' + (+new Date())];
                window[eventName] = function () {
                    resolve(true);
                }
                const script = document.createElement('script');
                script.charset = 'utf-8';
                script.src = url + (load ? eventName : '');
                script.onload = function () {
                    if (!load)
                        resolve(true);
                }
                document.head.appendChild(script);
            })
        },
        driverPolicy(draggable = false) {
            if (!window.AMap || !this.form.path)
                return;
            console.log(this.form.path);

            this.map.clearMap();
            // 创建折线实例
            this.polyline = new window.AMap.Polyline({
                path: this.form.path,
                borderWeight: 4, // 线条宽度，默认为 1
                strokeColor: '#0425d0', // 线条颜色
                lineJoin: 'round', // 折线拐点连接处样式
                draggable
            });
            // 将折线添加至地图实例
            this.map.add(this.polyline);
            this.map.setFitView();
        },
        handleAddressChange(ads) {
            this.form.address = ads;
            if (this.map) {
                if (ads.location) {
                    const p = new window.AMap.LngLat(ads.location.split(',')[0], ads.location.split(',')[1]);
                    this.map.setCenter(p);
                    this.drawMarker(p);
                } else
                    this.map.setCity(this.form.address.city)
            }
        },
        //点击绘制
        handleStartDraw(type = 1) {
            this.toolModel = 1;
            this.form.path = [];
            if (type === 1) {
                this.mouseTool.polyline({
                    strokeColor: '#0425d0',
                    strokeWeight: 4
                });
            } else {
                this.mouseTool.polygon({
                    strokeColor: '#0425d0',
                    strokeWeight: 4
                });
            }
        },
        //点击完成绘制
        handleStopDraw() {
            if (this.form.path.length < 3)
                return this.$message.error("请用鼠标在地图进行绘制至少3个点")
            this.toolModel = 2;
            this.mouseTool.close();
            if (this.form.path) {
                if (this.form.path.length > 1) {
                    if (this.form.path[0].lat !== this.form.path[this.form.path.length - 1].lat ||
                        this.form.path[0].lng !== this.form.path[this.form.path.length - 1].lng) {
                        this.form.path.push(this.form.path[0])
                    }
                }
                this.driverPolicy();
            }
        },
        //绘制点标记
        drawMarker(LngLat, title) {
            if (!window.AMap)
                return;
            this.map && this.marker && this.map.remove(this.marker)
            this.marker = new window.AMap.Marker({
                position: LngLat,
                title
            });
            this.map && this.map.add(this.marker);
        },
        //点击编辑围栏
        handleEdit(type = 0) {
            if (type === 0) {
                this.toolModel = 3;
                this.polyEditor = new window.AMap.PolyEditor(this.map, this.polyline)
                this.polyEditor.on('end', (event) => {
                    this.form.path = event.target.getPath();
                    this.driverPolicy();
                })
                this.polyEditor.open();
            } else {
                this.toolModel = 2;
                this.polyEditor.close();
            }
        },
        //清空
        async handleClear() {
            try {
                await this.$confirm('清空数据，将会清除所有绘制数据，是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                this.mouseTool.close();
                this.map.clearMap();
                this.toolModel = 0;
                this.form.path = [];
            } catch (e) {
                console.log(e);
            }
        },
        //保存围栏
        async handleSave() {
            if (!this.form.addressName)
                return this.$message.error("请输入围栏名称")
            let ret;
            const form = {
                id: this.form.id,
                addressName: this.form.addressName,
                addressDetail: this.form.path.map(({lat, lng}) => `${lng},${lat}`).join(';'),
                cityId: this.form.address.cityId,
                addressType: 1,
                cityName: this.form.address.city
            }
            if (this.form.id)
                ret = await updateOperatorAddressApi(form);
            else
                ret = await addOperatorAddressApi(form);
            if (ret.success) {
                this.$message.success(this.form.id ? "更新成功～" : "添加成功～");
                this.$router.push('/fenceList')
            } else this.$message.error(ret.errors[0].message)
        }
    },
    beforeDestroy() {
        this.map.destroy();
    }
}
</script>
