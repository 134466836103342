<style lang="scss" scoped>

</style>
<template>

    <div>
        <div v-for="(item, index) in value" :key="name+index" style="display: flex;margin-bottom: 10px">
            <el-checkbox :key="index" :label="item.value"
                         v-model="item.checked"
                         style="width: 200px"
                         @change="selectCheckbox">
                {{item.label}}
            </el-checkbox>
            <el-form-item label="车型："
                          label-width="70px"
                          :prop="name+'.'+index+'.vehicleType'"
                          :rules="{required: !!item.checked, message: '请选择车型', trigger: 'blur'}"
                          v-if="item.checked">
                <seat-model v-model="item.vehicleType"
                            @input="selectSeat"
                            :disabled="item.disabled"
                            multiple
                            style="width: 705px"></seat-model>
            </el-form-item>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
    import SeatModel from "@/components/seatModel/index";

    export default {
        //定义模版数据
        data() {
            return {}
        },
        props: {
            value: {
                type: Array,
                default: () => []
            },
            name: {
                type: String,
                default: ''
            }
        },
        components: {
            SeatModel
        },
        //定义事件方法
        methods: {
            selectCheckbox() {
                this.$emit('input', JSON.parse(JSON.stringify(this.value)))
            },
            selectSeat() {
                this.$emit('input', JSON.parse(JSON.stringify(this.value)))
            },
        },
        //监听模版变量
        watch: {}

    }
</script>

