/**
* Created by hansxing on 2018/12/17.
*/
<style lang="scss">
.quote-list {
    .common-list-search {
        .list-search-before {
            width: calc(100% - 180px);
        }

        .list-search-after {
            width: 180px;
            text-align: right;
        }
    }
}

.quote-popover_time {
    .time-table .time-table-title {
        margin-left: 0 !important;
    }
}

.excel-file-btn {
    width: 140px;
    height: 40px;
    border-radius: 4px;
    float: left;

    .file-btn-a {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 10px 0;
        border-radius: 4px;
        text-align: center;
        display: inline-block;
        color: #fff;
        background-color: #409EFF;
        border-color: #409EFF;
        text-decoration: none;
        text-indent: 0;
        font-size: 12px;
        -webkit-transition: .1s;
        transition: .1s;

        input {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            display: inline-block;
        }

        &:hover {
            background: #66b1ff;
            border-color: #66b1ff;
        }
    }
}

</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont quote-list">
                <quote-list-search ref="searchForm"></quote-list-search>
                <quote-table></quote-table>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont">
                <div class="table-page-number">
                    <div class="block">
                        <el-pagination @current-change="quoteTablePageChange"
                                       :current-page="page.pageIndex"
                                       :page-size="page.pageSize"
                                       layout="total, prev, pager, next"
                                       :total="page.total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </el-footer>
    </div>

</template>

<script type="text/ecmascript-6">
import quoteListSearch from "./components/search/index"
import Table from './components/table/index.vue'

export default {
    //定义模版数据
    name: 'quoteList',
    data() {
        return {
            dialogVisible: false,
            file: {},
            stationList: [],
            stationNameList: [],
            channels: [],//渠道
            refresh: null
        }
    }
    ,
    components: {
        quoteListSearch, QuoteTable:
        Table
    }
    ,
    //计算属性
    computed: {
        page() {
            return this.$store.state.quotationToolList.page
        }
    },
    activated() {
        if (this.refresh !== this.$route.query.refresh) {
            this.search(1);
            this.refresh = this.$route.query.refresh
        }
    },
    //定义事件方法
    methods: {
        search(index) {
            this.$refs['searchForm'].search(index)
        },
        async quoteTablePageChange(val) {
            try {
                // if (this.$store.state.quotationToolList.batchShow) {
                //     await this.$confirm('您处于批量操作模式，请确保数据已保存，切换页面选择的项目将会丢失！')
                // }
                this.search(val);
            } catch (e) {
                console.log(e);
            }
        }
    }
}
</script>

