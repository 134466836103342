/**
* 计算套餐外价格
*/
<style lang="scss" scoped>
    .container {
        margin-top: 10px;
    }

    .form {
        margin-top: 10px;
    }
</style>
<template>

    <div class="container">
        <el-button type="primary" @click="handleShowForm(showForm)">计算套餐外价格</el-button>
        <div v-if="showForm">
            <el-form ref="form" :inline="true" :model="form" :rules="rules" class="form">
                <el-form-item label="用车公里数：" prop="distance">
                    <el-input v-model="form.distance" placeholder="用车公里数"></el-input>
                </el-form-item>
                <el-form-item label="用车完成时间（分钟）：" prop="useTime">
                    <el-input v-model="form.useTime" type="number">
                        <span slot="suffix">分</span>
                    </el-input>
                    <!--<el-date-picker-->
                    <!--v-model="form.finishTime"-->
                    <!--type="date"-->
                    <!--:picker-options="pickerOptions"-->
                    <!--placeholder="用车完成时间">-->
                    <!--</el-date-picker>-->
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">查询</el-button>
                </el-form-item>
            </el-form>
            <el-table
                    :data="result"
                    style="width: 100%">
                <el-table-column
                        prop="distance"
                        label="用车公里数"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="exceedDistance"
                        label="超公里数"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="exceedDistanceFee"
                        label="超公里费用">
                </el-table-column>
                <el-table-column
                        prop="basicDistance"
                        label="基础公里数">
                </el-table-column>
                <el-table-column
                        prop="exceedTime"
                        label="超时长">
                </el-table-column>
                <el-table-column
                        prop="exceedTimeFee"
                        label="超时长费用">
                </el-table-column>
                <el-table-column
                        prop="basicTime"
                        label="基础时长">
                </el-table-column>
                <el-table-column
                        prop="time"
                        label="用车时长">
                </el-table-column>
            </el-table>
        </div>
    </div>

</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            return {
                showForm: false,
                form: {
                    distance: 0,
                    useTime: null
                },
                result: [],
                rules: {
                    distance: [{required: true, message: '请输入用车公里数', trigger: 'blur'}],
                    useTime: [{required: true, message: '请输入用车完成时间', trigger: 'blur'}]
                },
                pickerOptions: {
                    disabledDate: (time) => {
                        return time.getTime() < +new Date(new Date(this.useCarTime).toDateString());
                    }
                }
            }
        },
        props: {
            useCarTime: {
                type: String,
                value: null
            },
            info: {
                type: Object,
                value: {}
            }
        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
        },
        //定义事件方法
        methods: {
            //显示或隐藏form
            handleShowForm(show) {
                this.showForm = !show;
            },
            /**
             * 提交查询
             */
            onSubmit() {
                this.$refs['form'].validate(async (valid) => {
                    if (valid) {
                        this.loadingShow();
                        const {ruleId, cityId, quoteChannel} = this.info.quoteRuleDto;
                        const ret = await this.http('/galaxyQuote/charteredCarExtraPrice', {
                            ruleId,
                            cityId,
                            quoteChannel,
                            useCarTime: +new Date(this.useCarTime),
                            ...this.form,
                            useTime: this.form.useTime
                        }, 'POST');
                        if (ret.success && ret.data) {
                            this.result = [ret.data];
                        }
                        this.loadingHide();
                    }
                })
            }
        },
        //监听模版变量
        watch: {}

    }
</script>
