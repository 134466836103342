const columns = [
    {label: "运营商名称", prop: "operatorName"},
    {label: "供应商名称", prop: "supplierName"},
    {label: "应单人姓名", prop: "name"},
    {label: "应单人手机", prop: "mobile"},
    {label: "身份证号码", prop: "idCardNumber", width: '170'},
    {label: "车牌号码", prop: "carNumberPlate"},
    {label: "车辆品牌型号", prop: "brandModelTitle"},
    {label: "车型", prop: "carTypeTitle"},
    {label: "车身颜色", prop: "carColor"}
];
export default {
    columns
}
