<template>

    <div>
        <el-autocomplete
            style="width: 100%"
            v-model="operatorName"
            :fetch-suggestions="handleOperator"
            placeholder="输入运营商名称"
            :trigger-on-focus="trigger"
            :disabled="isEdit"
            @select="select"
            @blur="blur"
            @clear="clear"
            :clearable="clearable">
            <template slot-scope="{ item }">
                <div class="list-pop-bot">
                    <span>{{ item.label }}</span>
                </div>
            </template>
        </el-autocomplete>
    </div>

</template>

<script type="text/ecmascript-6">
export default {
    name: "operatorInput",
    //定义模版数据
    data() {
        let operatorUrl = '/galaxyOperator/operatorInfoList';
        return {
            operatorName: '',
            operatorUrl
        }
    },
    props: {
        selectOperatorId: {
            type: String,
            default: ''
        },  // 当前选中的运营商id
        isEdit: {
            type: Boolean,
            default: false
        },  // 是否编辑
        clearable: {
            type: Boolean,
            default: false
        },  // 是否清除  可配合@clear 清除事件
        trigger: {
            type: Boolean,
            default: false,
        },   // 是否显示建议列表
        clearOperator: {
            type: Function,
            default: null
        }
    },
    components: {},
    //计算属性
    computed: {},
    //主件被加载完成
    mounted: function () {
        this.getSelectOperatorInfo()
    },
    activated() {

    },
    //定义事件方法
    methods: {
        clearOperatorName() {
            this.operatorName = '';
            this.name = ''
        },
        filter(item) {
            return (res) => {
                return res.operatorName && res.operatorName.indexOf(item) === 0;
            };
        },
        async handleOperator(operator, cb) {
            let res = await this.http(this.operatorUrl, '', 'POST');
            if (res && res.success) {
                let list = [];
                list = res.data;
                list.forEach(item => {
                    item.value = item.id;
                    item.label = item.operatorName;
                });
                let operatorList = operator ? list.filter(this.filter(operator)) : list;
                cb(operatorList);
            }
        },  // 运营商列表
        select(item) {
            this.operatorName = item.operatorName;
            this.name = item.operatorName;
            this.$emit('input', item.id)
            this.$emit('update:getSelectOperator', item)
        },  // 运营商选中
        blur() {
            this.operatorName = this.name;
            this.$emit('update:selectOperatorBlur', this.operatorName)
        },
        clear() {
            this.clearOperatorName();
            if (this.clearOperator) {
                this.clearOperator()
            }
            this.$emit('clear')
        },
        async getSelectOperatorInfo() {
            let res = await this.http(this.operatorUrl, '', 'POST');
            if (res && res.success) {
                let list = [];
                list = res.data;
                list.forEach(item => {
                    if (item.id == this.selectOperatorId) {
                        this.operatorName = item.operatorName;
                        this.select(item)
                    }
                })
            }
        }// 根据selectOperatorId 运营商id查询运营商详情
    },
    //监听模版变量
    watch: {
        "selectOperatorId": function (n) {
            this.selectOperatorId = n;
            if (n) {
                this.getSelectOperatorInfo()
            }
        }
    }

}
</script>

