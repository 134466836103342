<style lang="scss" scoped>
.add-quote {
    .eTrip-box-card {
        p {
            margin-bottom: 10px;
        }
    }

    .line {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;
        height: 34px;
    }
}
</style>
<template>
    <div class="add-quote">
        <el-form label-position="left"
                 label-width="90px"
                 :model="form"
                 ref="form"
                 :rules="rules"
                 size="small">
            <div class="eTrip-box-card clear">
                <el-form-item label="报价类型：" prop="quoteType">
                    <quotation-type v-model="form.quoteType"></quotation-type>
                </el-form-item>
            </div>
            <div class="eTrip-box-card">
                <el-row style="margin-bottom: 10px">
                    <el-col :span="5">
                        <el-form-item label="城市："
                                      label-width="90px"
                                      prop="cityId"
                                      :rules="{ required: true, message: '出发城市', trigger: 'change' }">
                            <select-city :is-border="false"
                                         @update:city="handleDepChange">
                            </select-city>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" class="line">
                        -
                    </el-col>
                    <el-col :span="3">
                        <el-form-item prop="destinationCityId"
                                      style="margin-left:-100px"
                                      :rules="{ required: form.quoteType===2, message: '到达城市', trigger: 'change' }">
                            <select-city :is-border="false"
                                         @update:city="handleDesChange">
                            </select-city>
                        </el-form-item>
                    </el-col>
                    <el-col :md="5" :offset="1">
                        <el-form-item label="运营商：" label-width="80px" prop="operatorId">
                            <operator-select @change="handleOperatorChange"
                                             v-if="userInfo.userType!=='2'"></operator-select>
                            <p v-else>{{ userInfo.companyName }}</p>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5" :offset="1">
                        <el-form-item label="供应商：" label-width="80px">
                            <supplier-select :operatorId="form.operatorId"
                                             userType="2"
                                             @change="handleSupplierChange">
                            </supplier-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="是否跨城：" v-if="form.quoteType===2">
                    <el-radio-group v-model="form.isAcross">
                        <el-radio-button :label="vm.value" v-for="(vm,index) in crossCitys" :key="index">
                            {{ vm.label }}
                        </el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="包车类型：" v-if="form.quoteType===2">
                    <product-type name="charteredCarDuration" v-model="form.charteredCarDuration"></product-type>
                </el-form-item>
                <el-form-item label="产品类型：" v-else>
                    <product-type name="quoteRuleProductTypeDtoList"
                                  v-model="form.quoteRuleProductTypeDtoList"></product-type>
                </el-form-item>
            </div>
            <div class="eTrip-box-card">
                <el-form-item label="渠道：">
                    <channel-types v-model="form.quoteRuleProductChannelDtoList"></channel-types>
                </el-form-item>
            </div>
        </el-form>
    </div>

</template>

<script type="text/ecmascript-6">
import SupplierSelect from '@/www/components/supplier-select'
import SelectCity from '@/components/selectCity/'
import quotationType from '../quotationType/index'
import productType from '../productType/index'
import OperatorSelect from "../../../../components/operator-select/index"
import ChannelTypes from "../channel-types/index.vue"
import {quotationProductionTypes, crossCitys} from "@/data/index"
import {carCharterTypeList} from '@/data/quotationTool'
import {channelList} from '@/data/index'

const initForm = () => ({
    isAcross: 0,
    quoteType: 0,
    cityId: '',
    cityName: '',
    destinationCityId: '',
    destinationCityName: '',
    operatorId: '',
    operatorName: '',
    supplierId: '',
    supplierName: '',
    quoteRuleProductChannelDtoList: channelList.map(item => ({
        ...item,
        platformRatio: null,
        quoteChannelRatio: null,
        quoteChannel: item.value
    })),
    quoteRuleProductTypeDtoList: quotationProductionTypes.map(item => ({
        ...item,
        vehicleType: []
    })),
    charteredCarDuration: carCharterTypeList.map(item => ({
        ...item,
        vehicleType: []
    }))
})

export default {
    //定义模版数据
    name: 'quoteForm',
    data() {
        return {
            crossCitys,
            form: initForm(),
            rules: {
                cityId: [{required: true, message: '请选择出发城市'}],
                destinationCityId: [{required: true, message: '请选择到达城市'}],
                operatorId: [{required: true, message: '请选择运营商'}]
            }
        }
    },
    mounted() {
        if (this.userInfo.userType === '2') {
            this.handleOperatorChange({
                id: this.userInfo.refId,
                operatorName: this.userInfo.companyName
            })
        }
    },
    components: {
        quotationType,
        productType,
        SupplierSelect,
        SelectCity,
        OperatorSelect,
        ChannelTypes
    },
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo
        }
    },
    //定义事件方法
    methods: {
        handleDepChange({shortCode, city}) {
            this.form.cityId = shortCode
            this.form.cityName = city
        },
        handleDesChange({shortCode, city}) {
            this.form.destinationCityId = shortCode
            this.form.destinationCityName = city
        },
        handleOperatorChange({id, operatorName}) {
            this.form[`operatorId`] = id;
            this.form[`operatorName`] = operatorName;
        },
        handleSupplierChange({id, supplierName}) {
            this.form[`supplierId`] = id;
            this.form[`supplierName`] = supplierName;
        },
        submit() {
            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    let form = {
                        ...this.form
                    }
                    form.quoteRuleProductTypeDtoList = []
                    form.quoteRuleProductChannelDtoList = []
                    form.charteredCarDuration = [];
                    for (let item of this.form.quoteRuleProductTypeDtoList) {
                        if (item.checked) {
                            form.quoteRuleProductTypeDtoList.push({
                                vehicleType: item.vehicleType,
                                type: item.value
                            })
                        }
                    }
                    for (let item of this.form.quoteRuleProductChannelDtoList) {
                        if (item.checked) {
                            form.quoteRuleProductChannelDtoList.push({
                                platformRatio: item.platformRatio,
                                quoteChannelRatio: item.quoteChannelRatio,
                                quoteChannel: item.quoteChannel
                            })
                        }
                    }
                    for (let item of this.form.charteredCarDuration) {
                        if (item.checked) {
                            form.charteredCarDuration.push({
                                vehicleType: item.vehicleType,
                                charteredCarDuration: item.value,
                                type: 2
                            })
                        }
                    }
                    if (form.quoteType !== 2 && form.quoteRuleProductTypeDtoList.length === 0)
                        return this.$message.error('请选择产品类型')
                    if (form.quoteType === 2 && form.charteredCarDuration.length === 0)
                        return this.$message.error('请选择包车产品类型')
                    if (form.quoteRuleProductChannelDtoList.length === 0)
                        return this.$message.error('请选择渠道')
                    if (form.quoteType === 2) {
                        form.quoteRuleProductTypeDtoList = form.charteredCarDuration;
                        delete form.charteredCarDuration;
                    }
                    try {
                        await this.$confirm('我已确认填写内容并提交?', '确认提交', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        })
                        const ret = await this.$store.dispatch('add_new_quotation_tool', form)
                        if (ret) {
                            this.$store.commit('delete_tabs', this.$route.path);
                            this.$router.push('/quoteList')
                            this.form = initForm();
                        }
                    } catch (e) {
                        console.log(e);
                    }
                } else this.$message.error('还有必填项未填写')
            })
        }
    },
    //监听模版变量
    watch: {}

}
</script>

