<template>
    <div>
        <el-table
            type="index"
            class="table-info"
            border
            :data="dataList"
            v-loading="listLoading"
            style="width: 100%">
            <el-table-column
                type="index"
                width="50"
                label="序号"
                align="center"
                v-if="isIndex"
            >
            </el-table-column>
            <el-table-column
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
                :prop="item.prop"
                :width="item.width"
            >
                <template slot-scope="scope">
                    <span v-if="!isSlot">
                        {{ scope.row[item.prop] }}
                    </span>
                    <slot name="desc" :row="scope.row" :col="item"></slot>
                </template>
            </el-table-column>
            <el-table-column label="操作" v-if="showButtons" :width="operationWidth">
                <template slot-scope="scope">
                    <div class="operation-btn clear">
                        <slot name="buttons" :row="scope.row"></slot>
                    </div>
                </template>
            </el-table-column>
        </el-table>

    </div>

</template>

<script type="text/ecmascript-6">
export default {
    //定义模版数据
    data() {
        return {
            listLoading: false
        }
    },

    props: {
        dataList: {
            type: Array,
            default: function () {
                return []
            }
        },
        columns: {
            type: Array,
            default: function () {
                return []
            }
        },
        showButtons: {
            type: Boolean,
            default: false
        },
        isSlot: {
            type: Boolean,
            default: false
        },
        isIndex: {
            type: Boolean,
            default: false
        },
        operationWidth: {
            type: String,
            default: ''
        }
    }

}
</script>

