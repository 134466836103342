<style lang="scss" scoped>
    .quote-base-info-container {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        padding: 20px 0;
        color: #666;
        li {
            width: 25%;
            margin-bottom: 10px;
        }
    }
</style>
<template>
    <div>
        <ul class="quote-base-info-container">
            <li>规则编号：{{info.ruleNo}}</li>
            <li>车型：{{info.quoteRuleRelProductDto.vehicleType | seatModelDesc}}</li>
            <li>渠道：{{info.quoteRuleRelProductDto.quoteChannel | channelDesc}}</li>
            <li>报价类型：{{info.quoteType | quotationTypeDesc}}</li>
            <li>城市：{{info.quoteRuleRelProductDto.cityName}} - {{info.quoteRuleRelProductDto.destinationCityName}}</li>
            <li v-if="info.quoteType===2">跨城：{{info.quoteRuleRelProductDto.isAcross | formatDesc(crossCitys)}}</li>
            <li>产品类型：{{info.quoteRuleRelProductDto.type | productTypeDesc}}</li>
            <li>运营商：{{info.quoteRuleRelProductDto.operatorName}}</li>
            <li>供应商：{{info.quoteRuleRelProductDto.supplierName}}</li>
            <li>渠道费率：{{info.quoteRuleRelProductDto.quoteChannelRatio}}%</li>
            <li>平台费率：{{info.quoteRuleRelProductDto.platformRatio}}%</li>
        </ul>
    </div>
</template>

<script type="text/ecmascript-6">
    import {crossCitys} from '@/data/'
    export default {
        //定义模版数据
        data() {
            return {
                crossCitys
            }
        },
        props: {
            info: {
                type: Object,
                value: () => ({})
            }
        }
    }
</script>
