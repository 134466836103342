<style lang="scss" scoped>
.list {
    color: #333;

    & > li {
        background-color: #fff;
        padding-bottom: 10px;

        &:hover {
            background-color: #E6F1FC;
        }
    }
}

.list-hd {
    height: 52px;
    line-height: 52px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding: 0 20px;
    font-weight: 500;
    & > div {
        display: flex;
    }

    & > div > span {
        min-width: 120px;
    }

    .label {
        color: #909399;
        font-weight: normal;
    }
}

.list-bd {
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    margin-bottom: 10px;

    li {
        width: 140px;
        height: 50px;
        display: flex;
        font-size: 14px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(216, 220, 230, 1);
        margin-left: -1px;
        margin-top: -1px;
        background-color: #fff;
        font-weight: 500;
    }
}

.vehicle-inventory-monitor::v-deep {
    .common-list-search .list-search-before .el-form-item.el-form-item--small {
        margin-bottom: 0;
    }
}
</style>

<template>
    <el-main class="com-eTrip-section vehicle-inventory-monitor">
        <com-list-search>
            <div slot="list-search-before">
                <el-form inline size="small">
                    <el-form-item>
                        <ApiSelect :options="channels" clearable placeholder="渠道"
                                   v-model="form.quoteChannel" @clear="form.quoteChannel=undefined"></ApiSelect>
                    </el-form-item>
                    <el-form-item>
                        <selectCity ref="selectCityRef" placeholder="城市" clearable :isBorder="false"
                                    @update:city="handleSelectCity"></selectCity>
                    </el-form-item>
                    <el-form-item>
                        <ApiSelect :options="quotationProductionTypes" clearable placeholder="类型"
                                   v-model="form.type" @clear="form.type=undefined"></ApiSelect>
                    </el-form-item>
                    <el-form-item>
                        <ApiSelect :options="seatModelList" clearable placeholder="车型车座"
                                   v-model="form.vehicleType" @clear="form.vehicleType=undefined"></ApiSelect>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker align="right" type="date" :clearable="false" placeholder="选择日期"
                                        :picker-options="pickerOptions" v-model="form.date"></el-date-picker>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="list-search-after">
                <el-button @click="resetForm()">重置</el-button>
                <el-button type="primary" :loading="loading" @click="search()">查询</el-button>
            </div>
        </com-list-search>
        <div class="eTrip-section-cont" v-loading="loading">
            <ul class="list" v-if="dataSource.length">
                <li v-for="vm in dataSource" :key="vm.id" class="mb10">
                    <div class="list-hd">
                        <div>
                            <span style="min-width: 150px"><span class="label">报价ID：</span>{{ vm.ruleNo }}</span>
                            <span><span class="label">渠道：</span>{{ vm.quoteChannel | channelDesc }}</span>
                            <span><span class="label">城市：</span>{{ vm.cityName }}</span>
                            <span><span class="label">类型：</span>{{
                                    vm.type | formatDesc(quotationProductionTypes)
                                }}</span>
                            <span style="min-width: 180px"><span
                                class="label">车型车座：</span>{{ vm.vehicleType | seatModelDesc }}</span>
                            <span><span class="label">日期：</span>{{
                                    vm.date | dateFormat('YYYY年MM月DD日','YYYYMMDD')
                                }}</span>
                        </div>
                        <el-link type="primary" class="font_s16" :href="'#/periodInventory/'+vm.id+'?date='+form.date">
                            编辑时段库存
                        </el-link>
                    </div>
                    <ul class="list-bd" v-for="(li,index) in vm.list" :key="index">
                        <li v-for="(item,idx) in li" :key="idx">
                            <p>{{ item.startTime }}-{{ item.endTime }}</p>
                            <p><span>已消耗<span
                                class="color-danger">{{ item.usedStockNum }}</span></span>/<span>总库存<span
                                class="color-primary">{{ item.totalStockNum }}</span> </span></p>
                        </li>
                    </ul>
                </li>
            </ul>
            <el-empty v-else></el-empty>
        </div>
    </el-main>
</template>

<script type="text/ecmascript-6">
import comListSearch from "@/www/components/comListSearch/index.vue";
import selectCity from "@/components/selectCity/index.vue";
import {quotationProductionTypes} from "@/data";
import dayjs from "dayjs";
import {getNoInventoryByDayApi} from "@/www/urls/quotationUrls";
import {findItem} from "@/common/js/common";

export default {
    //定义模版数据
    name: 'vehicleInventoryMonitor',
    data() {
        return {
            list: [],
            loading: false,
            channels: [{label: '全部', value: -1}, {label: '携程', value: 1}],
            quotationProductionTypes,
            seatModelList: [
                {
                    label: '全部',
                    value: -1,
                    index: 1
                },
                {
                    label: '5座经济',
                    value: 1,
                    index: 2
                },
                {
                    label: '5座舒适',
                    value: 2,
                    index: 3
                },
                {
                    label: '5座豪华',
                    value: 3,
                    index: 4
                },
                {
                    label: '7座经济',
                    value: 27,
                    index: 4
                },
                {
                    label: '7座舒适',
                    value: 4,
                    index: 5
                },
                {
                    label: '7座商务',
                    value: 5,
                    index: 6
                },
                {
                    label: '7座豪华',
                    value: 25,
                    index: 7

                }
            ],
            pickerOptions: {
                disabledDate(time) {
                    return dayjs(time).isBefore(dayjs(), 'd')
                },
                shortcuts: [
                    {
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    },
                    {
                        text: '明天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() + 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    },
                    {
                        text: '后天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() + 3600 * 1000 * 24 * 2);
                            picker.$emit('pick', date);
                        }
                    }]
            },
            form: {
                quoteChannel: 1,
                date: dayjs().valueOf()
            }
        }
    },
    components: {comListSearch, selectCity},
    mounted() {
        this.search()
    },
    computed: {
        dataSource() {
            let list = []
            for (let id in this.list) {
                const item = this.list[id];
                list.push({
                    ...item[0],
                    vehicleType: Number(item[0].vehicleType),
                    sortIndex: findItem(this.seatModelList, Number(item[0].vehicleType), 'index'),
                    id,
                    list: [item.filter(({endTime}) => endTime <= '12:00'),
                        item.filter(({endTime}) => endTime > '12:00')]
                })
            }
            return list.sort((a, b) => a.cityName.localeCompare(b.cityName) || a.sortIndex - b.sortIndex);
        }
    },
    //定义事件方法
    methods: {
        resetForm() {
            this.form = {
                quoteChannel: 1,
                date: dayjs().valueOf()
            }
            this.$refs['selectCityRef'].clear()
            this.search();

        },
        async search() {
            try {
                this.loading = true;
                const ret = await getNoInventoryByDayApi({
                    ...this.form,
                    quoteChannel: this.form.quoteChannel !== -1 ? this.form.quoteChannel : undefined,
                    date: dayjs(this.form.date).format('YYYYMMDD')
                })
                if (ret.success) {
                    this.list = ret.data;
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        },
        handleSelectCity(city) {
            this.form.cityId = city.shortCode
        }
    }
}
</script>

