<template>
    <div style="display: inline-block">
        <el-popover placement="top-start"
                    :title="title"
                    width="1000"
                    trigger="click"
                    v-for="(vm,index) in getList"
                    :key="vm.addressId"
                    v-model="vm.visible"
                    @show="handleShow(index)">
            <polyline-map v-if="visibleIndex===index" :id="getMapId+vm.addressId" :list="vm.data"/>
            <el-button slot="reference"
                       size="small"
                       style="margin-right: 10px">
                {{ getList.length > 1 ? vm.addressName : '栅栏' }}示意图
            </el-button>
        </el-popover>
    </div>
</template>

<script>
import PolylineMap from '@/www/components/gaodeMap/polyline-map'

export default {
    name: "enclosure-dialog",
    data() {
        return {
            visibleIndex: false
        }
    },
    components: {PolylineMap},
    props: {
        ids: {
            type: Array,
            default: () => []
        },
        dataList: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: '围栏示意图'
        }
    },
    computed: {
        getMapId() {
            return 'enclosure-dialog' + (+new Date());
        },
        getList() {
            return this.ids.map((id) => {
                const item = this.dataList.find(({addressId}) => addressId === id);
                if (item)
                    return {
                        ...item,
                        data: item.addressDetail ? item.addressDetail.split(';').map((it) => it.split(',')) : []
                    }
                return {
                    addressId: id,
                    data: []
                }
            });
        }
    },
    methods: {
        handleShow(index) {
            this.visibleIndex = index;
        }
    }
}
</script>

<style scoped>

</style>
