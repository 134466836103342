<style lang="scss" scoped>
.order-contact-name /deep/ {
    display: flex;

    .el-form-item--small.el-form-item {
        margin-bottom: 0;
    }
}
</style>
<template>

    <div>
        <bms-dialog :isShow="isShow"
                    :title="(addOrEdit === 0 ? '新增':'编辑') + '订单联系人'"
                    isShowFooter
                    :width="550"
                    @update:cancelBtn="cancelBtn"
                    @update:submitBtn="submitBtn"
                    :submitState="addOrEdit"
        >
            <div slot="content">
                <el-form :model="form" :rules="rules" ref="form" size="small" label-width="110px">
                    <el-form-item label="运营商：" prop="operatorInfo.operatorName">
                        <operator-info-input
                            :selectOperatorId="selectOperatorId"
                            :is-edit="userInfo.userType != 0"
                            ref="operatorInfoRef"
                            trigger
                            clearable
                            @update:getSelectOperator="getSelectOperator">
                        </operator-info-input>
                    </el-form-item>
                    <el-form-item label="供应商：">
                        <!--                        prop="supplierInfo.supplierName"-->
                        <!--                        :rules="{required: true, message: '请填写供应商', trigger: 'change'}"-->
                        <supplier-info-input
                            :selectSupplierId="selectSupplierId"
                            :is-edit="userInfo.userType == 1"
                            :operatorInfo="form.operatorInfo"
                            ref="supplierInfoRef"
                            trigger
                            @update:getSelectSupplier="getSelectSupplier">
                        </supplier-info-input>
                    </el-form-item>
                    <el-form-item label="订单联系人1：" required>
                        <div class="order-contact-name">
                            <el-form-item prop="nameFirst">
                                <el-input v-model="form.nameFirst" clearable style="width: 135px;margin-right: 10px"
                                          placeholder="姓名"></el-input>
                            </el-form-item>

                            <el-form-item prop="mobileFirst">
                                <el-input v-model="form.mobileFirst" clearable maxlength="11"
                                          placeholder="手机号"></el-input>
                            </el-form-item>
                        </div>
                    </el-form-item>
                    <el-form-item label="订单联系人2：">
                        <div class="order-contact-name">
                            <el-form-item prop="nameSecond">
                                <el-input clearable v-model="form.nameSecond" style="width: 135px;margin-right: 10px"
                                          placeholder="姓名"></el-input>
                            </el-form-item>
                            <el-form-item prop="mobileSecond">
                                <el-input clearable v-model="form.mobileSecond" maxlength="11"
                                          placeholder="手机号"></el-input>
                            </el-form-item>
                            <el-button icon="el-icon-circle-plus-outline" type="primary" @click="showThird=true"
                                       v-if="!showThird">添加
                            </el-button>
                        </div>
                    </el-form-item>
                    <el-form-item label="订单联系人3：" v-if="showThird">
                        <div class="order-contact-name">
                            <el-form-item prop="nameSecond">
                                <el-input clearable v-model="form.nameThird" style="width: 135px;margin-right: 10px"
                                          placeholder="姓名"></el-input>
                            </el-form-item>
                            <el-form-item prop="mobileSecond">
                                <el-input clearable v-model="form.mobileThird" maxlength="11"
                                          placeholder="手机号"></el-input>
                            </el-form-item>
                            <el-button icon="el-icon-delete" type="danger" @click="handleDelete">删除</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </bms-dialog>
    </div>

</template>

<script type="text/ecmascript-6">

import bmsDialog from "../../../../components/dialog/index"
import operatorInfoInput from "../../../../components/operatorInfoInput/index"
import supplierInfoInput from "../../../../components/supplierInfoInput/index"

import {onlyChineseAndLetter, isCellphone} from '@/common/js/Utils';
import {getSupplierIdOperatorInfo} from '@/urls/index';
import service from "../../../../urls/quotationUrls"

export default {
    //定义模版数据
    data() {
        let name = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请填写姓名'));
            } else if (!onlyChineseAndLetter(value)) {
                callback(new Error('姓名格式错误'));
            } else {
                callback();
            }
        };
        let nameSecond = (rule, value, callback) => {
            if (!value) {
                callback();
            } else if (!onlyChineseAndLetter(value)) {
                callback(new Error('姓名格式错误'));
            } else {
                callback();
            }
        };
        let mobile = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请填写手机号'));
            } else if (!isCellphone(value)) {
                callback(new Error('手机号格式错误'));
            } else {
                callback();
            }
        };
        let mobileSecond = (rule, value, callback) => {
            if (!value) {
                callback();
            } else if (!isCellphone(value)) {
                callback(new Error('手机号格式错误'));
            } else {
                callback();
            }
        };
        return {
            showThird: false,
            form: {
                operatorInfo: {},
                supplierInfo: {},
                mobileFirst: '',
                mobileSecond: '',
                mobileThird: '',
                nameFirst: '',
                nameSecond: '',
                nameThird: '',
            },
            selectOperatorId: '',
            selectSupplierId: '',
            isOperatorUser: false,
            isSupplierUser: false,
            rules: {
                nameFirst: [{validator: name, trigger: 'blur'}],
                nameSecond: [{validator: nameSecond, trigger: 'blur'}],
                mobileFirst: [{validator: mobile, trigger: 'blur'}],
                mobileSecond: [{validator: mobileSecond, trigger: 'blur'}],
            }
        }
    },
    props: {
        infoData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        addOrEdit: {
            type: Number,
            default: 0
        },  // 0 新增  1编辑
    },
    components: {
        bmsDialog,
        operatorInfoInput,
        supplierInfoInput,
    },
    //计算属性
    computed: {
        isShow() {
            return this.$store.state.singleSetting.orderContactShow;
        },
        userInfo() {
            return this.$store.state.loginUserInfo
        },
    },
    //定义事件方法
    methods: {
        getUserInfo(type) {
            switch (parseInt(type)) {
                case 1:
                    this.getOperatorInfo(this.userInfo.refId);
                    this.selectSupplierId = this.userInfo.refId;
                    break;
                case 2:
                    this.selectOperatorId = this.userInfo.refId;
                    break;
            }
        },
        async getOperatorInfo(id) {
            const ret = await getSupplierIdOperatorInfo(id);
            if (ret)
                this.selectOperatorId = ret.operatorId;
        },
        initData(info) {
            let {
                operatorId,
                supplierId,
                mobileFirst,
                mobileSecond,
                nameFirst,
                nameSecond,
                nameThird,
                mobileThird,
                id
            } = info;
            if (info) {
                this.selectOperatorId = operatorId;
                this.selectSupplierId = supplierId;
                this.form.mobileFirst = mobileFirst;
                this.form.mobileSecond = mobileSecond;
                this.form.nameFirst = nameFirst;
                this.form.nameSecond = nameSecond;
                this.form.nameThird = nameThird;
                this.form.mobileThird = mobileThird;
                this.showThird = !!this.form.nameThird;
                this.form.id = id;
            }
        },
        getSelectOperator(item) {  // 运营商
            this.form.operatorInfo = item;
        },
        getSelectSupplier(item) {
            this.form.supplierInfo = item;
            this.selectSupplierId = item.id;
        },
        cancelBtn() {
            this.selectOperatorId = '';
            this.selectSupplierId = '';
            this.$refs.operatorInfoRef.clearOperatorName()
            this.$refs.supplierInfoRef.clearSupplierName()
            this.$refs.form.resetFields()
            this.form = {
                operatorInfo: {},
                supplierInfo: {},
                mobileFirst: '',
                mobileSecond: '',
                nameFirst: '',
                nameSecond: '',
                nameThird: "",
                mobileThird: ""
            };
            this.$emit("update:cancelBtn")
        },
        submitBtn() {
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    let {
                            operatorInfo,
                            supplierInfo,
                            mobileFirst,
                            mobileSecond,
                            nameFirst,
                            nameSecond,
                            nameThird,
                            mobileThird
                        } = this.form,
                        form = {
                            operatorId: operatorInfo.id,
                            supplierId: supplierInfo.id,
                            mobileFirst,
                            nameFirst,
                            nameThird,
                            mobileThird
                        };
                    if (mobileSecond) form.mobileSecond = mobileSecond;
                    if (nameSecond) form.nameSecond = nameSecond;
                    if (this.form.id) form.id = this.form.id;
                    let res = {};
                    let type = this.addOrEdit;
                    switch (type) {
                        case 0:
                            res = await service.insertProductOrderConnectInfo(form);
                            break;
                        case 1:
                            res = await this.$store.dispatch('updateOrderContact', form);
                            break
                    }
                    if (res.success && res.data) {
                        this.$message({
                            message: `${type === 0 ? '新增' : '编辑'}订单联系人成功`,
                            type: 'success'
                        });
                        this.$parent.handleInitData(1);
                        this.cancelBtn()
                    }
                    // this.$emit("update:submitBtn", form, this.addOrEdit)
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        handleDelete() {
            this.form.nameThird = "";
            this.form.mobileThird = "";
            this.showThird = false;
        }
    },
    //监听模版变量
    watch: {}

}
</script>

