<style lang="scss" scoped>
</style>
<template>
    <el-popover :placement="placement" :title="title" v-model="show" width="800" trigger="click">
        <periods :value="list" @change="handleChange" :options="periodOptions" :index="index">
            <el-button slot="tools" type="primary" @click="handleSubmit">
                确定
            </el-button>
            <el-button slot="tools" type="danger" @click="show=false">
                关闭
            </el-button>
        </periods>
        <div slot="reference" v-if="customReference">
            <slot name="reference"></slot>
        </div>
        <el-button v-else slot="reference">{{title}}</el-button>
    </el-popover>
</template>

<script type="text/ecmascript-6">
    import periods from './periods-panel.vue'

    export default {
        data() {
            return {
                list: [],
                show: false
            }
        },
        props: {
            customReference: Boolean,
            title: String,
            placement: {
                type: String,
                default: 'left'
            },
            value: {
                type: Array,
                default: () => []
            },
            periodOptions: {
                type: Object,
                default: () => ({
                    customHead: false,
                    custom: false,
                    range: false,
                    endTimeMinus: false,
                    disabledPeriod: null
                })
            },
            index: Number
        },
        mounted() {
            this.list = this.value;
        },
        components: {
            periods
        },
        //定义事件方法
        methods: {
            handleChange(val) {
                this.list = val;
            },
            handleSubmit() {
                this.$emit('input', this.list)
                this.$emit('change', this.list)
                this.show = false;
            }
        },
        watch: {
            value() {
                this.list = this.value;
            },
            show(n) {
                if (n)
                    this.list = [...this.value];
            }
        }
    }
</script>