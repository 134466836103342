const columns = [
    {label: "运营商", prop: "operatorName"},
    {label: "供应商", prop: "supplierName"},
    {label: "客服时间", prop: "customServiceWorkTime"},
    {label: "客服反馈时间", prop: "customServiceLongestResponseTime"},
    {label: "司机反馈时间", prop: "driverLongestResponseTime"}
];

export default {
    columns
}
