<template>

    <div class="add-quote">
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <quote-top-from ref="quoteTopFrom"></quote-top-from>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont">
                <el-button type="primary" style="float: right;margin-right: 20px" v-debounce="save">
                    保存
                </el-button>
            </div>
        </el-footer>
    </div>
</template>
<script type="text/ecmascript-6">
    import QuoteTopFrom from '../components/add-quote-form/index.vue'

    export default {
        name: 'addQuote',
        //定义模版数据
        data() {
            let data
            return {
                data
            }
        },
        components: {
//            quoteForm,
            QuoteTopFrom
        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted() {

        },
        //定义事件方法
        methods: {
            save() {
                this.$refs['quoteTopFrom'].submit();
            }
        },
        //监听模版变量
        watch: {}

    }
</script>
