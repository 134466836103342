<style lang="scss" scoped>
</style>
<template>
    <el-input v-bind="$attrs"
              :value="val"
              :type="type"
              :min="min"
              @blur="blur"
              @focus="focus"
              :disabled="disabled"
              @clear="triggerEvent.bind(this,'clear')"
              @change="triggerEvent.bind(this,'change')"
              @input="input">
        <slot name="prefix" slot="prefix"></slot>
        <slot name="suffix" slot="suffix"></slot>
    </el-input>
</template>

<script type="text/ecmascript-6">
import {keepTwoDecimal} from '@/common/js/Utils'

export default {
    //定义模版数据
    data() {
        return {
            val: null
        }
    },
    props: {
        value: {
            type: [String, Number],
            default: null
        },
        decimal: {
            type: Number,
            default: 2
        },
        type: {
            type: String,
            default: 'number'
        },
        min: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    //计算属性
    computed: {},
    //主件被加载完成
    mounted() {
        if (this.value)
            this.setValue()
    },
    //定义事件方法
    methods: {
        keepDecimal() {
            if (this.value || this.value === 0)
                this.val = keepTwoDecimal(this.value, this.decimal);
        },
        triggerEvent(eventName, val) {
            this.$emit(eventName, val)
        },
        blur(e) {
            this.isFocus = false;
            this.keepDecimal();
            this.$emit('blur', e)
        },
        input(val) {
            this.triggerEvent('input', val)
        },
        focus() {
            this.val = null;
            this.isFocus = true;
            this.triggerEvent('focus')
        },
        setValue() {
            this.val = this.value;
            if (!this.isFocus) {
                this.keepDecimal();
            }
        },
        setDefault() {
            if (!this.value && !this.isFocus)
                this.triggerEvent('input', 0)
        }
    },
    //监听模版变量
    watch: {
        value() {
            this.setValue();
        }
    }
}
</script>
