<style lang="scss" scoped>

    .el-button{
        margin-right: 10px;
    }
</style>
<template>

    <div>
        <service-table :columns="data.columns" :dataList="serviceTimeList" :show-buttons="true">
            <template v-slot:buttons="slotRow">
                <el-button type="text" @click="handleEditBtn(slotRow.row)">编辑</el-button>
                <!--<el-popover-->
                        <!--v-if="userInfo.userType != 1"-->
                        <!--placement="top"-->
                        <!--width="120"-->
                        <!--trigger="click"-->
                        <!--v-model="slotRow.row.showPopup">-->
                    <!--<p>确认删除</p>-->
                    <!--<div style="text-align: right; margin: 0">-->
                        <!--<el-button size="mini" type="text" @click="slotRow.row.showPopup = false">取消</el-button>-->
                        <!--<el-button type="primary" size="mini" @click="handleDelBtn(slotRow.row)">确定</el-button>-->
                    <!--</div>-->
                    <!--<el-button slot="reference" type="text" style="color: #f56c6c">删除</el-button>-->
                <!--</el-popover>-->
            </template>
        </service-table>
        <service-time-dialog
                @update:cancelBtn="handleCancelBtn"
                :addOrEdit="addOrEdit"
                ref="serviceTimeDialogRef"
        ></service-time-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
    import data from "./data"
    import serviceTable from "../../../../components/table/index"
    import serviceTimeDialog from "../serviceTimeDialog/index"

    export default {
        //定义模版数据
        data() {
            return {
                data,
                listLoading: false,
                addOrEdit: 0,
                form: {},
                pageInfo: {
                    pageIndex: 1,
                    pageSize: 10,
                    total: 1
                },
                serviceTimeList:[],
            }
        },
        components: {
            serviceTable,
            serviceTimeDialog
        },
        //计算属性
        computed: {
            userInfo() {
                return this.$store.state.loginUserInfo
            }
        },
        //主件被加载完成
        mounted: function () {
            this.handleInitData(1)
        },
        //定义事件方法
        methods: {
            async handleInitData(index) {
                this.pageInfo.pageIndex = index;
                let {
                        operatorId,
                        supplierId,
                    } = this.form,
                    form = {
                        operatorId,
                        supplierId,
                        pageIndex: index,
                        pageSize: this.pageInfo.pageSize,
                    };
                let res = await this.$store.dispatch('serviceTimeList', form)
                if(res) {
                    this.serviceTimeList = res.data;
                    this.pageInfo.total = res.total;
                    this.$emit('update:list', {pageInfo: this.pageInfo, data: this.serviceTimeList})
                }
            },



            handleCancelBtn() {
                this.$store.dispatch("serviceTimeShow", false);
            },
            handleAddOrEditBtn(type) {
                this.$refs.serviceTimeDialogRef.getUserInfo(this.userInfo.userType);
                this.addOrEdit = type;  // 0 新增  1 编辑
            },
            handleEditBtn(item) {
                if (item) {
                    this.$refs.serviceTimeDialogRef.initData(item);
                    this.$store.dispatch("serviceTimeShow", true);
                    this.handleAddOrEditBtn(1);
                }
            },


            async handleDelBtn(item) {
                item.showPopup = false;
                let form = {
                    id: item.id,
                    isDelete: 1
                }
                let res = await this.$store.dispatch('updateServiceTime', form)
                if (res.success && res.data) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.handleInitData(1)
                }
            },
            handleSearch(form){
                this.form = form;
                this.handleInitData(1)
            },
            handleReset(){
                this.form = {};
                this.handleInitData(1)

            }

        },
        //监听模版变量
        watch: {}

    }
</script>

