<style lang="scss" scoped>

</style>
<template>

    <div>
        <el-cascader placeholder="品牌型号"
                     filterable
                     :value="carBrandModel"
                     :options="brandModelList"
                     style="width: 100%"
                     @change="selectBrandModel"
                     v-if="delayShow"
        ></el-cascader>
    </div>

</template>

<script type="text/ecmascript-6">

    import {uniqueArray} from '@/common/js/Utils';

    export default {
        name: "carBrandModel",
        //定义模版数据
        data() {
            return {
                brandModelList: [],
                carBrandModel: [],
                delayShow: false
            }
        },
        props: {},
        components: {},
        //计算属性
        computed: {
            initBrand() {
                return this.$store.state.singleSetting.initCarBrandModel;
            }
        },
        //主件被加载完成
        mounted: function () {
            this.carBrandModel = this.initBrand;
            this.initData()
        },
        //定义事件方法
        methods: {
            initData(){
                this.getBrandModelList();
                setTimeout(() => {
                    this.delayShow = true;
                }, 10);
            },
            clearBrandModel() {
                this.carBrandModel = [];
            },
            async getBrandModelList() {
                this.delayShow = false;
                let res = await this.http('/galaxyStationApi/doCarBrandModelList', {}, 'POST');
                if (res.success) {
                    let listData = uniqueArray(res.data, 'carBrand');
                    let list = [];
                    listData.forEach(item => {
                        let brandList = {
                            value: item.carBrand,
                            label: item.carBrand,
                            children: []
                        };
                        res.data.forEach(modelList => {
                            if (item.carBrand == modelList.carBrand) {
                                let data = {
                                    value: modelList.carModel,
                                    label: modelList.carModel,
                                    carBrand: modelList.carBrand,
                                    carModel: modelList.carModel,
                                    carType: modelList.carType
                                };
                                brandList.children.push(data);
                            }
                        })
                        list.push(brandList)
                    })
                    this.brandModelList = list;
                }
            },
            selectBrandModel(val) {
                this.$emit("input", val);
                this.$store.commit("initCarBrandModel", val);
                const brand = this.brandModelList.find(b => {
                    return (val[0] === b.value);
                });
                const model = brand.children.find(c => {
                    return (val[1] === c.value);
                });
                this.$emit("update:selectBrandModel", model)
            },

        },
        //监听模版变量
        watch: {
            initBrand(n) {
                this.carBrandModel = n;
                this.initData()
            }
        }

    }
</script>

